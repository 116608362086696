import { all, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import userService from 'services/usersService';
import { setIsLoading, setPaginationTotal, setPaginationNoOfRows } from '../../Tables/modules/actions';
import {
  types,
  setParents,
  setCouponData,
} from './actions';

export function * getParents(action) {
  yield put(setIsLoading(true));
  const { pagination } = action.payload;
  const { data, error } = yield userService.getParents(pagination);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setParents(data.rows));
    yield put(setPaginationTotal(data.count));
    if (pagination.showEntries === 'All') {
      yield put(setPaginationNoOfRows(data.count));
    }
    yield put(setIsLoading(false));
  }
}

export function * createCouponSaga(action) {
  yield put(setIsLoading(true));
  const { data } = action.payload;
  const couponData = yield userService.createCoupon(data);
  if (couponData.error) {
    notification.error({
      message: i18n.t('notification error'),
      description: couponData.error,
    });
  } else {
    yield put(setCouponData(couponData.data));
    yield put(setIsLoading(false));
  }
}

export default function * parentsSaga() {
  yield all([
    takeLatest(types.GET_ALL_PARENTS, getParents),
    takeLatest(types.CREATE_COUPON_CODE, createCouponSaga),
  ]);
}
