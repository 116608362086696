import React, { createContext, useMemo } from 'react';
import io from 'socket.io-client';
import config from 'config';

const WebSocketContext = createContext(null);

export { WebSocketContext };

// eslint-disable-next-line react/prop-types
export default ({ children }) => {
  let socket;

  const disconnect = () => {
    socket.removeAllListeners();
    socket.disconnect();
  };

  const connect = (lessonId) => {
    if (socket) {
      socket.removeAllListeners();
      if (socket.connected) {
        disconnect();
      }
    }
    socket = io(config.socketUrl, {
      extraHeaders: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      autoConnect: false,
    });
    socket.auth = { lessonId };
    socket.connect();

    socket.on('connect_error', (err) => {
      // eslint-disable-next-line no-console
      console.log('Connection error', err);
    });

    socket.on('new reaction', () => {
    });
  };

  const ws = useMemo(() => ({
    socket,
    connect,
    disconnect,
  }), []);

  return (
    <WebSocketContext.Provider value={ws}>
      {children}
    </WebSocketContext.Provider>
  );
};
