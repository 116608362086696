import React from 'react';
import PropTypes from 'prop-types';

const CustomTooltipMultipleValues = ({ active, payload, showDollarSign }) => {
  const dollarSign = showDollarSign ? '$' : '';
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-monthly-revenue">
        <span className="label-total">
          {`${payload[0].payload.date}`}
        </span>
        <span>{`Registered: ${dollarSign}${payload[0].payload.registered}`}</span>
        <span>{`Trials: ${dollarSign}${payload[0].payload.trials}`}</span>
        <span>{`Subscriptions: ${dollarSign}${payload[0].payload.subscription}`}</span>
        <span>{`Cancelled: ${dollarSign}${payload[0].payload.cancel}`}</span>
      </div>
    );
  }

  return null;
};

CustomTooltipMultipleValues.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf.isRequired,
  showDollarSign: PropTypes.bool.isRequired,
};

export default CustomTooltipMultipleValues;
