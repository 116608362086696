import React, { useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import ActiveUsers from './components/ActiveUsers';
import Trials from './components/Trials';
import Subscriptions from './components/Subscriptions';
import RegisteredUsers from './components/RegisteredUsers';
import TypeOfUsers from './components/TypeOfUsers';
import MonthlyRevenue from './components/MonthlyRevenue';
import { exportOptions, timeRangeFilterItems } from './components/options';
import './styles.scss';
import PaymentPerUser from './components/PaymentPerUser';
import WauAnalytics from './components/WauAnalytics';

const View = ({
  actions, subscriptionPaymentPerUser,
  averagePaymentByUserData,
  monthlyRevenueData,
  trialMemberships,
  subscriptionMemberships,
  timeRangeFilter,
  activeUsers,
  platformInstallations,
  averagePaymentByUserDataByDevice,
  averagePaymentCalculationData,
  subscriptionPaymentPerUserHasMoreData,
  userSubscriptionsOverview,
  wauAnalytics,
}) => {
  const handleChangeTimeRangeFilter = (value) => {
    actions.setTimeRangeFilterGlobalOption({ value });
  };

  const exportData = useMemo(() => ({
    installs: [platformInstallations],
    activeUsers: [activeUsers],
    trialMemberships: [trialMemberships],
    subscriptionMemberships: [subscriptionMemberships],
    monthlyRevenueData: monthlyRevenueData.data,
    averagePaymentByUserData,
    subscriptionPaymentPerUser,
  }), [averagePaymentByUserData, monthlyRevenueData.data, platformInstallations,
    subscriptionMemberships, subscriptionPaymentPerUser, trialMemberships, activeUsers]);

  const onExportChange = (value) => {
    actions.exportDataAction({ data: exportData[value.value], label: value.label });
  };

  return (
    <div className="analytics-wrapper">
      <div className="global-time-range-filter">
        <Select
          className="time-range-select"
          defaultValue="Last Month"
          size="large"
          options={timeRangeFilterItems}
          onChange={handleChangeTimeRangeFilter}
        />
        <Select
          className="export-select"
          defaultValue="Export"
          size="large"
          options={exportOptions}
          onChange={onExportChange}
          labelInValue
        />
      </div>
      <RegisteredUsers timeRangeFilter={timeRangeFilter.installs} />
      <ActiveUsers
        activeUsers={activeUsers}
        getActiveUsers={actions.getActiveUsers}
        timeRangeFilter={timeRangeFilter.activeUsers}
      />
      <Trials
        averagePaymentByUserData={averagePaymentByUserData}
        averagePaymentByUserDataByDevice={averagePaymentByUserDataByDevice}
        getAveragePaymentOfUsers={actions.getAveragePaymentOfUsers}
        timeRangeFilter={timeRangeFilter.averagePayment}
        averagePaymentCalculationData={averagePaymentCalculationData}
      />
      <Subscriptions
        subscriptionMemberships={subscriptionMemberships}
        getSubscriptionMemberships={actions.getSubscriptionMemberships}
        timeRangeFilter={timeRangeFilter.subscriptions}
      />
      <MonthlyRevenue
        getMonthlyRevenueData={actions.getMonthlyRevenue}
        monthlyRevenueData={monthlyRevenueData}
        timeRangeFilter={timeRangeFilter.monthlyRevenue}

      />
      <PaymentPerUser
        getSubscriptionPaymentPerUser={actions.getSubscriptionPaymentPerUser}
        resetSubscriptionPaymentPerUserState={actions.resetSubscriptionPaymentPerUserState}
        subscriptionPaymentPerUser={subscriptionPaymentPerUser}
        subscriptionPaymentPerUserHasMoreData={subscriptionPaymentPerUserHasMoreData}
        timeRangeFilter={timeRangeFilter.subscriptionPerUsers}
        userSubscriptionsOverview={userSubscriptionsOverview}
        getUserSubscriptionOverview={actions.getUserSubscriptionOverview}
        exportData={actions.exportDataAction}
        plan="PLUS"
        title="Subscribed Users"
      />
      <TypeOfUsers timeRangeFilter={timeRangeFilter.installs} />
      <WauAnalytics
        getWauAnalyticsData={actions.getWauAnalytics}
        wauAnalyticsData={wauAnalytics}
        timeRangeFilter={timeRangeFilter.monthlyRevenue}
      />
    </div>
  );
};
View.propTypes = {
  subscriptionPaymentPerUser: ImmutablePropTypes.list.isRequired,
  subscriptionPaymentPerUserHasMoreData: PropTypes.bool.isRequired,
  averagePaymentByUserData: ImmutablePropTypes.list.isRequired,
  activeUsers: ImmutablePropTypes.list.isRequired,
  trialMemberships: ImmutablePropTypes.map.isRequired,
  subscriptionMemberships: ImmutablePropTypes.map.isRequired,
  monthlyRevenueData: PropTypes.arrayOf.isRequired,
  timeRangeFilter: PropTypes.objectOf.isRequired,
  platformInstallations: PropTypes.objectOf.isRequired,
  averagePaymentByUserDataByDevice: ImmutablePropTypes.map.isRequired,
  averagePaymentCalculationData: ImmutablePropTypes.map.isRequired,
  userSubscriptionsOverview: PropTypes.arrayOf.isRequired,
  wauAnalytics: PropTypes.arrayOf.isRequired,
  actions: PropTypes.shape({
    getSubscriptionPaymentPerUser: PropTypes.func.isRequired,
    getCurrentMembership: PropTypes.func.isRequired,
    getTrialsPaymentPerUser: PropTypes.func.isRequired,
    getTrialMemberships: PropTypes.func.isRequired,
    getActiveUsers: PropTypes.func.isRequired,
    getSubscriptionMemberships: PropTypes.func.isRequired,
    resetSubscriptionPaymentPerUserState: PropTypes.func.isRequired,
    resetTrialsPaymentPerUserState: PropTypes.func.isRequired,
    getAveragePaymentOfUsers: PropTypes.func.isRequired,
    getMonthlyRevenue: PropTypes.func.isRequired,
    setTimeRangeFilterGlobalOption: PropTypes.func.isRequired,
    exportDataAction: PropTypes.func.isRequired,
    getUserSubscriptionOverview: PropTypes.func.isRequired,
    getWauAnalytics: PropTypes.func.isRequired,
  }).isRequired,
};

export default View;
