import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCourseFilter, selectMaxCourseWeek, selectSelectedCourseFilter } from 'components/ManageCourses/modules/selectors';
import { resetOverviewTableData } from 'components/Tables/modules/actions';
import { selectCurrentUser } from 'components/Login/modules/selectors';
import View from './View';
import {
  selectScheduledLessons,
} from './modules/selectors';
import {
  getLessonsByCourse,
  deleteLesson,
} from './modules/actions';
import { selectPagination, selectShowEntries } from '../Tables/modules/selectors';

const mapStateToProps = (state) => ({
  scheduledLessons: selectScheduledLessons(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
  coursesForFilter: selectCourseFilter(state),
  selectedCourseForFilter: selectSelectedCourseFilter(state),
  user: selectCurrentUser(state),
  maxWeek: selectMaxCourseWeek(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getLessonsByCourse,
      deleteLesson,
      resetOverviewTableData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
