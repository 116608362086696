import { fromJS, List as IList } from 'immutable';
import { bool, number } from 'prop-types';

export const selectActiveTab = (state) => state.dashboardReducer.get('activeTab', '1');
export const selectPagination = (state) => state.dashboardReducer.get('pagination', fromJS({
  current: 1,
  pageSize: 10,
  showSizeChanger: false,
}));
export const selectTitleFilter = (state) => state.dashboardReducer.get('titleFilter', '');
export const selectMonthFilter = (state) => state.dashboardReducer.get('monthFilter', '');
export const selectIsLoading = (state) => state.dashboardReducer.get('isLoading', false);
export const selectSortOrder = (state) => state.dashboardReducer.get('sortOrder', 'descend');
export const selectTableData = (state) => state.dashboardReducer.get('tableData', fromJS([]));
export const selectStudentsData = (state) => state.dashboardReducer.get('studentsData', IList());
export const selectStudentsTable = (state) => state.dashboardReducer.get('studentsTable', bool);
export const selectNumOfRows = (state) => state.dashboardReducer.get('numOfRows', number);
export const selectOffset = (state) => state.dashboardReducer.get('offset', number);
