import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { getChildOverview } from 'components/Children/modules/actions';
import { selectChildOverview } from 'components/Children/modules/selectors';
import { selectOverviewTableData } from 'components/Tables/modules/selectors';

import { setPaginationTotal } from 'components/Tables/modules/actions';

const mapStateToProps = (state) => ({
  childOverviewData: selectChildOverview(state),
  overviewTableData: selectOverviewTableData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getChildOverview,
      setPaginationTotal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
