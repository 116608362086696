import { authorizedApiService } from './apiService';

const BASE_PATH = '/quizzes';

export default {
  createQuiz: async (quiz) => {
    try {
      const response = await authorizedApiService.post(`${BASE_PATH}`, quiz);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getManageQuizzesData: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/manage`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  deleteQuizData: async (id) => {
    try {
      const response = await authorizedApiService.delete(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getQuizByIdData: async (id) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getUserQuizInfo: async (userId, quizId) => {
    try {
      const response = await authorizedApiService.get(`/user-quiz-info?userId=${userId}&quizId=${quizId}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateQuizData: async (quizId, quizData) => {
    try {
      const response = await authorizedApiService.put(`${BASE_PATH}/${quizId}`, quizData);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
