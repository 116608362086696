import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import * as FullStory from '@fullstory/browser';
import { ErrorBoundary } from 'react-error-boundary';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';

import { environment } from 'utils/constants';
import MobileView from 'components/MobileView';
import store from './store';
import history from './localHistory';
import AppContainer from './AppContainer';
import WebSocketProvider from './utils/webSocket';
import { logger } from './utils/logger';

function ErrorHandler({ error }) {
  logger(error);
  return (
    <div role="alert">
      <p>An error occurred!</p>
    </div>
  );
}

const App = () => {
  useEffect(() => {
    if (environment === 'production') {
      const email = localStorage.getItem('email');
      if (email) {
        FullStory.setUserVars({
          displayName: email,
        });
      }
    }
  }, []);

  const appContent = isMobile ? (
    <MobileView />
  ) : (
    <WebSocketProvider>
      <AppContainer />
    </WebSocketProvider>
  );

  return (
    <ErrorBoundary FallbackComponent={ErrorHandler}>
      <Provider store={store}>
        <Router history={history}>
          {appContent}
        </Router>
      </Provider>
    </ErrorBoundary>
  );
};

ErrorHandler.propTypes = {
  error: PropTypes.objectOf().isRequired,
};

export default App;
