/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Line, AreaChart,
  Area,
} from 'recharts';
import moment from 'moment';

import {
  analyticsColor, areaChartOptions, axisOptions, monthlyRevenueLegend, timeRangeFilterComponents,
} from './options';
import CustomTooltipMultipleValuesDevices from './CustomTooltipMultipleValuesDevices';
import TimeRangeFilter from './TimeRangeFilter';
import '../styles.scss';

const MonthlyRevenue = ({
  getMonthlyRevenueData,
  monthlyRevenueData,
  timeRangeFilter,
}) => {
  const [monthlyRevenue, setMonthlyRevenue] = useState(0);

  useEffect(() => {
    getMonthlyRevenueData({ dateFilter: timeRangeFilter });
  }, [timeRangeFilter]);

  useEffect(() => {
    let revenue = 0;
    monthlyRevenueData?.lastMonthRevenue?.forEach((element) => {
      revenue += element?.total;
    });
    setMonthlyRevenue(revenue);
  }, [monthlyRevenueData.lastMonthRevenue]);

  const monthlyRevenuePercentage = Math.sign(monthlyRevenueData?.percentageFromLastMonth) === 1 ? (
    <span className="monthly-revenue-last-month-value">
      &#8593;
      {parseInt(monthlyRevenueData?.percentageFromLastMonth, 10) || 0}
      %
    </span>
  ) : (
    <span className="monthly-revenue-last-month-value-negative">
      &#8595;
      {parseInt(monthlyRevenueData?.percentageFromLastMonth, 10) || 0}
      %
    </span>
  );

  const month = moment().format('MMMM YYYY');

  return (
    <div className="monthly-revenue-wrapper">
      <div className="analytics-header-wrapper">
        <span className="analytics-title">Revenue</span>
        <TimeRangeFilter
          timeRangeFilter={timeRangeFilterComponents.monthlyRevenue}
          filterKey={timeRangeFilter}
        />
      </div>
      <div className="charts-wrapper">
        <div className="monthly-revenue-left-area">
          <span className="monthly-revenue-value">
            $
            {monthlyRevenue.toFixed(2)}
          </span>
          <span className="monthly-revenue-label">Monthly Revenue</span>
          <span className="monthly-revenue-label">
            {month}
          </span>
          <ResponsiveContainer width="100%" height="45%">
            <AreaChart
              data={monthlyRevenueData?.lastMonthRevenue}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset={areaChartOptions.startOffset}
                    stopColor={analyticsColor.primaryBlue}
                    stopOpacity={areaChartOptions.stopOpacityStart}
                  />
                  <stop
                    offset={areaChartOptions.endOffset}
                    stopColor={analyticsColor.primaryBlue}
                    stopOpacity={areaChartOptions.stopOpacityEnd}
                  />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="total" stroke={analyticsColor.primaryBlue} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
          <div className="monthly-revenue-last-month">
            {monthlyRevenuePercentage}
            <span className="monthly-revenue-label">from last month</span>
          </div>
        </div>
        <ResponsiveContainer width="80%" height="95%">

          <ComposedChart
            data={monthlyRevenueData.revenue}
          >
            <XAxis tickMargin={axisOptions.tickMargin} tickLine={false} dataKey="date" axisLine={false} />
            <YAxis tickMargin={axisOptions.tickMargin} tickLine={false} axisLine={false} />
            <Tooltip content={<CustomTooltipMultipleValuesDevices showDollarSign />} />
            <Legend payload={
                        monthlyRevenueLegend
                    }
            />
            <Bar dataKey="ios" fill={analyticsColor.iosColor} background={{ fill: analyticsColor.backgroundFill }} />
            <Bar dataKey="android" fill={analyticsColor.androidColor} background={{ fill: analyticsColor.backgroundFill }} />
            <Bar dataKey="web" fill={analyticsColor.webColor} background={{ fill: analyticsColor.backgroundFill }} />
            <Line dataKey="total" strokeWidth="3" stroke={analyticsColor.primaryBlue} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

MonthlyRevenue.propTypes = {
  monthlyRevenueData: PropTypes.objectOf.isRequired,
  getMonthlyRevenueData: PropTypes.func.isRequired,
  timeRangeFilter: PropTypes.string.isRequired,

};

export default MonthlyRevenue;
