import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Table } from 'antd';
import { withTranslation } from 'react-i18next';

const DashboardTable = (props) => {
  const {
    data, columns, loading,
    numOfRows,
    pagination, onChange, filterTable,
  } = props;

  const customPagination = { ...pagination, pageSize: numOfRows };

  return (
    <div>
      {filterTable}
      <Table
        loading={loading}
        columns={columns}
        pagination={customPagination}
        dataSource={data?.toJS()}
        onChange={onChange}
      />
    </div>
  );
};

DashboardTable.propTypes = {
  data: ImmutablePropTypes.list.isRequired,
  onChange: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  filterTable: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  numOfRows: PropTypes.number.isRequired,

};

export default withTranslation()(DashboardTable);
