// eslint-disable-next-line import/prefer-default-export
export const getPageTitle = (pathname, stateData) => {
  switch (pathname) {
    case 'children':
      if (stateData) {
        return `Children / ${stateData?.name}'s Children`;
      }
      return 'Children';
    case 'parents':
      return 'Parents';
    case 'child-profile':
      if (stateData) {
        return `Children / ${stateData?.name}'s Profile`;
      }
      return 'Child profile';
    case 'course-overview':
      if (stateData) {
        return `Courses / ${stateData?.title} students`;
      }
      return 'Courses';
    case 'schedule':
      return 'Lessons';
    case 'quiz-overview':
      if (stateData) {
        return `Quizzes / ${stateData?.title}`;
      }
      return 'Quizzes';
    case 'lesson-overview':
      if (stateData) {
        return `Lesson / ${stateData?.title}`;
      }
      return 'Lesson';
    case 'child-overview':
      if (stateData) {
        return `Children / ${stateData?.childName}'s Profile / ${stateData?.courseTitle}`;
      }
      return 'Child overview';
    case 'manage-courses':
      return 'Manage Courses';
    case 'manage-quizzes':
      return 'Manage Quizzes';
    case 'create-quiz':
      return 'Create Quiz';
    case 'students':
      return 'Students';
    case 'settings':
      return 'Settings';
    case 'edit-lesson':
      return 'Edit Lesson';
    case 'analytics-dashboard':
      return 'Dashboard / Analytics';
    case 'users':
      return 'Users';
    default:
      return null;
  }
};
