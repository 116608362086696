import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import View from './View';
import {
  selectActiveTab, selectPagination, selectTitleFilter,
  selectSortOrder, selectIsLoading, selectTableData, selectStudentsData,
  selectStudentsTable, selectMonthFilter, selectNumOfRows, selectOffset,
} from './modules/selectors';
import {
  setActiveTab, loadData, setTitleFilter,
  setSortOrder, setCurrentPage, getStudentsData, setStudentsTableVisible, setMonthFilter,
  setNumOfRows, setOffset,
} from './modules/actions';

const mapStateToProps = (state) => ({
  activeTab: selectActiveTab(state),
  pagination: selectPagination(state),
  isLoading: selectIsLoading(state),
  sortOrder: selectSortOrder(state),
  titleFilter: selectTitleFilter(state),
  monthFilter: selectMonthFilter(state),
  tableData: selectTableData(state),
  studentsData:selectStudentsData(state),
  studentsTable: selectStudentsTable(state),
  numOfRows: selectNumOfRows(state),
  offset: selectOffset(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setActiveTab,
      loadData,
      setCurrentPage,
      setSortOrder,
      setTitleFilter,
      getStudentsData,
      setStudentsTableVisible,
      setMonthFilter,
      setNumOfRows,
      setOffset,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
