export const types = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_PAGINATION: 'SET_PAGINATION',
  SET_PAGINATION_TOTAL: 'SET_PAGINATION_TOTAL',
  SET_PAGINATION_CURRENT: 'SET_PAGINATION_CURRENT',
  SET_PAGINATION_NO_OF_ROWS: 'SET_PAGINATION_NO_OF_ROWS',
  SET_OFFSET: 'SET_OFFSET',
  SET_SHOW_ENTRIES: 'SET_SHOW_ENTRIES',
  SET_OVERVIEW_TABLE_DATA: 'SET_OVERVIEW_TABLE_DATA',
  RESET_PAGINATION: 'RESET_PAGINATION',
  RESET_OVERVIEW_TABLE_DATA: 'RESET_OVERVIEW_TABLE_DATA',
};

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const setPaginationTotal = (total) => ({
  type: types.SET_PAGINATION_TOTAL,
  payload: { total },
});
export const setPaginationCurrent = (current) => ({
  type: types.SET_PAGINATION_CURRENT,
  payload: { current },
});
export const setPaginationNoOfRows = (noOfRows) => ({
  type: types.SET_PAGINATION_NO_OF_ROWS,
  payload: { noOfRows },
});
export const setOffset = (offset) => ({
  type: types.SET_OFFSET,
  payload: { offset },
});
export const setShowEntries = (showEntries) => ({
  type: types.SET_SHOW_ENTRIES,
  payload: { showEntries },
});
export const setOverviewTableData = (overviewTableData, pathname) => ({
  type: types.SET_OVERVIEW_TABLE_DATA,
  payload: { overviewTableData, pathname },
});
export const resetPagination = () => ({
  type: types.RESET_PAGINATION,
});
export const resetOverviewTableData = () => ({
  type: types.RESET_OVERVIEW_TABLE_DATA,
});
