import axios from 'axios';
import config from '../config';
import history from '../localHistory';

const defaultOptions = (url) => ({
  timeout: 30000,
  baseURL: `${url}`,
  headers: {
    'Content-Type': 'application/json',
    'Accept-Language':
      localStorage.getItem('i18nextLng') === 'en' ? 'en' : 'ba',
  },
});

const getToken = () => `Bearer ${localStorage.getItem('token')}`;

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const parseErrorResponse = (error) => {
  if (!error) return 'Something went wrong';
  let errorResponse = error.data?.message || error.message || 'Something went wrong';
  if (error.data && error.data?.errors && error.data.errors.length) {
    const errorMessages = error.data.errors.map((e) => e.message);
    errorResponse += `, ${errorMessages.join(', ')}`;
  }
  return errorResponse;
};

const handleResponseError = (error, originalRequest) => {
  const refreshToken = () => localStorage.getItem('refreshToken');
  const refreshAccessToken = () => {
    if (!refreshToken()) {
      return Promise.reject(new Error('Please log in again'));
    }
    return axios.post(`${config.apiUrl}/auth/refresh-token`, { refreshToken: refreshToken() });
  };
  const updateToken = (data) => {
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('token', data.accessToken);
  };
  const removeTokenAndRedirect = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userId');
    history.push('/');
  };
  if (error.status === 401 && !originalRequest._retry) {
    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        failedQueue.push({
          resolve,
          reject,
        });
      })
        .then(() => {
          originalRequest.headers.Authorization = getToken();
          return axios(originalRequest);
        })
        .catch((err) => err);
    }
    if (originalRequest.headers.Authorization !== getToken()) {
      originalRequest.headers.Authorization = getToken();
      return Promise.resolve(axios(originalRequest));
    }

    originalRequest._retry = true;
    isRefreshing = true;
    return new Promise((resolve, reject) => {
      refreshAccessToken()
        .then(({ data }) => {
          updateToken(data);
          processQueue(null, data.token);
          originalRequest.headers.Authorization = getToken();
          resolve(axios(originalRequest));
        })
        .catch((err) => {
          processQueue(err, null);
          removeTokenAndRedirect();
          reject(parseErrorResponse(err));
        })
        .finally(() => {
          isRefreshing = false;
        });
    });
  }
  return { data: null, error: parseErrorResponse(error) };
};

export const apiService = axios.create(defaultOptions(config.apiUrl));

apiService.interceptors.response.use(
  (res) => res,
  (err) => ({ data: null, error: parseErrorResponse(err.response) }),
);

export const authorizedApiService = axios.create(defaultOptions(config.apiUrl));

authorizedApiService.interceptors.request.use(
  (req) => {
    req.headers.Authorization = getToken();
    return req;
  },
);

authorizedApiService.interceptors.response.use(
  (res) => res,
  (err) => handleResponseError(err.response, err.config),
);

export default apiService;
