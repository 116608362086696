import { all, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import userService from 'services/usersService';
import {
  types,
  setChildren,
  setChildById,
  setChildOverview,
} from './actions';
import {
  setIsLoading,
  setPaginationTotal,
  setPaginationNoOfRows,
} from '../../Tables/modules/actions';

export function * getChildren(action) {
  const { pagination } = action.payload;
  yield put(setIsLoading(true));
  const { data, error } = yield userService.getChildren(pagination);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setChildren(data.rows));
    yield put(setPaginationTotal(data.count));
    if (pagination.showEntries === 'All') {
      yield put(setPaginationNoOfRows(data.count));
    }
    yield put(setIsLoading(false));
  }
}

export function * getChildOverviewSaga(action) {
  const { userId, courseId } = action.payload;
  yield put(setIsLoading(true));
  const { data } = yield userService.getChildOverviewForAdminDashboard(userId, courseId);
  if (data) {
    yield put(setChildOverview(data));
  }
  yield put(setIsLoading(false));
}

export function * getChildProfileById(action) {
  const { data, error } = yield userService.getChildById(action.payload.id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setChildById(data));
  }
}

export default function * childrenSaga() {
  yield all([
    takeLatest(types.GET_ALL_CHILDREN, getChildren),
    takeLatest(types.GET_CHILD_BY_ID, getChildProfileById),
    takeLatest(types.GET_CHILD_OVERVIEW, getChildOverviewSaga),
  ]);
}
