import { combineReducers } from 'redux';

import loginReducer from '../components/Login/modules/reducer';
import createLessonReducer from '../components/CreateLesson/modules/reducer';
import manageCoursesReducer from '../components/ManageCourses/modules/reducer';
import manageQuizzesReducer from '../components/ManageQuizzes/modules/reducer';
import createCourseReducer from '../components/CreateCourse/modules/reducer';
import resetPasswordReducer from '../components/ResetPassword/modules/reducer';
import scheduleReducer from '../components/Schedule/modules/reducer';
import createQuizReducer from '../components/CreateQuiz/modules/reducer';
import dashboardReducer from '../components/Dashboard/modules/reducer';
import studentsReducer from '../components/Students/modules/reducer';
import studentProfileReducer from '../components/StudentProfile/modules/reducer';
import changePasswordReducer from '../components/ChangePassword/modules/reducer';
import userReducer from '../components/Users/modules/reducer';
import childrenReducer from '../components/Children/modules/reducer';
import parentsReducer from '../components/Parents/modules/reducer';
import tableReducer from '../components/Tables/modules/reducer';
import analyticsReducer from '../components/AnalyticsDashboard/modules/reducer';

export default combineReducers({
  loginReducer,
  tableReducer,
  parentsReducer,
  childrenReducer,
  createLessonReducer,
  userReducer,
  createCourseReducer,
  manageCoursesReducer,
  manageQuizzesReducer,
  resetPasswordReducer,
  scheduleReducer,
  createQuizReducer,
  dashboardReducer,
  studentsReducer,
  studentProfileReducer,
  changePasswordReducer,
  analyticsReducer,
});
