import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import { nameLength } from 'utils/helpers';
import Tables from '../Tables';
import { columns } from './components/columns';
import './children.scss';

const Children = ({
  actions, children, pagination, showEntries, overviewTableData,
}) => {
  const childrenParentTableData = overviewTableData.toJS()['/children']?.value;

  const [searchVal, setSearchVal] = useState('');
  const [statusFilter, setStatusFilter] = useState('');

  const statusFilterHandler = (item) => {
    setStatusFilter(item);
    actions.setOffset(0);
  };
  const parentId = childrenParentTableData?.id;

  useEffect(() => {
    actions.getChildren({
      ...pagination.toJS(), searchVal, showEntries, statusFilter, parentId,
    });
  }, [pagination, searchVal, statusFilter]);

  const childrenData = children.toJS();

  const childrenTableData = childrenData.filter((item) => item?.username?.toLowerCase()
    .includes(searchVal.toLowerCase())).map((item) => {
    const childrenItem = {
      id: item?.id,
      name: nameLength(`${item?.firstName} ${item?.lastName}`, 25),
      age: moment().diff(item?.dateOfBirth, 'years'),
      email: nameLength(item?.parent?.email, 25),
      createdAt: moment(item?.createdAt).format('DD/MM/YYYY'),
      gender: item?.gender,
      status: item?.deletedAt ? 'Deleted' : 'Active',
      username: item?.username,
      courses: item?.courseAttendees,
      enrolled: item?.courseAttendees?.length > 0 ? 'Yes' : 'No',
    };
    return childrenItem;
  });

  return (
    <div>
      <Tables
        dataSource={childrenTableData}
        columns={columns}
        setSearchVal={setSearchVal}
        search
        exportData
        showEntries
        statusFilter
        searchPlaceholder="Search by username"
        setStatusFilter={statusFilterHandler}
        tableTitle={childrenParentTableData ? `${childrenParentTableData.name}'s Children` : 'Children'}
        rowOptions={['Active', 'Deleted']}
        pathname="/child-profile"
      />
    </div>
  );
};

Children.propTypes = {
  children: ImmutablePropTypes.list.isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  showEntries: PropTypes.string.isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,
  actions: PropTypes.shape({
    getChildren: PropTypes.func.isRequired,
    getChildById: PropTypes.func.isRequired,
    setChildById: PropTypes.func.isRequired,
    setPaginationCurrent: PropTypes.func.isRequired,
    setOffset: PropTypes.func.isRequired,
    setPaginationTotal: PropTypes.func.isRequired,
    resetPagination: PropTypes.func.isRequired,
  }).isRequired,
};
export default Children;
