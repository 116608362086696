import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  getSubscriptionPaymentPerUser,
  resetSubscriptionPaymentPerUserState,
  getAveragePaymentOfUsers, getMonthlyRevenue,
  getTrialMemberships,
  getSubscriptionMemberships,
  setTimeRangeFilterSingleOption,
  setTimeRangeFilterGlobalOption,
  getActiveUsers,
  exportDataAction,
  getUserSubscriptionOverview,
  getTrialsPaymentPerUser,
  resetTrialsPaymentPerUserState,
  getCurrentMembership,
  getWauAnalytics,
} from './modules/actions';
import {
  selectSubscriptionPaymentPerUserHasMoreData,
  selectAveragePaymentByUserData, selectAveragePaymentByUserDataByDevice,
  selectMonthlyRevenueData, selectTimeRangeFilter, selectSubscriptionsPerUser,
  selectTrialMembershipsData, selectSubscriptionMembershipsData,
  selectActiveUsersData,
  platformInstallationsSelector,
  selectUserSubscriptionsOverview,
  selectAveragePaymentCalculationData,
  selectTrialsPerUser,
  selectTrialsPaymentPerUserHasMoreData,
  selectCurrentTrial,
  selectCurrentSubscription,
  selectWauAnalytics,
} from './modules/selectors';
import View from './View';

const mapStateToProps = (state) => ({
  subscriptionPaymentPerUser: selectSubscriptionsPerUser(state),
  subscriptionPaymentPerUserHasMoreData: selectSubscriptionPaymentPerUserHasMoreData(state),
  trialsPaymentPerUser: selectTrialsPerUser(state),
  trialsPaymentPerUserHasMoreData: selectTrialsPaymentPerUserHasMoreData(state),
  averagePaymentByUserData: selectAveragePaymentByUserData(state),
  averagePaymentByUserDataByDevice: selectAveragePaymentByUserDataByDevice(state),
  monthlyRevenueData: selectMonthlyRevenueData(state),
  trialMemberships: selectTrialMembershipsData(state),
  subscriptionMemberships: selectSubscriptionMembershipsData(state),
  timeRangeFilter: selectTimeRangeFilter(state),
  activeUsers: selectActiveUsersData(state),
  platformInstallations: platformInstallationsSelector(state),
  userSubscriptionsOverview: selectUserSubscriptionsOverview(state),
  averagePaymentCalculationData: selectAveragePaymentCalculationData(state),
  currentMembershipSubscriptions: selectCurrentSubscription(state),
  currentMembershipTrials: selectCurrentTrial(state),
  wauAnalytics: selectWauAnalytics(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSubscriptionPaymentPerUser,
      resetSubscriptionPaymentPerUserState,
      getTrialsPaymentPerUser,
      resetTrialsPaymentPerUserState,
      getAveragePaymentOfUsers,
      getMonthlyRevenue,
      getActiveUsers,
      getTrialMemberships,
      getSubscriptionMemberships,
      setTimeRangeFilterSingleOption,
      setTimeRangeFilterGlobalOption,
      exportDataAction,
      getUserSubscriptionOverview,
      getCurrentMembership,
      getWauAnalytics,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
