import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import View from './View';
import {
  setTimeRangeFilterSingleOption,
} from '../../modules/actions';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setTimeRangeFilterSingleOption,
    },
    dispatch,
  ),
});

export default connect(null, mapDispatchToProps)(View);
