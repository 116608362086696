import React from 'react';
import classNames from 'classnames';
import MaleIcon from '../../../images/male-icon.png';
import FemaleIcon from '../../../images/female-icon.png';

// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Username',
    dataIndex: 'username',
    key: 'username',
  },
  {
    title: 'Parent email',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Enrolled',
    dataIndex: 'enrolled',
    key: 'enrolled',
  },
  {
    title: 'Age',
    dataIndex: 'age',
    key: 'age',
  },
  {
    title: 'Gender',
    key: 'gender',
    render: (item) => (
      <div className="gender-wrapper">
        {item.gender === 'M'
          ? <img src={MaleIcon} alt="male-icon" />
          : <img src={FemaleIcon} alt="female-icon" />}
      </div>
    ),
  },
  {
    title: 'Register date',
    dataIndex: 'createdAt',
    key: 'createdAt',
  },
  {
    title: 'Status',
    key: 'status',
    render: (item) => (
      <div className={classNames({
        'status-wrapper': true,
        'status-wrapper-active': item.status === 'Active',
        'status-wrapper-inactive': item.status === 'Deleted',
      })}
      >
        <p>{item?.status}</p>
      </div>
    ),
  },
];
