import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Checkbox,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { quizQuestionTitleRules } from 'utils/inputRules';
import trashIcon from '../../../images/icons_trash-can.svg';
import '../createQuiz.scss';

const MultipleChoiceQuestionType = ({
  isVideoQuiz,
  question,
  updateCorrectMultipleChoice,
  correctAnswers,
  questions,
  removeQuestion,
  t,
  getAnswerLetter,
  questionCount,
}) => {
  const [checkedValues, setCheckedValues] = useState([]);
  const onChange = (e) => {
    setCheckedValues(e);
    updateCorrectMultipleChoice(e, question);
  };

  useEffect(() => {
    if (updateCorrectMultipleChoice.length) {
      setCheckedValues(correctAnswers[question.key]);
    }
  }, []);
  const checkedErrorCondition = checkedValues.length < 2;
  const answersMapping = useMemo(() => question.answers.map((answer, index) => (
    <div className="create-quiz-form-answer" key={answer.key}>
      <span className="answer-letter">
        {getAnswerLetter(index)}
      </span>
      <Form.Item
        name={`a${index}q${question.key}`}
        fieldKey={question.key}
        rules={[
          {
            required: true,
            message: `${t(
              'createQuiz answerField error',
            )} ${getAnswerLetter(index)}`,
            whitespace: true,
          },
          {
            max: 100,
            message: 'Maximum 100 characters allowed for answer',
          },
        ]}
      >
        <TextArea
          autoSize
          className="answer-input"
          placeholder={t('createQuiz answerField placeholder')}
        />
      </Form.Item>
      <Checkbox className="checkbox" value={getAnswerLetter(index)} />
    </div>
  )), [getAnswerLetter, t, question.answers, question.key]);

  return (
    <div
      className={`create-quiz-form-question-wrapper ${isVideoQuiz ? 'create-quiz-form-question-wrapper--bordered' : ''}`}
      key={question.key}
    >
      <Checkbox.Group
        onChange={onChange}
        value={correctAnswers[question.key]}
        className="create-quiz-form-question"
      >
        <div className="question-header">
          <span className="plain-text">
            {t('createQuiz questionNumber')}
            {' '}
            {questionCount + 1}
          </span>
          {questions.length > 1 && (
          <div onClick={removeQuestion(question.key)}>
            <img src={trashIcon} alt="remove-answer-icon" />
          </div>
          )}
        </div>
        <Form.Item
          name={`q${question.key}`}
          fieldKey={question.key}
          rules={quizQuestionTitleRules}
          className="question-field"
        >
          <TextArea
            autoSize
            placeholder={t('createQuiz questionField placeholder')}
          />
        </Form.Item>
        <span className="plain-text">
          {t('createQuiz multipleAnswers title')}
        </span>
        {answersMapping}
      </Checkbox.Group>
      <span className="multiple-choice-selected-error">{checkedErrorCondition && 'You must provide at least two correct answers for the multiple choice questions'}</span>
    </div>
  );
};

MultipleChoiceQuestionType.propTypes = {
  isVideoQuiz: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
    })),
    key: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  updateCorrectMultipleChoice: PropTypes.func.isRequired,
  correctAnswers: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getAnswerLetter: PropTypes.func.isRequired,
  questionCount: PropTypes.number.isRequired,
};

export default MultipleChoiceQuestionType;
