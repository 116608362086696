import { authorizedApiService } from './apiService';

const BASE_PATH = '/users';

export default {
  getParents: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/parents`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getParentsByEmail: async (email) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/parents/${email}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getChildOverviewForAdminDashboard: async (userId, courseId) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/child-overview-for-admin-panel/${userId}/${courseId}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getChildren: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/children`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  createCoupon: async (data) => {
    try {
      const response = await authorizedApiService.post('/coupon-code', data);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getChildById: async (id) => {
    try {
      const response = await authorizedApiService.get(`/me/children/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
