import { timeRangeOptions } from 'utils/constants';

// eslint-disable-next-line import/prefer-default-export
export const analyticsColor = {
  // Dark blue color
  iosColor:'#003F5C',
  // Purple color
  androidColor:'#BC5090',
  // Yellow color
  webColor:'#FCB629',
  // Gray color
  backgroundFill: '#f9f9f9',
  // blur color
  primaryBlue: '#2A616C',
};

export const timeRangeFilterItems = [
  {
    key: 1,
    value: timeRangeOptions.week,
    label: 'Last Week',
  },
  {
    key: 2,
    value: timeRangeOptions.month,
    label: 'Last Month',
  },
  {
    key: 3,
    value: timeRangeOptions.year,
    label: 'Last Year',
  },
  {
    key: 4,
    value: timeRangeOptions.all,
    label: 'All Time',
  },
];

export const deviceFilterItems = [
  {
    key: 1,
    value: 'IOS',
    label: 'iOS only',
  },
  {
    key: 2,
    value: 'ANDROID',
    label: 'Android only',
  },
  {
    key: 3,
    value: 'WEB',
    label: 'Web only',
  },
  {
    key: 4,
    value: timeRangeOptions.all,
    label: 'All Devices',
  },
];

export const exportOptions = [
  {
    key: 1,
    value: 'installs',
    label: 'Installs',
  },
  {
    key: 2,
    value: 'activeUsers',
    label: 'Active Users',
  },
  {
    key: 3,
    value: 'trialMemberships',
    label: 'Trials',
  },
  {
    key: 4,
    value: 'subscriptionMemberships',
    label: 'Subscriptions',
  },
  {
    key: 5,
    value: 'monthlyRevenueData',
    label: 'Monthly Revenue',
  },
  {
    key: 6,
    value: 'averagePaymentByUserData',
    label: 'Average Payment Of Users',
  },
  {
    key: 7,
    value: 'subscriptionPaymentPerUser',
    label: 'Subscription Payment Per User',
  },
];

export const timeRangeFilterComponents = {
  installs: 'installs',
  activeUsers: 'activeUsers',
  trials: 'trials',
  subscriptions: 'subscriptions',
  averagePayment: 'averagePayment',
  subscriptionPerUsers: 'subscriptionPerUsers',
  trialsPerUsers: 'trialsPerUsers',
  monthlyRevenue: 'monthlyRevenue',

};

export const activeUsersLegend = [
  {
    id: 'registered', value: 'Registered', type: 'line', color: analyticsColor.primaryBlue,
  },
  {
    id: 'trials', value: 'Trials enabled', type: 'line', color: analyticsColor.webColor,
  },
  {
    id: 'subscription', value: 'Subscriptions', type: 'line', color: analyticsColor.iosColor,
  },
  {
    id: 'cancel', value: 'Cancelled', type: 'line', color: analyticsColor.androidColor,
  },
];

export const installationsLegend = [
  {
    id: 'pv', value: 'Subscriptions', type: 'circle', color: analyticsColor.androidColor,
  },
  {
    id: 'pv', value: 'Trial', type: 'circle', color: analyticsColor.webColor,
  },
  {
    id: 'pv', value: 'Basic', type: 'circle', color: analyticsColor.iosColor,
  },
];

export const typeOfUsersLegend = [
  {
    id: 'pv', value: 'Children', type: 'circle', color: analyticsColor.androidColor,
  },
  {
    id: 'pv', value: 'Enrolled', type: 'circle', color: analyticsColor.webColor,
  },
  {
    id: 'pv', value: 'Parents', type: 'circle', color: analyticsColor.iosColor,
  },
];
export const ageGroupLegend = [
  {
    id: 'pv', value: '8-10', type: 'circle', color: analyticsColor.androidColor,
  },
  {
    id: 'pv', value: '10-12', type: 'circle', color: analyticsColor.webColor,
  },
  {
    id: 'pv', value: '5-8', type: 'circle', color: analyticsColor.iosColor,
  },
];

export const monthlyRevenueLegend = [
  {
    id: 'total', value: 'Total', type: 'line', color: analyticsColor.primaryBlue,
  },
  {
    id: 'android', value: 'Android', type: 'circle', color: analyticsColor.androidColor,
  },

  {
    id: 'web', value: 'Web', type: 'circle', color: analyticsColor.webColor,
  }, {
    id: 'ios', value: 'iOS', type: 'circle', color: analyticsColor.iosColor,
  },
];
export const wauAnalyticsLegend = [
  {
    id: 'watchedCount', value: 'Watched Lessons', type: 'circle', color: analyticsColor.iosColor,
  },

  {
    id: 'quizCompletedCount', value: 'Completed Quizzes', type: 'circle', color: analyticsColor.androidColor,
  }, {
    id: 'activeUsersCount', value: 'Active Users', type: 'circle', color: analyticsColor.webColor,
  },
];

export const areaChartOptions = {
  startOffset: '5%',
  endOffset: '95%',
  stopOpacityStart: 0.8,
  stopOpacityEnd: 0,

};
export const axisOptions = {
  tickMargin: '5',
};
// this will be removed
export const data = [
  {
    name: 'Jan',
    pv: 2400,
  },
  {
    name: 'Feb',
    pv: 1398,
  },
  {
    name: 'Mar',
    pv: 9800,
  },
  {
    name: 'Apr',
    pv: 3908,
  },
  {
    name: 'May',
    pv: 4800,
  },
  {
    name: 'Jun',
    pv: 3800,
  },
  {
    name: 'Jul',
    pv: 4300,
  },
  {
    name: 'Aug',
    pv: 5800,
  },
  {
    name: 'Sep',
    pv: 3800,
  },
  {
    name: 'Okt',
    pv: 4300,
  }, {
    name: 'Nov',
    pv: 3800,
  },
  {
    name: 'Dec',
    pv: 4300,
  },
];
// this will be removed
export const monthlyData = [
  {
    name: 'Jan', total: 5431, web: 2431, ios: 1432, android: 1568,
  },
  {
    name: 'Feb', total: 5222, web: 2456, ios: 1367, android: 1399,
  },
  {
    name: 'Mar', total: 5792, web: 2977, ios: 1564, android: 1251,
  },
  {
    name: 'Apr', total: 5437, web: 1968, ios: 1679, android: 1790,
  },
  {
    name: 'May', total: 6310, web: 2312, ios: 1921, android: 2077,
  },
  {
    name: 'Jun', total: 6865, web: 2654, ios: 1943, android: 2268,
  },
  {
    name: 'Jul', total: 6125, web: 2187, ios: 1896, android: 2042,
  },
  {
    name: 'Aug', fullName: 'August', total: 6354, web: 2622, ios: 1750, android: 1982,
  },
  {
    name: 'Sep', fullName: 'September', total: 5736, web: 2256, ios: 1843, android: 1637,
  },
  {
    name: 'Oct', total: 6067, web: 2520, ios: 1698, android: 1849,
  },
  {
    name: 'Nov', total: 5786, web: 2223, ios: 1745, android: 1818,
  },
  {
    name: 'Dec', total: 5894, web: 2476, ios: 1603, android: 1815,
  },
];
// this will be removed
export const dataAveragePayment = [
  { name: 'Jan', pv: 1 },
  { name: 'Feb', pv: 2 },
  { name: 'Mar', pv: 4 },
  { name: 'Apr', pv: 5 },
  { name: 'May', pv: 2 },
  { name: 'Jun', pv: 10 },
  { name: 'Jul', pv: 1 },
  { name: 'Aug', pv: 5 },
  { name: 'Sep', pv: 4 },
  { name: 'Okt', pv: 7 },
  { name: 'Nov', pv: 5 },
  { name: 'Dec', pv: 4 },
];
// this will be removed
export const dataPaymentPerUser = [
  { name: 'Bob', amount: 1325 },
  { name: 'Grace', amount: 478 },
  { name: 'Alice', amount: 1657 },
  { name: 'John', amount: 1234 },
  { name: 'Charlie', amount: 987 },
  { name: 'Eve', amount: 735 },
  { name: 'David', amount: 1293 },
  { name: 'Hannah', amount: 1549 },
  { name: 'Frank', amount: 572 },
  { name: 'Jane', amount: 25 },
  { name: 'Bob', amount: 609 },
  { name: 'Grace', amount: 1212 },
  { name: 'Alice', amount: 899 },
  { name: 'John', amount: 1653 },
  { name: 'Charlie', amount: 43 },
  { name: 'Eve', amount: 776 },
  { name: 'David', amount: 1442 },
  { name: 'Hannah', amount: 1948 },
  { name: 'Frank', amount: 1032 },
  { name: 'Jane', amount: 107 },
  { name: 'Bob', amount: 1657 },
  { name: 'Grace', amount: 1298 },
  { name: 'Alice', amount: 23 },
  { name: 'John', amount: 821 },
  { name: 'Charlie', amount: 1786 },
  { name: 'Eve', amount: 1290 },
  { name: 'David', amount: 1567 },
  { name: 'Hannah', amount: 1025 },
  { name: 'Frank', amount: 1481 },
  { name: 'Jane', amount: 1907 },
  { name: 'Bob', amount: 1868 },
  { name: 'Grace', amount: 554 },
  { name: 'Alice', amount: 1675 },
  { name: 'John', amount: 1774 },
  { name: 'Charlie', amount:445 },
  { name: 'Bob', amount: 1325 },
  { name: 'Grace', amount: 478 },
  { name: 'Alice', amount: 1657 },
  { name: 'John', amount: 1234 },
  { name: 'Charlie', amount: 987 },
  { name: 'Eve', amount: 735 },
  { name: 'David', amount: 1293 },
  { name: 'Hannah', amount: 1549 },
  { name: 'Frank', amount: 572 },
  { name: 'Jane', amount: 25 },
  { name: 'Bob', amount: 609 },
  { name: 'Grace', amount: 1212 },
  { name: 'Alice', amount: 899 },
  { name: 'John', amount: 1653 },
  { name: 'Charlie', amount: 43 },
  { name: 'Eve', amount: 776 },
  { name: 'David', amount: 1442 },
  { name: 'Hannah', amount: 1948 },
  { name: 'Frank', amount: 1032 },
  { name: 'Jane', amount: 107 },
  { name: 'Bob', amount: 1657 },
  { name: 'Grace', amount: 1298 },
  { name: 'Alice', amount: 23 },
  { name: 'John', amount: 821 },
  { name: 'Charlie', amount: 1786 },
  { name: 'Eve', amount: 1290 },
  { name: 'David', amount: 1567 },
  { name: 'Hannah', amount: 1025 },
  { name: 'Frank', amount: 1481 },
  { name: 'Jane', amount: 1907 },
  { name: 'Bob', amount: 1868 },
  { name: 'Grace', amount: 554 },
  { name: 'Alice', amount: 1675 },
  { name: 'John', amount: 1774 },
  { name: 'Charlie', amount: 445 },
  { name: 'Bob', amount: 1325 },
  { name: 'Grace', amount: 478 },
  { name: 'Alice', amount: 1657 },
  { name: 'John', amount: 1234 },
  { name: 'Charlie', amount: 987 },
  { name: 'Eve', amount: 735 },
  { name: 'David', amount: 1293 },
  { name: 'Hannah', amount: 1549 },
  { name: 'Frank', amount: 572 },
  { name: 'Jane', amount: 25 },
  { name: 'Bob', amount: 609 },
  { name: 'Grace', amount: 1212 },
  { name: 'Alice', amount: 899 },
  { name: 'John', amount: 1653 },
  { name: 'Charlie', amount: 43 },
  { name: 'Eve', amount: 776 },
  { name: 'David', amount: 1442 },
  { name: 'Hannah', amount: 1948 },
  { name: 'Frank', amount: 1032 },
  { name: 'Jane', amount: 107 },
  { name: 'Bob', amount: 1657 },
  { name: 'Grace', amount: 1298 },
  { name: 'Alice', amount: 23 },
  { name: 'John', amount: 821 },
  { name: 'Charlie', amount: 1786 },
  { name: 'Eve', amount: 1290 },
  { name: 'David', amount: 1567 },
  { name: 'Hannah', amount: 1025 },
  { name: 'Frank', amount: 1481 },
  { name: 'Jane', amount: 1907 },
  { name: 'Bob', amount: 1868 },
  { name: 'Grace', amount: 554 },
  { name: 'Alice', amount: 1675 },
  { name: 'John', amount: 1774 },
  { name: 'Charlie', amount: 445 },
  { name: 'Bob', amount: 1325 },
  { name: 'Grace', amount: 478 },
  { name: 'Alice', amount: 1657 },
  { name: 'John', amount: 1234 },
  { name: 'Charlie', amount: 987 },
  { name: 'Eve', amount: 735 },
  { name: 'David', amount: 1293 },
  { name: 'Hannah', amount: 1549 },
  { name: 'Frank', amount: 572 },
  { name: 'Jane', amount: 25 },
  { name: 'Bob', amount: 609 },
  { name: 'Grace', amount: 1212 },
  { name: 'Alice', amount: 899 },
  { name: 'John', amount: 1653 },
  { name: 'Charlie', amount: 43 },
  { name: 'Eve', amount: 776 },
  { name: 'David', amount: 1442 },
  { name: 'Hannah', amount: 1948 },
  { name: 'Frank', amount: 1032 },
  { name: 'Jane', amount: 107 },
  { name: 'Bob', amount: 1657 },
  { name: 'Grace', amount: 1298 },
  { name: 'Alice', amount: 23 },
  { name: 'John', amount: 821 },
  { name: 'Charlie', amount: 1786 },
  { name: 'Eve', amount: 1290 },
  { name: 'David', amount: 1567 },
  { name: 'Hannah', amount: 1025 },
  { name: 'Frank', amount: 1481 },
  { name: 'Jane', amount: 1907 },
  { name: 'Bob', amount: 1868 },
  { name: 'Grace', amount: 554 },
  { name: 'Alice', amount: 1675 },
  { name: 'John', amount: 1774 },
  { name: 'Charlie', amount: 445 },
];

export const monthAbbreviationsToFullName = {
  Jan: 'January',
  Feb: 'February',
  Mar: 'March',
  Apr: 'April',
  May: 'May',
  Jun: 'June',
  Jul: 'July',
  Aug: 'August',
  Sep: 'September',
  Oct: 'October',
  Nov: 'November',
  Dec: 'December',
};
