import React from 'react';
import ReactExport from 'react-data-export';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';

import { Button } from 'antd';
import ExportOutlined from '@ant-design/icons/ExportOutlined';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const Export = ({ studentData, activeTab }) => (

  <ExcelFile element={(
    <Button className="export_button">
      Export
      {' '}
      <ExportOutlined />
    </Button>
)}
  >
    <ExcelSheet data={studentData} name="Parents">
      <ExcelColumn label="Name" value="name" />
      <ExcelColumn label="Age" value="age" />
      <ExcelColumn label={activeTab === '1' ? 'Watched' : 'Points Collected'} value={activeTab === '1' ? 'watched' : 'noOfPoints'} />
      <ExcelColumn label="Gender" value="gender" />
    </ExcelSheet>
  </ExcelFile>
);

Export.propTypes = {
  studentData: ImmutablePropTypes.list.isRequired,
  activeTab: PropTypes.string.isRequired,
};

export default Export;
