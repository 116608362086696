import React from 'react';
import ReactDOM from 'react-dom';
import * as FullStory from '@fullstory/browser';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import {
  sentryDsn,
  fullstoryOrgId,
  sentryOrgSlug,
  environment,
} from 'utils/constants';
import SentryFullStory from '@sentry/fullstory';
import './index.scss';
import 'antd/dist/antd.css';
import './i18n';
import App from './App';

if (environment === 'production') {
  if (fullstoryOrgId) {
    FullStory.init({ orgId: fullstoryOrgId });
  }
  if (sentryDsn && sentryOrgSlug) {
    Sentry.init({
      dsn: sentryDsn,
      integrations: [
        new Integrations.BrowserTracing(),
        new SentryFullStory(sentryOrgSlug),
      ],
      tracesSampleRate: 1.0,
    });
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
