import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Input, Button, Tag, notification,
} from 'antd';

import './dragAndDropQuestionType.scss';
import formatForEdit from 'utils/editDragAndDropHelper';
import PreviewModal from './PreviewModal';
import Answers from './Answers';

const DragAndDropQuestionType = ({
  isVideoQuiz,
  question,
  handleDragAndDropQuestionData,
  questions,
  removeQuestion,
  t,
  questionCount,
}) => {
  const [questionTitle, setQuestionTitle] = useState(null);
  const [selectedWords, setSelectedWords] = useState([]);
  const [correctWords, setCorrectWords] = useState([]);
  const [incorrectWords, setIncorrectWords] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [wrongWord, setWrongWord] = useState('');
  const [finalTitle, setFinalTitle] = useState([]);
  const [visible, setVisible] = useState(false);
  const [approve, setApprove] = useState(false);

  const changeQuestionTitleHandler = (e) => {
    setQuestionTitle(e.target.value);
  };

  const wrongAnswersChangeHandler = (e) => {
    setWrongWord(e.target.value);
  };

  const approveQuestion = () => {
    setApprove(true);
  };

  const createNewWords = (getter, setter, item, index, correct) => {
    setter([...getter, { title: item, index, correct }]);
    setWrongWord('');
  };

  const sameWordsNotification = () => {
    notification.open({
      message: 'Validation error',
      description:
            'Correct and incorrect answers cannot be the same',
    });
  };

  const selectCorrectWordsHandler = (item, index, correct) => {
    if (!approve) {
      if (correctWords.some((word) => word.title === item
        && word.index === index)) {
        setCorrectWords(correctWords
          .filter((word) => word.title !== item && word.index !== index));
      } else if (incorrectWords.some((word) => item === word.title)) {
        sameWordsNotification();
      } else {
        createNewWords(correctWords, setCorrectWords, item, index, correct);
      }
    }
  };

  const selectIncorrectWordsHandler = (item, index, correct) => {
    if (!approve) {
      if (incorrectWords.some((word) => word.title === item
        && word.index === index)) {
        setIncorrectWords(incorrectWords
          .filter((word) => (word.title !== item && word.index === index)));
      } else if (correctWords.some((word) => item === word.title)) {
        sameWordsNotification();
      } else {
        createNewWords(incorrectWords, setIncorrectWords, item, index, correct);
      }
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (question.title) {
      setIsEdit(true);
      if (!isEdit) {
        setCorrectWords(formatForEdit(question).correctAnswers);
        setIncorrectWords(formatForEdit(question).incorrectAnswers);
      }
    }
    setSelectedWords([...correctWords, ...incorrectWords]);
  }, [correctWords, incorrectWords]);

  useEffect(() => {
    if (question.title) {
      if (!questionTitle && questionTitle !== '') {
        setQuestionTitle(formatForEdit(question).title.join(' '));
      }
    }
    const splitTitle = questionTitle?.split(' ');
    const titleWithBlank = splitTitle?.map((item, index) => {
      if (selectedWords?.some((word) => word.title === item && word.index === index)) {
        return 'BLANK';
      }
      return item;
    });
    setFinalTitle(titleWithBlank);
  }, [selectedWords, questionTitle]);

  useEffect(() => {
    if (finalTitle?.length > 0 && questionTitle) {
      const sorted = selectedWords.sort(
        (a, b) => (a.index === null) - (b.index === null)
          || +(a.index > b.index) || -(a.index < b.index),
      );
      let counter = -1;
      const answerData = sorted.map((item) => {
        const answItem = {
          value: item.title,
          order: counter + 1,
          correct: item.correct,
        };
        counter += 1;
        return answItem;
      });

      handleDragAndDropQuestionData({
        title: finalTitle,
        approve,
        answers: answerData,
      });
    }
  }, [approve]);

  return (
    <div
      className={`create-quiz-form-question-wrapper ${isVideoQuiz ? 'create-quiz-form-question-wrapper--bordered' : ''}`}
      key={question.key}
    >
      <PreviewModal
        finalTitle={finalTitle}
        handleCancel={handleCancel}
        visible={visible}
        selectedWords={selectedWords}
      />
      <Form
        name="basic"
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
      >
        <div className="question-header">
          <span className="plain-text">
            {t('createQuiz questionNumber')}
            {questionCount + 1}
          </span>
          {questions?.length > 1 && (
            <Button
              disabled={approve}
              className="remove-question-btn"
              size="large"
              danger
              htmlType="button"
              onClick={() => removeQuestion(question.key)}
            >
              {t('button removeQuestion text')}
            </Button>
          )}
        </div>
        <Form.Item
          name={`q${question.key}`}
          fieldKey={question.key}
          rules={[
            {
              required: true,
              message: t('createQuiz questionField error'),
              whitespace: true,
            },
          ]}
          className="question-field"
        >
          <Input
            onChange={changeQuestionTitleHandler}
            placeholder={t('createQuiz questionField placeholder')}
            disabled={approve}
            value={questionTitle}
          />
          <p className="select-words-message">Select words by clicking on them</p>
          {questionTitle?.split(' ').map((item, tagIndex) => (
            <Tag
              color={selectedWords?.some((word) => word.title === item && word.index === tagIndex) ? 'blue' : 'orange'}
              onClick={() => {
                selectCorrectWordsHandler(item, tagIndex, true);
              }}
            >
              {item}

            </Tag>
          ))}
        </Form.Item>

        {selectedWords?.length > 0
        && (
        <div>
          <Answers
            wrongWord={wrongWord}
            wrongAnswersChangeHandler={wrongAnswersChangeHandler}
            selectedWords={selectedWords}
            selectWordsHandler={selectIncorrectWordsHandler}
            approve={approve}
          />
          <div className="dragAndDrop-preview-button">
            <Button onClick={approveQuestion} disabled={approve} className="dragAndDrop-quiz-approve">
              Approve
            </Button>
            <Button onClick={showModal}>
              Preview
            </Button>
          </div>
        </div>

        )}
      </Form>
    </div>
  );
};

DragAndDropQuestionType.propTypes = {
  isVideoQuiz: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    title: PropTypes.string.isRequired,
    answers: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
    })).isRequired,
    key: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  correctAnswers: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  questionCount: PropTypes.number.isRequired,
  handleDragAndDropQuestionData: PropTypes.func.isRequired,
};

export default DragAndDropQuestionType;
