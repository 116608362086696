import { authorizedApiService } from './apiService';

const BASE_PATH = '/me';

export default {
  getInfo: async () => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
