import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { searchArray } from 'utils/helpers';
import Tables from 'components/Tables';
import CautionModal from 'components/Tables/components/CautionModal';
import './schedule.scss';
import CreateLesson from 'components/CreateLesson';
import { getColumns } from './components/columns';

const Schedule = (props) => {
  const {
    actions, scheduledLessons, coursesForFilter, pagination, showEntries, selectedCourseForFilter,
    user, maxWeek,
  } = props;

  const [visible, setVisible] = useState(false);
  const [cautionModalVisible, setCautionModalVisible] = useState(false);
  const [lessonForEdit, setLessonForEdit] = useState(null);
  const [lessonForDelete, setLessonForDelete] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [week, setWeek] = useState(null);

  const showModal = () => {
    setVisible(true);
  };
  const editLesson = (item) => {
    setLessonForEdit(item);
    setVisible(true);
  };
  const closeModal = () => {
    setLessonForEdit(null);
    setVisible(false);
    setCautionModalVisible(false);
  };

  const deleteLesson = () => {
    actions.deleteLesson(lessonForDelete);
    setCautionModalVisible(false);
  };

  const cautionModalVisibleOn = (item) => {
    setCautionModalVisible(true);
    setLessonForDelete(item);
  };

  const lessonsData = scheduledLessons?.toJS();

  const lessonTableData = searchArray(lessonsData, 'title', searchVal)?.map((item) => {
    const lessonItem = {
      id: item?.id,
      courseId: item?.course?.id,
      lessonWatched: item?.watchedLessonId,
      courseAttendees: item?.course?.courseAttendees,
      title: item?.title,
      week: item?.week,
      status: item?.deletedAt ? 'Deleted' : 'Active',
      students: item?.course?.courseAttendees?.length,
      course: item?.course?.title,
      order: item?.order,
    };
    return lessonItem;
  });

  useEffect(() => {
    actions.getLessonsByCourse({
      ...pagination.toJS(), searchVal, showEntries, selectedCourseForFilter, week,
    });
    actions.resetOverviewTableData();
  }, [searchVal, pagination, selectedCourseForFilter, week]);

  const columns = getColumns(editLesson, cautionModalVisibleOn, user);

  const courseOptions = coursesForFilter?.map((item) => item.title);

  return (
    <div>
      <Tables
        dataSource={lessonTableData}
        columns={columns}
        exportData
        showEntries
        tableTitle="Lessons"
        addNew
        addNewComponent={CreateLesson}
        showModal={showModal}
        closeModal={closeModal}
        visible={visible}
        itemForEdit={lessonForEdit?.id}
        modalTitle={lessonForEdit ? `Edit ${lessonForEdit.title}` : 'Add new lesson'}
        pathname="/lesson-overview"
        setSearchVal={setSearchVal}
        search
        searchPlaceholder="Search By Lesson"
        courseFilter
        courseOptions={courseOptions}
        weekFilter
        weekOptions={maxWeek}
        setWeekFilter={setWeek}
        dropDownMinWidth
      />
      <CautionModal
        visible={cautionModalVisible}
        cautionDescription="Are you sure you want to delete this lesson?"
        closeModal={closeModal}
        onYes={deleteLesson}
      />
    </div>
  );
};

Schedule.propTypes = {
  actions: PropTypes.shape({
    deleteLesson: PropTypes.func.isRequired,
    getLessonsByCourse: PropTypes.func.isRequired,
    getCourseFilter: PropTypes.func.isRequired,
    resetOverviewTableData: PropTypes.func,
  }).isRequired,
  scheduledLessons: ImmutablePropTypes.list.isRequired,
  coursesForFilter: ImmutablePropTypes.list.isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  showEntries: PropTypes.string.isRequired,
  selectedCourseForFilter: PropTypes.string.isRequired,
  maxWeek: PropTypes.number.isRequired,
  user: PropTypes.shape({
    get: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
};

export default withTranslation()(Schedule);
