const formatForEdit = (data) => {
  const { title, answers } = data;
  const incorrectAnswers = [];
  const correctAnswers = [];
  const fullTitle = [];
  let counter = 0;

  answers.map((item) => {
    if (item.correct === true) {
      correctAnswers.push(item);
    } else {
      incorrectAnswers.push(item);
    }
    return item;
  });

  title.split(' ').map((item, idx) => {
    if (item === 'BLANK') {
      fullTitle.push(correctAnswers[counter].value);
      correctAnswers[counter] = { ...correctAnswers[counter], index: idx };
      counter += 1;
    } else {
      fullTitle.push(item);
    }
    return item;
  });

  const newCorrect = correctAnswers
    .map((item) => ({ title: item.value, index: item.index, correct: true }));
  const newIncorrect = incorrectAnswers
    .map((item) => ({ title: item.value, index: null, correct: false }));

  return {
    title: fullTitle, correctAnswers: newCorrect, incorrectAnswers: newIncorrect,
  };
};

export default formatForEdit;
