export const types = {
  GET_SKILLS: 'GET_SKILLS',
  SET_SKILLS: 'SET_SKILLS',
  GET_LESSONS: 'GET_LESSONS',
  SET_LESSONS: 'SET_LESSONS',
  GET_COURSES: 'GET_COURSES',
  SET_COURSES: 'SET_COURSES',
  SET_DISABLED_START_TIMES: 'SET_DISABLED_START_TIMES',
  SET_DISABLED_END_TIMES: 'SET_DISABLED_END_TIMES',
  CREATE_LESSON: 'CREATE_LESSON',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CREATED_LESSON: 'SET_CREATED_LESSSON',
  GET_LESSON: 'GET_LESSON',
  SET_LESSON: 'SET_LESSON',
  UPDATE_LESSON: 'UPDATE_LESSON',
  LESSON_SUBMIT: 'LESSON_SUBMIT',
};

export const lessonSubmit = (lessonSubmitState) => ({
  type: types.LESSON_SUBMIT,
  payload: { lessonSubmitState },
});

export const getSkills = () => ({
  type: types.GET_SKILLS,
});

export const setSkills = (skills) => ({
  type: types.SET_SKILLS,
  payload: { skills },
});

export const getLessons = (from, to, lessonId) => ({
  type: types.GET_LESSONS,
  payload: { from, to, lessonId },
});

export const setLessons = (lessons) => ({
  type: types.SET_LESSONS,
  payload: { lessons },
});

export const getCourses = (pagination) => ({
  type: types.GET_COURSES,
  payload: { pagination },
});

export const setCourses = (courses) => ({
  type: types.SET_COURSES,
  payload: { courses },
});

export const setDisabledStartTimes = (times) => ({
  type: types.SET_DISABLED_START_TIMES,
  payload: { times },
});

export const createLesson = (lesson) => ({
  type: types.CREATE_LESSON,
  payload: { lesson },
});

export const setCurrentStep = (step) => ({
  type: types.SET_CURRENT_STEP,
  payload: { step },
});

export const setCreatedLesson = (lesson) => ({
  type: types.SET_CREATED_LESSON,
  payload: { lesson },
});

export const getLesson = (id) => ({
  type: types.GET_LESSON,
  payload: { id },
});

export const setLesson = (lesson) => ({
  type: types.SET_LESSON,
  payload: { lesson },
});

export const updateLesson = (id, lesson) => ({
  type: types.UPDATE_LESSON,
  payload: { id, lesson },
});
