import React from 'react';
import './footer.scss';

const Footer = () => (
  <div className="kutubi-footer">
    <p className="copyright">Copyright @2023 Kutuby</p>
  </div>
);

export default Footer;
