import { authorizedApiService } from './apiService';

const BASE_PATH = '/analytics';

export default {
  getLastMonthRevenue: async () => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/revenue?dateFilter=1M`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getRevenue: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/revenue`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getWauAnalytics: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/wau-analytics`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getSubscriptionPaymentPerUserService: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/subscription-payment-per-user`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getAveragePaymentOfUsers: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/average-payment`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getUserSubscriptionsOverview: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/subscriptions-by-user`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getMembershipsService: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/memberships`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getMembershipService: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/membership`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getPlatformInstallationsService: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/register`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getActiveUsersService: async (params) => {
    try {
      const registered = await authorizedApiService.get(`${BASE_PATH}/active-users`, { params });
      const subscriptions = await authorizedApiService.get(`${BASE_PATH}/subscriptions?dateFilter=${params.dateFilter}&type=PLUS&cancel=0`);
      const trials = await authorizedApiService.get(`${BASE_PATH}/subscriptions?dateFilter=${params.dateFilter}&type=TRIAL&cancel=0`);
      const cancels = await authorizedApiService.get(`${BASE_PATH}/subscriptions?dateFilter=${params.dateFilter}&type=TRIAL&cancel=1`);

      return {
        registered: registered.data,
        subscriptions: subscriptions.data,
        trials: trials.data,
        cancels: cancels.data,
      };
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getCourseMetrics: async () => {
    try {
      const response = await authorizedApiService.get('/user-metrics/courses');
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getTypeOfUsersService: async (params) => {
    try {
      const response = await authorizedApiService.get('/analytics/type-of-users', { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getCountOfStatuses: async (params) => {
    try {
      const response = await authorizedApiService.get('/analytics/active-status', { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getLessonMetrics: async (data) => {
    try {
      const response = await authorizedApiService.get(`/user-metrics/lessons?search=${data.search}&week=${data.week}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getQuizMetrics: async (data) => {
    try {
      const response = await authorizedApiService.get(`/user-metrics/quizzes?search=${data.search}&week=${data.week}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
