import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  tableData: [],
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  },
  nameFilter: '',
  sortOrder: 'ascend',
  isLoading: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_STUDENTS_TABLE_DATA:
      return state.set('tableData', fromJS(action.payload.data));
    case types.SET_STUDENTS_CURRENT_PAGE:
      return state.setIn(['pagination', 'current'], action.payload.currentPage);
    case types.SET_NAME_FILTER:
      return state.set('nameFilter', action.payload.nameFilter);
    case types.SET_STUDENTS_SORT_ORDER:
      return state.set('sortOrder', action.payload.sortOrder);
    case types.SET_STUDENTS_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    case types.SET_STUDENTS_TOTAL_COUNT:
      return state.setIn(['pagination', 'total'], action.payload.total);
    default:
      return state;
  }
};
