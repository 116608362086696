export const types = {
  GET_ALL_CHILDREN: 'GET_ALL_CHILDREN',
  SET_ALL_CHILDREN: 'SET_ALL_CHILDREN',
  GET_CHILD_BY_ID: 'GET_CHILD_BY_ID',
  SET_CHILD_BY_ID: 'SET_CHILD_BY_ID',
  GET_CHILD_OVERVIEW: 'GET_CHILD_OVERVIEW',
  SET_CHILD_OVERVIEW: 'SET_CHILD_OVERVIEW',
};

export const getChildren = (pagination) => ({
  type: types.GET_ALL_CHILDREN,
  payload: { pagination },
});

export const setChildren = (children) => ({
  type: types.SET_ALL_CHILDREN,
  payload: { children },
});

export const getChildById = (id) => ({
  type: types.GET_CHILD_BY_ID,
  payload: { id },
});

export const setChildById = (data) => ({
  type: types.SET_CHILD_BY_ID,
  payload: { data },
});

export const getChildOverview = (userId, courseId) => ({
  type: types.GET_CHILD_OVERVIEW,
  payload: { userId, courseId },
});

export const setChildOverview = (data) => ({
  type: types.SET_CHILD_OVERVIEW,
  payload: { data },
});
