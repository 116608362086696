import { fromJS, List as IList } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  scheduledLessons: IList(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SCHEDULED_LESSONS:
      return state.set('scheduledLessons', fromJS(action.payload.lessons));
    case types.LESSON_DELETED:
      return state.set('scheduledLessons', state.get('scheduledLessons', IList()).filter((lesson) => lesson.get('id', '') !== action.payload.id));
    default:
      return state;
  }
};
