import React from 'react';
import PropTypes from 'prop-types';

import {
  Form,
  Radio,
} from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { quizQuestionTitleRules } from 'utils/inputRules';
import trashIcon from '../../../images/icons_trash-can.svg';

const SingleAnswerQuestionType = ({
  isVideoQuiz,
  question,
  updateCorrect,
  correctAnswers,
  questions,
  removeQuestion,
  t,
  getAnswerLetter,
  questionCount,
}) => (
  <div
    className={`create-quiz-form-question-wrapper ${isVideoQuiz ? 'create-quiz-form-question-wrapper--bordered' : ''}`}
    key={question.key}
  >

    <Radio.Group
      onChange={(e) => updateCorrect(e, question)}
      value={correctAnswers[question.key] || 'A'}
      className="create-quiz-form-question"
    >
      <div className="question-header">
        <span className="plain-text">
          {t('createQuiz questionNumber')}
          {' '}
          {questionCount + 1}
        </span>
        {questions.length > 1 && (
          <div onClick={removeQuestion(question.key)}>
            <img src={trashIcon} alt="remove-answer-icon" />
          </div>
        )}
      </div>
      <Form.Item
        name={`q${question.key}`}
        fieldKey={question.key}
        rules={quizQuestionTitleRules}
        className="question-field"
      >
        <TextArea
          autoSize
          placeholder={t('createQuiz questionField placeholder')}
        />
      </Form.Item>
      <span className="plain-text">
        {t('createQuiz answers title')}
      </span>
      {question.answers.map((answer, index) => (
        <div className="create-quiz-form-answer" key={answer.key}>
          <span className="answer-letter">
            {getAnswerLetter(index)}
          </span>
          <Form.Item
            name={`a${index}q${question.key}`}
            fieldKey={question.key}
            rules={[
              {
                required: true,
                message: `${t(
                  'createQuiz answerField error',
                )} ${getAnswerLetter(index)}`,
                whitespace: true,
              },
              {
                max:100,
                message: 'Maximum 100 characters allowed for answer',
              },
            ]}
          >
            <TextArea
              autoSize
              className="answer-input"
              placeholder={t('createQuiz answerField placeholder')}
            />
          </Form.Item>
          <Radio value={getAnswerLetter(index)} />

        </div>
      ))}
    </Radio.Group>
  </div>
);

SingleAnswerQuestionType.propTypes = {
  isVideoQuiz: PropTypes.bool.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string.isRequired,
    })).isRequired,
    key: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  updateCorrect: PropTypes.func.isRequired,
  correctAnswers: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getAnswerLetter: PropTypes.func.isRequired,
  questionCount: PropTypes.number.isRequired,
};

export default SingleAnswerQuestionType;
