import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Tag, Input,
} from 'antd';

const Answers = ({
  selectedWords, wrongAnswersChangeHandler, wrongWord, selectWordsHandler, approve,
}) => (
  <div>
    <p>Correct answers:</p>
    {selectedWords?.map((item) => (
      item.correct && (
      <Tag
        color="green"
      >
        {item?.title}
      </Tag>
      )
    ))}
    <div className="add-wrong-answers">
      <Input
        disabled={approve}
        placeholder="Add incorrect answers"
        onChange={wrongAnswersChangeHandler}
        value={wrongWord}
      />
      <Button
        disabled={approve || wrongWord?.length < 1}
        onClick={() => {
          selectWordsHandler(wrongWord, null, false);
        }}
      >
        Add

      </Button>
    </div>

    <p>Wrong answers:</p>
    {selectedWords?.map((item) => (
      !item.correct && (
      <Tag
        color="red"
      >
        {item?.title}
      </Tag>
      )
    ))}
  </div>
);

Answers.propTypes = {
  wrongAnswersChangeHandler: PropTypes.func.isRequired,
  wrongWord: PropTypes.string.isRequired,
  selectedWords: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectWordsHandler: PropTypes.func.isRequired,
  approve: PropTypes.bool.isRequired,
};

export default Answers;
