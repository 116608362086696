import { List as IList, Map as IMap } from 'immutable';

export const selectSkills = (state) => state.createLessonReducer.get('skills', IList());
export const selectLessons = (state) => state.createLessonReducer.get('lessons', IList());
export const selectCourses = (state) => state.createLessonReducer.get('courses', IList());
export const selectDisabledStartTimes = (state) => state.createLessonReducer.get('disabledStartTimes', IList());
export const selectCurrentStep = (state) => state.createLessonReducer.get('currentStep', 0);
export const selectCreatedLesson = (state) => state.createLessonReducer.get('createdLesson', IMap());
export const selectLesson = (state) => state.createLessonReducer.get('lesson', IMap());
export const selectLessonSubmit = (state) => state.createLessonReducer.get('lessonSubmit', Boolean);
