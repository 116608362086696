export const types = {
  GET_ALL_PARENTS: 'GET_ALL_PARENTS',
  SET_ALL_PARENTS: 'SET_ALL_PARENTS',
  GET_PARENT_BY_EMAIL: 'GET_PARENT_BY_EMAIL',
  SET_PARENT_BY_EMAIL: 'SET_PARENT_BY_EMAIL',
  GET_ALL_CHILDREN: 'GET_ALL_CHILDREN',
  SET_ALL_CHILDREN: 'SET_ALL_CHILDREN',
  GET_CHILD_BY_ID: 'GET_CHILD_BY_ID',
  SET_CHILD_BY_ID: 'SET_CHILD_BY_ID',
  SET_PARENT_CHILD_TABLE_TRUE: 'PARENT_CHILD_TABLE_TRUE',
  SET_PARENT_CHILD_TABLE_FALSE: 'PARENT_CHILD_TABLE_FALSE',
};

export const getParents = () => ({
  type: types.GET_ALL_PARENTS,
});

export const setParentChildTableTrue = () => ({
  type: types.SET_PARENT_CHILD_TABLE_TRUE,
});

export const setParentChildTableFalse = () => ({
  type: types.SET_PARENT_CHILD_TABLE_FALSE,
});

export const setParents = (parents) => ({
  type: types.SET_ALL_PARENTS,
  payload: { parents },
});

export const getChildren = () => ({
  type: types.GET_ALL_CHILDREN,
});

export const setChildren = (children) => ({
  type: types.SET_ALL_CHILDREN,
  payload: { children },
});

export const getParentsByEmail = (email) => ({
  type: types.GET_PARENT_BY_EMAIL,
  payload: { email },
});

export const setParentsByEmail = (parent) => ({
  type: types.SET_PARENT_BY_EMAIL,
  payload: { parent },
});

export const getChildById = (id) => ({
  type: types.GET_CHILD_BY_ID,
  payload: { id },
});

export const setChildById = (data) => ({
  type: types.SET_CHILD_BY_ID,
  payload: { data },
});
