export const types = {
  CREATE_COURSE: 'CREATE_COURSE',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CREATED_COURSE: 'SET_CREATED_COURSE',
  GET_COURSE: 'GET_COURSE',
  SET_COURSE: 'SET_COURSE',
  UPDATE_COURSE: 'UPDATE_COURSE',
  POST_COURSE_IMAGE: 'POST_COURSE_IMAGE',
  POST_COURSE_IMAGE_LANDSCAPE: 'POST_COURSE_IMAGE_LANDSCAPE',
  SET_COURSE_IMAGE: 'SET_COURSE_IMAGE',
  SET_COURSE_IMAGE_LANDSCAPE: 'SET_COURSE_IMAGE_LANDSCAPE',
  SET_IS_LOADING: 'SET_IS_LOADING',
};

export const createCourse = (course, courseImageData, courseImageLandscapeData) => ({
  type: types.CREATE_COURSE,
  payload: { course, courseImageData, courseImageLandscapeData },
});

export const setCurrentStep = (step) => ({
  type: types.SET_CURRENT_STEP,
  payload: { step },
});

export const setCreatedCourse = (course) => ({
  type: types.SET_CREATED_COURSE,
  payload: { course },
});

export const getCourse = (id) => ({
  type: types.GET_COURSE,
  payload: { id },
});

export const setCourse = (course) => ({
  type: types.SET_COURSE,
  payload: { course },
});

export const updateCourse = (id, course, courseImageData, courseImageLandscapeData) => ({
  type: types.UPDATE_COURSE,
  payload: {
    id, course, courseImageData, courseImageLandscapeData,
  },
});

export const postCourseImage = (courseImage) => ({
  type: types.POST_COURSE_IMAGE,
  payload: { courseImage },
});
export const postCourseImageLandscape = (courseImage) => ({
  type: types.POST_COURSE_IMAGE_LANDSCAPE,
  payload: { courseImage },
});

export const setCourseImage = (courseImage) => ({
  type: types.SET_COURSE_IMAGE,
  payload: { courseImage },
});
export const setCourseImageLandscape = (courseImage) => ({
  type: types.SET_COURSE_IMAGE_LANDSCAPE,
  payload: { courseImage },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});
