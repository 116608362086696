import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getOverviewTableData } from 'utils/overviewTableDataHelper';
import { avgGradeToLetters } from 'utils/helpers';
import Tables from '../Tables';
import { columns } from './components/columns';
import './childProfile.scss';

const ChildProfile = ({ actions, overviewTableData }) => {
  const singleChildTableData = getOverviewTableData(overviewTableData, '/child-profile', '/children');
  const [searchVal, setSearchVal] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const totalPagination = singleChildTableData?.courses?.length;

  actions.setPaginationTotal(totalPagination);

  const statusFilterHandler = (item) => {
    setStatusFilter(item);
  };

  const childProfileData = singleChildTableData?.courses?.filter(
    (item) => item?.course?.title?.toLowerCase().includes(searchVal.toLowerCase()),
  ).map(
    (item) => {
      const childrenItem = {
        courseTitle: item?.course?.title,
        finalGrade: avgGradeToLetters(item?.finalGrade || 0),
        pointsCollected: item?.pointsCollected || 0,
        enrollDate: moment(item?.from).format('DD/MM/YYYY'),
        status: moment(new Date()).isBefore(item?.to, 'second') ? item?.status : 'Completed',
        courseId: item?.courseId,
        userId: singleChildTableData?.id,
        childName: singleChildTableData?.name,
      };
      return childrenItem;
    },
  );

  const filterByStatus = () => childProfileData?.filter((item) => item?.status
    .includes(statusFilter));

  return (
    <div>
      <Tables
        dataSource={filterByStatus()}
        columns={columns}
        setSearchVal={setSearchVal}
        search
        exportData
        showEntries
        statusFilter
        searchPlaceholder="Search by course title"
        setStatusFilter={statusFilterHandler}
        tableTitle={`${singleChildTableData?.name}'s Profile`}
        rowOptions={['Completed', 'Pending', 'On Track']}
        backButton
        pathname="/child-overview"
      />
    </div>
  );
};

ChildProfile.propTypes = {
  actions: PropTypes.shape({
    setPaginationTotal: PropTypes.func.isRequired,
  }).isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,
};

export default ChildProfile;
