import React from 'react';
import '../tables.scss';

export default function itemRender(current, type, originalElement) {
  if (type === 'prev') {
    return <a className="pagination-prev-next">Previous</a>;
  }
  if (type === 'next') {
    return <a className="pagination-prev-next"> Next</a>;
  }
  return originalElement;
}
