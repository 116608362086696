import React from 'react';
import logo from './logo.png';
import './logo.scss';

const Logo = () => (
  <img
    className="logo-image"
    src={logo}
    alt="Kutuby"
  />
);

export default Logo;
