import moment from 'moment';

// eslint-disable-next-line import/prefer-default-export
export const nameLength = (value = '', length = 25) => {
  if (value?.length > length) {
    return `${value.substring(0, length)}...`;
  }
  return value;
};

export function searchArray(array, field, searchValue) {
  return array?.filter((item) => {
    const value = field?.split('.')?.reduce((acc, searchField) => acc[searchField], item);
    return value?.toLowerCase().includes(searchValue?.toLowerCase());
  });
}

export const avgGradeToLetters = (averageGrade) => {
  if (averageGrade >= 3.75) {
    return 'A';
  } if (averageGrade >= 2.5) {
    return 'B';
  }
  return 'C';
};

export const renameFile = (file) => {
  const MAX_LENGTH = 15;
  if (file.name) {
    const name = file.name.replace(/ /g, '_').substring(0, MAX_LENGTH);
    const extension = name.split('.').pop();
    const newName = `${name.substring(0, name.length - extension.length - 1)}_${Date.now()}.${extension}`;
    return new File([file], newName, { type: file.type });
  }
  return file;
};

export const calculatePercentage = (value, totalValue) => ((value / totalValue) * 100);
export const formatDate = (date) => moment(date).format('MMM YYYY');
export const arrayOfWeeks = (maxWeek) => Array.from({ length: maxWeek }, (_, index) => index + 1);
