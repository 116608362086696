import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { CloseCircleFilled } from '@ant-design/icons';
import '../../tables.scss';

const CautionModal = ({
  cautionDescription, visible, closeModal, onYes,
}) => (
  <div className="caution-modal-wrapper">
    <Modal
      footer
      destroyOnClose
      className="caution-modal"
      closeIcon={<CloseCircleFilled />}
      title="Caution"
      visible={visible}
      onCancel={closeModal}
    >
      <p>{cautionDescription}</p>
      <div className="caution-buttons">
        <Button className="caution-no-button" onClick={closeModal}>No</Button>
        <Button className="caution-yes-button" onClick={onYes}>Yes</Button>
      </div>
    </Modal>
  </div>
);

CautionModal.propTypes = {
  cautionDescription: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  onYes: PropTypes.func.isRequired,
};

export default CautionModal;
