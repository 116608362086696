import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  skills: IList(),
  lessons: IList(),
  courses: IList(),
  disabledStartTimes: IList(),
  currentStep: 0,
  createdLesson: IMap(),
  lesson: IMap(),
  lessonSubmit: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_SKILLS:
      return state.set('skills', IList(action.payload.skills));
    case types.SET_COURSES:
      return state.set('courses', IList(action.payload.courses));
    case types.SET_LESSONS:
      return state.set('lessons', IList(action.payload.lessons));
    case types.SET_DISABLED_START_TIMES:
      return state.set('disabledStartTimes', IList(action.payload.times));
    case types.SET_CURRENT_STEP:
      return state.set('currentStep', action.payload.step);
    case types.SET_CREATED_LESSON:
      return state.set('createdLesson', fromJS(action.payload.lesson));
    case types.SET_LESSON:
      return state.set('lesson', fromJS(action.payload.lesson));
    case types.LESSON_SUBMIT:
      return state.set('lessonSubmit', action.payload.lessonSubmitState);
    default:
      return state;
  }
};
