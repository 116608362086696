import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { nameLength, searchArray } from 'utils/helpers';
import { getOverviewTableData } from 'utils/overviewTableDataHelper';
import Tables from '../Tables';
import { columns } from './components/columns';

const QuizOverview = ({ actions, overviewTableData }) => {
  const singleQuizOverviewData = getOverviewTableData(overviewTableData, '/quiz-overview', '/manage-quizzes');
  const totalPagination = singleQuizOverviewData?.courseAttendees?.length;
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    actions.setPaginationTotal(totalPagination);
  }, [totalPagination]);

  const formatName = (item) => nameLength(`${item?.firstName} ${item?.lastName}`, 25);
  const isCompletedCondition = (item) => (element) => element.userId === item.userId;

  const quizPoints = (item) => singleQuizOverviewData
    .userQuizzes?.find(isCompletedCondition(item));

  const quizTableData = searchArray(singleQuizOverviewData?.courseAttendees, 'users.firstName', searchVal)?.map((item) => {
    const quizItem = {
      childName: formatName(item?.users),
      enrollDate: moment(item?.from).format('DD/MM/YYYY'),
      status: quizPoints(item) ? 'Completed' : 'Pending',
      points: quizPoints(item)?.correctAnswersCount,
    };
    return quizItem;
  });

  return (
    <Tables
      dataSource={quizTableData}
      columns={columns}
      exportData
      showEntries
      tableTitle={singleQuizOverviewData?.title}
      backButton
      setSearchVal={setSearchVal}
      search
      searchPlaceholder="Search By Name"
    />
  );
};
QuizOverview.propTypes = {
  actions: PropTypes.shape({
    setPaginationTotal: PropTypes.func.isRequired,
  }).isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,
};
export default QuizOverview;
