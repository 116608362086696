import {
  all,
  put,
  takeLeading,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import authService from 'services/authService';
import history from '../../../localHistory';
import { types, toggleSuccessScreen, setIsLoading } from './actions';

function * requestPasswordReset(action) {
  yield put(setIsLoading(true));
  const { email } = action.payload;
  const { data, error } = yield authService.requestPasswordReset(email);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(toggleSuccessScreen(data));
    history.push('/reset-password/success');
  }
  yield put(setIsLoading(false));
}

function * resetPassword(action) {
  yield put(setIsLoading(true));
  const { email, token, newPassword } = action.payload;
  const { data, error } = yield authService.resetPassword(email, newPassword, token);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(toggleSuccessScreen(data));
  }
  yield put(setIsLoading(false));
}

export default function * resetPasswordSaga() {
  yield all([
    takeLeading(types.REQUEST_PASSWORD_RESET, requestPasswordReset),
    takeLeading(types.RESET_PASSWORD, resetPassword),
  ]);
}
