import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  children: IList(),
  childProfile: IMap(),
  childOverview: IMap(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ALL_CHILDREN:
      return state.set('children', IList(action.payload.children));
    case types.SET_CHILD_BY_ID:
      return state.set('childProfile', action.payload.data);
    case types.SET_CHILD_OVERVIEW:
      return state.set('childOverview', action.payload.data);
    default:
      return state;
  }
};
