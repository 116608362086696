import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  login,
  setIsLoading,
} from './modules/actions';
import View from './View';
import { selectIsLoading } from './modules/selectors';

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      login,
      setIsLoading,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
