import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setPaginationTotal } from 'components/Tables/modules/actions';
import { selectOverviewTableData } from 'components/Tables/modules/selectors';

const mapStateToProps = (state) => ({
  overviewTableData: selectOverviewTableData(state),

});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setPaginationTotal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps);
