import { authorizedApiService, apiService } from './apiService';

const BASE_PATH = '/auth';

export default {
  login: async (email, password, rememberMe) => {
    try {
      const response = await apiService.post(`${BASE_PATH}/login`, {
        email,
        password,
        rememberMe,
      });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  logout: async (refreshToken) => {
    try {
      return await apiService.post(`${BASE_PATH}/logout`, { refreshToken });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  refreshToken: async (refreshToken) => {
    try {
      return await apiService.post(`${BASE_PATH}/refresh-token`, { refreshToken });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  requestPasswordReset: async (email) => {
    try {
      return await apiService.post(`${BASE_PATH}/send-reset-password-email`, { email });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  resetPassword: async (email, password, token) => {
    try {
      return await apiService.post(`${BASE_PATH}/reset-password`, {
        email,
        password,
        token,
      });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  changePassword: async (oldPassword, newPassword) => {
    try {
      return await authorizedApiService.post(`${BASE_PATH}/change-password`, {
        oldPassword,
        newPassword,
      });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
