import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { nameLength, searchArray } from 'utils/helpers';
import { getOverviewTableData } from 'utils/overviewTableDataHelper';
import Tables from '../Tables';
import { columns } from './components/columns';

const CourseOverview = ({ actions, overviewTableData, courseOverview }) => {
  const singleCourseTableData = getOverviewTableData(overviewTableData, '/course-overview', '/manage-courses');
  const [searchVal, setSearchVal] = useState('');
  const totalPagination = courseOverview?.toJS().courseAttendees?.length;

  actions.setPaginationTotal(totalPagination);

  const formatName = (item) => nameLength(`${item?.firstName} ${item?.lastName}`, 25);

  const childProfileData = searchArray(courseOverview?.toJS().courseAttendees, 'users.firstName', searchVal)?.map((item) => {
    const childrenItem = {
      childName: formatName(item?.users),
      parentEmail: item?.users?.parent?.email,
      enrollDate: moment(item?.from).format('DD/MM/YYYY'),
      status: moment(new Date()).isBefore(item?.to, 'second') ? item?.status : 'Completed',
    };
    return childrenItem;
  });

  useEffect(() => {
    actions.getCourseOverview(singleCourseTableData?.id);
  }, []);

  return (
    <div>
      <Tables
        dataSource={childProfileData}
        columns={columns}
        exportData
        showEntries
        tableTitle={singleCourseTableData?.title}
        backButton
        setSearchVal={setSearchVal}
        search
        searchPlaceholder="Search By Name"
      />
    </div>
  );
};
CourseOverview.propTypes = {
  actions: PropTypes.shape({
    setPaginationTotal: PropTypes.func.isRequired,
    getCourseOverview: PropTypes.func.isRequired,
  }).isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,
  courseOverview: ImmutablePropTypes.map.isRequired,
};
export default CourseOverview;
