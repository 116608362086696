import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { getOverviewTableData } from 'utils/overviewTableDataHelper';
import { searchArray } from 'utils/helpers';
import Tables from '../Tables';
import { columns } from './components/columns';

const ChildOverview = ({ actions, childOverviewData, overviewTableData }) => {
  const singleChildTableData = getOverviewTableData(overviewTableData, '/child-overview', '/child-profile');
  const [searchVal, setSearchVal] = useState('');
  const [weekFilter, setWeekFilter] = useState('');
  const [lessons, setLessons] = useState([]);
  const [quizzes, setQuizzes] = useState([]);

  const weekFilterHandler = (item) => {
    setWeekFilter(item);
  };

  const courseAttendees = childOverviewData?.courseAttendees?.[0];

  useEffect(() => {
    actions.getChildOverview(singleChildTableData?.userId, singleChildTableData?.courseId);
  }, []);

  useEffect(() => {
    if (courseAttendees) {
      setLessons(courseAttendees.course?.lessons?.map((item) => ({ ...item, content: 'Lesson' })));
      setQuizzes(courseAttendees.course?.quizzes?.map((item) => ({ ...item, content: 'Quiz' })));
    }
  }, [courseAttendees]);
  useEffect(() => {
    actions.setPaginationTotal(lessons.length + quizzes.length);
  }, [lessons, quizzes]);

  const tableData = [...lessons, ...quizzes];
  const childProfileData = searchArray(tableData, 'title', searchVal).map((item) => {
    const pointsScoredValue = item?.userQuizzes?.[0];
    const statusValue = item?.watchedLessonId?.length > 0 || item?.userQuizzes?.length > 0 ? 'Completed' : 'Pending';
    const childrenItem = {
      content: item?.content,
      title: item?.title,
      pointsScored: pointsScoredValue?.correctAnswersCount,
      courseWeek: item?.week,
      status: statusValue,
    };
    return childrenItem;
  });

  const weekOptions = [];
  for (let i = courseAttendees?.course?.totalWeeks; i > 0; i--) {
    weekOptions.push(i);
  }

  const filterTableData = childProfileData?.filter((item) => item?.courseWeek?.toString()
    .includes(weekFilter));

  return (
    <div>
      <Tables
        dataSource={filterTableData}
        columns={columns}
        setSearchVal={setSearchVal}
        search
        exportData
        showEntries
        searchPlaceholder="Search by title"
        tableTitle={`${singleChildTableData?.courseTitle}`}
        rowOptions={['Completed', 'In Progress']}
        backButton
        weekFilter
        setWeekFilter={weekFilterHandler}
        weekOptions={weekOptions}
      />
    </div>
  );
};

ChildOverview.propTypes = {
  actions: PropTypes.shape({
    getChildOverview: PropTypes.func.isRequired,
    setPaginationTotal: PropTypes.func.isRequired,
  }).isRequired,
  childOverviewData: ImmutablePropTypes.list.isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,

};

export default ChildOverview;
