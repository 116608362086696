import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  currentStudent: {},
  userActivities: [],
  isLoadingStudent: false,
  isLoadingTable: false,
  pagination: {
    current: 1,
    pageSize: 10,
    showSizeChanger: false,
  },
  activeTab: '1',
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_STUDENT:
      return state.set('currentStudent', action.payload.data);
    case types.SET_USER_ACTIVITIES:
      return state.set('userActivities', fromJS(action.payload.data));
    case types.SET_IS_LOADING_STUDENT:
      return state.set('isLoadingStudent', action.payload.isLoading);
    case types.SET_IS_LOADING_TABLE:
      return state.set('isLoadingTable', action.payload.isLoading);
    case types.SET_CURRENT_PAGE_STUDENT_PROFILE:
      return state.setIn(['pagination', 'current'], action.payload.currentPage);
    case types.SET_ACTIVE_TAB_STUDENT_PROFILE:
      return state.set('activeTab', action.payload.activeTab);
    case types.SET_TOTAL_COUNT_STUDENT_PROFILE:
      return state.setIn(['pagination', 'total'], action.payload.totalCount);
    default:
      return state;
  }
};
