import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  setPaginationCurrent, setPaginationNoOfRows, setOffset, resetOverviewTableData,
} from 'components/Tables/modules/actions';
import View from './View';
import {
  getParents,
  createCouponCode,
  setCouponData,
} from './modules/actions';
import {
  selectCouponData,
  selectParents,
} from './modules/selectiors';
import { selectPagination, selectShowEntries } from '../Tables/modules/selectors';

const mapStateToProps = (state) => ({
  parents: selectParents(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
  couponData: selectCouponData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getParents,
      setPaginationCurrent,
      setPaginationNoOfRows,
      setOffset,
      createCouponCode,
      setCouponData,
      resetOverviewTableData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
