export const types = {
  GET_USER_SUBSCRIPTIONS_OVERVIEW: 'GET_USER_SUBSCRIPTIONS_OVERVIEW',
  SET_USER_SUBSCRIPTIONS_OVERVIEW: 'SET_USER_SUBSCRIPTIONS_OVERVIEW',
  GET_SUBSCRIPTIONS_PAYMENT_PER_USER: 'GET_SUBSCRIPTIONS_PAYMENT_PER_USER',
  SET_SUBSCRIPTIONS_PAYMENT_PER_USER: 'SET_SUBSCRIPTIONS_PAYMENT_PER_USER',
  GET_TRIALS_PAYMENT_PER_USER: 'GET_TRIALS_PAYMENT_PER_USER',
  SET_TRIALS_PAYMENT_PER_USER: 'SET_TRIALS_PAYMENT_PER_USER',
  GET_MONTHLY_REVENUE: 'GET_MONTHLY_REVENUE',
  SET_MONTHLY_REVENUE: 'SET_MONTHLY_REVENUE',
  RESET_SUBSCRIPTIONS_PAYMENT_PER_USER: 'RESET_SUBSCRIPTIONS_PAYMENT_PER_USER',
  RESET_TRIALS_PAYMENT_PER_USER: 'RESET_TRIALS_PAYMENT_PER_USER',
  GET_AVERAGE_PAYMENT_PER_USER: 'GET_AVERAGE_PAYMENT_PER_USER',
  SET_AVERAGE_PAYMENT_PER_USER: 'SET_AVERAGE_PAYMENT_PER_USER',
  SET_AVERAGE_PAYMENT_PER_USER_BY_DEVICE: 'SET_AVERAGE_PAYMENT_PER_USER_BY_DEVICE',
  SET_AVERAGE_PAYMENT_CALCULATION_DATA: 'SET_AVERAGE_PAYMENT_CALCULATION_DATA',
  GET_TRIAL_MEMBERSHIPS: 'GET_TRIAL_MEMBERSHIPS',
  SET_TRIAL_MEMBERSHIPS: 'SET_TRIAL_MEMBERSHIPS',
  GET_SUBSCRIPTION_MEMBERSHIPS: 'GET_SUBSCRIPTION_MEMBERSHIPS',
  SET_SUBSCRIPTION_MEMBERSHIPS: 'SET_SUBSCRIPTION_MEMBERSHIPS',
  GET_PLATFORM_INSTALLATIONS: 'GET_PLATFORM_INSTALLATIONS',
  SET_PLATFORM_INSTALLATIONS: 'SET_PLATFORM_INSTALLATIONS',
  SET_TIME_RANGE_FILTER_SINGLE_OPTION:'SET_TIME_RANGE_FILTER_SINGLE_OPTION',
  SET_TIME_RANGE_FILTER_GLOBAL_OPTION: 'SET_TIME_RANGE_FILTER_GLOBAL_OPTION',
  GET_ACTIVE_USERS: 'GET_ACTIVE_USERS',
  SET_ACTIVE_USERS: 'SET_ACTIVE_USERS',
  EXPORT_DATA: 'EXPORT_DATA',
  GET_COURSE_METRICS: 'GET_COURSE_METRICS',
  SET_COURSE_METRICS: 'SET_COURSE_METRICS',
  GET_LESSON_METRICS: 'GET_LESSON_METRICS',
  SET_LESSON_METRICS: 'SET_LESSON_METRICS',
  GET_QUIZ_METRICS: 'GET_QUIZ_METRICS',
  SET_QUIZ_METRICS: 'SET_QUIZ_METRICS',
  GET_TYPE_OF_USERS: 'GET_TYPE_OF_USERS',
  SET_TYPE_OF_USERS: 'SET_TYPE_OF_USERS',
  SET_CURRENT_MEMBERSHIP_SUBSCRIPTIONS: 'SET_CURRENT_MEMBERSHIP_SUBSCRIPTIONS',
  SET_CURRENT_MEMBERSHIP_TRIALS: 'SET_CURRENT_MEMBERSHIP_TRIALS',
  GET_CURRENT_MEMBERSHIP: 'GET_CURRENT_MEMBERSHIP',
  GET_WAU_ANALYTICS: 'GET_WAU_ANALYTICS',
  SET_WAU_ANALYTICS: 'SET_WAU_ANALYTICS',
};

export const getMonthlyRevenue = (params) => ({
  type: types.GET_MONTHLY_REVENUE,
  payload: { params },
});

export const setAveragePaymentCalculationData = (data) => ({
  type: types.SET_AVERAGE_PAYMENT_CALCULATION_DATA,
  payload: { data },
});

export const setMonthlyRevenue = (data) => ({
  type: types.SET_MONTHLY_REVENUE,
  payload: { data },
});

export const getSubscriptionPaymentPerUser = (params) => ({
  type: types.GET_SUBSCRIPTIONS_PAYMENT_PER_USER,
  payload: { params },
});

export const setSubscriptionPaymentPerUser = (data) => ({
  type: types.SET_SUBSCRIPTIONS_PAYMENT_PER_USER,
  payload: { data },
});

export const getTrialsPaymentPerUser = (params) => ({
  type: types.GET_TRIALS_PAYMENT_PER_USER,
  payload: { params },
});

export const setTrialsPaymentPerUser = (data) => ({
  type: types.SET_TRIALS_PAYMENT_PER_USER,
  payload: { data },
});

export const getUserSubscriptionOverview = (params) => ({
  type: types.GET_USER_SUBSCRIPTIONS_OVERVIEW,
  payload: { params },
});

export const setUserSubscriptionOverview = (data) => ({
  type: types.SET_USER_SUBSCRIPTIONS_OVERVIEW,
  payload: { data },
});

export const resetSubscriptionPaymentPerUserState = () => ({
  type: types.RESET_SUBSCRIPTIONS_PAYMENT_PER_USER,
});
export const resetTrialsPaymentPerUserState = () => ({
  type: types.RESET_TRIALS_PAYMENT_PER_USER,
});

export const getAveragePaymentOfUsers = (params) => ({
  type: types.GET_AVERAGE_PAYMENT_PER_USER,
  payload: { params },
});

export const setAveragePaymentOfUsers = (data) => ({
  type: types.SET_AVERAGE_PAYMENT_PER_USER,
  payload: { data },
});

export const setAveragePaymentOfUsersByDevice = (data) => ({
  type: types.SET_AVERAGE_PAYMENT_PER_USER_BY_DEVICE,
  payload: { data },
});

export const getTrialMemberships = (params) => ({
  type: types.GET_TRIAL_MEMBERSHIPS,
  payload: { params },
});

export const setTrialMemberships = (data) => ({
  type: types.SET_TRIAL_MEMBERSHIPS,
  payload: { data },
});

export const getTypeOfUsers = (params) => ({
  type: types.GET_TYPE_OF_USERS,
  payload: { params },
});

export const setTypeOfUsers = (data) => ({
  type: types.SET_TYPE_OF_USERS,
  payload: { data },
});

export const getSubscriptionMemberships = (params) => ({
  type: types.GET_SUBSCRIPTION_MEMBERSHIPS,
  payload: { params },
});

export const setSubscriptionMemberships = (data) => ({
  type: types.SET_SUBSCRIPTION_MEMBERSHIPS,
  payload: { data },
});

export const getPlatformInstallations = (params) => ({
  type: types.GET_PLATFORM_INSTALLATIONS,
  payload: { params },
});

export const setPlatformInstallations = (data) => ({
  type: types.SET_PLATFORM_INSTALLATIONS,
  payload: { data },
});

export const setTimeRangeFilterSingleOption = (data) => ({
  type: types.SET_TIME_RANGE_FILTER_SINGLE_OPTION,
  payload: { data },
});
export const setTimeRangeFilterGlobalOption = (data) => ({
  type: types.SET_TIME_RANGE_FILTER_GLOBAL_OPTION,
  payload: { data },
});

export const getActiveUsers = (params) => ({
  type: types.GET_ACTIVE_USERS,
  payload: { params },
});

export const setActiveUsers = (data) => ({
  type: types.SET_ACTIVE_USERS,
  payload: { data },
});
export const getWauAnalytics = (params) => ({
  type: types.GET_WAU_ANALYTICS,
  payload: { params },
});

export const setWauAnalytics = (data) => ({
  type: types.SET_WAU_ANALYTICS,
  payload: { data },
});

export const exportDataAction = (data) => ({
  type: types.EXPORT_DATA,
  payload: { data },
});

export const getCourseMetrics = () => ({
  type: types.GET_COURSE_METRICS,
});

export const setCourseMetrics = (data) => ({
  type: types.SET_COURSE_METRICS,
  payload: { data },
});

export const getLessonMetrics = (data) => ({
  type: types.GET_LESSON_METRICS,
  payload: { data },
});

export const setLessonMetrics = (data) => ({
  type: types.SET_LESSON_METRICS,
  payload: { data },
});

export const getQuizMetrics = (data) => ({
  type: types.GET_QUIZ_METRICS,
  payload: { data },
});

export const setQuizMetrics = (data) => ({
  type: types.SET_QUIZ_METRICS,
  payload: { data },
});

export const getCurrentMembership = (data) => ({
  type: types.GET_CURRENT_MEMBERSHIP,
  payload: { data },
});

export const setCurrentMembershipSubscriptions = (data) => ({
  type: types.SET_CURRENT_MEMBERSHIP_SUBSCRIPTIONS,
  payload: { data },
});

export const setCurrentMembershipTrials = (data) => ({
  type: types.SET_CURRENT_MEMBERSHIP_TRIALS,
  payload: { data },
});
