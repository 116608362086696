import {
  all, put, takeLatest, call,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import skillsService from 'services/skillsService';
import coursesService from 'services/coursesService';
import lessonsService from 'services/lessonsService';
import { cloudinaryCloudName, cloudinaryUploadPreset } from 'utils/constants';
import { createCourseThumbnail } from '../../../services/courseImageService';
import { setIsLoading, setPaginationTotal, setPaginationNoOfRows } from '../../Tables/modules/actions';
import { getLessonsByCourse } from '../../Schedule/modules/actions';

import {
  types,
  setSkills,
  setLessons,
  setDisabledStartTimes,
  setCurrentStep,
  setCreatedLesson,
  setLesson,
  setCourses,
  lessonSubmit,
} from './actions';

const moment = extendMoment(Moment);

export function * getSkills() {
  const { data, error } = yield skillsService.getSkills();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setSkills(data));
  }
}

export function * getCourses(action) {
  yield put(setIsLoading(true));
  const { pagination } = action.payload;
  const { data, error } = yield coursesService.getCourses(pagination);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourses(data.rows));
    yield put(setPaginationTotal(data.count));
    if (pagination.showEntries === 'All') {
      yield put(setPaginationNoOfRows(data.count));
    }
  }
  yield put(setIsLoading(false));
}

export function * getLessons(action) {
  // this function is used for checking if there are overlapping lessons when creating/editing lesson
  // when a lesson is being edited, it shouldn't be forbidden to schedule it in its current timeframe
  // that is why lesson id is sent to check if lesson is the same as the lesson that is being edited
  const { from, to, lessonId } = action.payload;
  const { data, error } = yield lessonsService.getLessons(from, to);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    const disabledStartTimes = [];
    const disabledEndTimes = [];
    data.forEach((lesson) => {
      if (!lessonId || (lessonId && lesson.id !== lessonId)) {
        const range = moment.range(lesson.from, lesson.to);
        const dt = Array.from(range.by('minutes', { step: 15 }));
        disabledStartTimes.push(...dt.slice(0, dt.length - 1));
        disabledEndTimes.push(...dt.slice(1));
      }
    });
    yield all([
      put(setLessons(data)),
      put(setDisabledStartTimes(disabledStartTimes)),
    ]);
  }
}

function * createLesson(action) {
  const { lesson } = action.payload;

  yield put(lessonSubmit(true));
  const dataForCloudinary = new FormData();
  dataForCloudinary.append('file', lesson.image);
  dataForCloudinary.append('upload_preset', cloudinaryUploadPreset);
  dataForCloudinary.append('cloud_name', cloudinaryCloudName);

  const cloudinaryUpload = yield call(createCourseThumbnail, dataForCloudinary);

  const dataFromCloudinary = cloudinaryUpload.data;
  delete lesson.image;
  delete lesson.skillIds;
  const lessonData = { lesson, dataFromCloudinary };

  const { data, error } = yield call(lessonsService.createLesson, lessonData);

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: 'An error occurred while creating a new lesson',
    });
  } else {
    yield all([put(setCreatedLesson(data)), put(setCurrentStep(2))]);
  }
  yield put(lessonSubmit(false));
  yield put(getLessonsByCourse({ current: 1, noOfRows: 20, searchVal: '' }));
}

function * getLesson(action) {
  const { id } = action.payload;
  const { data, error } = yield lessonsService.getLessonById(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setLesson(data));
  }
}

function * updateLesson(action) {
  const { id, lesson } = action.payload;
  let dataFromCloudinary = {};

  if (!lesson?.image?.publicId) {
    const dataForCloudinary = new FormData();
    dataForCloudinary.append('file', lesson.updatedData.image);
    dataForCloudinary.append('upload_preset', cloudinaryUploadPreset);
    dataForCloudinary.append('cloud_name', cloudinaryCloudName);

    const cloudinaryUpload = yield call(createCourseThumbnail, dataForCloudinary);

    dataFromCloudinary = cloudinaryUpload.data;
  } else {
    dataFromCloudinary = lesson.image;
  }

  delete lesson.image;
  delete lesson.skillIds;
  const lessonData = { lesson, dataFromCloudinary };
  const { data, error } = yield lessonsService.updateCompleteLesson(id, lessonData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([put(setCreatedLesson(data)), put(setCurrentStep(2))]);
    yield put(getLessonsByCourse({ current: 1, noOfRows: 20, searchVal: '' }));
  }
}

export default function * createLessonSaga() {
  yield all([
    takeLatest(types.GET_SKILLS, getSkills),
    takeLatest(types.GET_LESSONS, getLessons),
    takeLatest(types.CREATE_LESSON, createLesson),
    takeLatest(types.GET_LESSON, getLesson),
    takeLatest(types.UPDATE_LESSON, updateLesson),
    takeLatest(types.GET_COURSES, getCourses),
  ]);
}
