import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setSelectedCourseForFilter } from 'components/ManageCourses/modules/actions';
import View from './View';
import { selectIsLoading, selectPagination, selectShowEntries } from './modules/selectors';
import {
  setPaginationCurrent, setPaginationNoOfRows, setOffset, setShowEntries,
  setOverviewTableData, resetPagination,
} from './modules/actions';

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setPaginationCurrent,
      setPaginationNoOfRows,
      setOffset,
      setShowEntries,
      setSelectedCourseForFilter,
      setOverviewTableData,
      resetPagination,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
