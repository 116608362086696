import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { singleAnswerQuestionType, multipleChoiceQuestionType, dragAndDropQuestionType } from 'utils/constants';
import SingleAnswerQuestionType from '../SingleAnswerQuestionType';
import MultipleChoiceQuestionType from '../MultipleChoiceQuestionType';
import DragAndDropQuestionType from '../DragAndDropQuestionType';

const QuestionType = ({
  question, i,
  isVideoQuiz, updateCorrect, correctAnswers,
  questions, removeQuestion, getAnswerLetter, t, handleDragAndDropQuestionData,
}) => {
  const Content = useMemo(() => {
    if (question.questionType === singleAnswerQuestionType) {
      return (
        <SingleAnswerQuestionType
          isVideoQuiz={isVideoQuiz}
          question={question}
          updateCorrect={updateCorrect}
          correctAnswers={correctAnswers}
          questions={questions}
          removeQuestion={removeQuestion}
          t={t}
          getAnswerLetter={getAnswerLetter}
          questionCount={i}
        />
      );
    }
    if (question.questionType === multipleChoiceQuestionType) {
      return (
        <MultipleChoiceQuestionType
          isVideoQuiz={isVideoQuiz}
          question={question}
          updateCorrectMultipleChoice={updateCorrect}
          correctAnswers={correctAnswers}
          questions={questions}
          removeQuestion={removeQuestion}
          t={t}
          getAnswerLetter={getAnswerLetter}
          questionCount={i}
        />
      );
    }
    if (question.questionType === dragAndDropQuestionType) {
      return (
        <DragAndDropQuestionType
          isVideoQuiz={isVideoQuiz}
          question={question}
          updateCorrectMultipleChoice={updateCorrect}
          correctAnswers={correctAnswers}
          questions={questions}
          removeQuestion={removeQuestion}
          t={t}
          getAnswerLetter={getAnswerLetter}
          questionCount={i}
          handleDragAndDropQuestionData={handleDragAndDropQuestionData}
        />
      );
    }
    return <div />;
  }, [correctAnswers, getAnswerLetter, handleDragAndDropQuestionData,
    i, isVideoQuiz, question, questions, removeQuestion, t, updateCorrect]);
  return (
    <div>{Content}</div>
  );
};

QuestionType.propTypes = {
  isVideoQuiz: PropTypes.bool.isRequired,
  i: PropTypes.number.isRequired,
  question: PropTypes.shape({
    answers: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
    })),
    key: PropTypes.string.isRequired,
    questionType: PropTypes.string.isRequired,
  }).isRequired,
  handleDragAndDropQuestionData: PropTypes.func.isRequired,
  correctAnswers: PropTypes.shape({}).isRequired,
  questions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  removeQuestion: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  getAnswerLetter: PropTypes.func.isRequired,
  updateCorrect: PropTypes.func.isRequired,

};

export default QuestionType;
