import {
  all,
  put,
  takeLatest,
  takeLeading,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import * as FullStory from '@fullstory/browser';

import authService from 'services/authService';
import meService from 'services/meService';
import history from 'localHistory';
import { environment } from 'utils/constants';
import { setCurrentUser, setIsLoading, types } from './actions';

function * login(action) {
  const { email, password, remember } = action.payload;
  const { data, error } = yield authService.login(email, password, remember);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
    yield put(setIsLoading(false));
  } else if (data.user.type === 'TEACHER' || data.user.type === 'ADMIN' || data.user.type === 'MARKETING') {
    localStorage.setItem('token', data.accessToken);
    localStorage.setItem('userId', data.user.id);
    localStorage.setItem('email', data.user.email);
    if (environment === 'production') {
      FullStory.setUserVars({
        displayName: data.user.email,
      });
    }
    if (remember) {
      localStorage.setItem('refreshToken', data.refreshToken);
    }
    yield getCurrentUser();
    yield put(setIsLoading(false));
    history.push('/');
  } else {
    notification.error({
      message: 'Invalid login',
      description: 'You do not have access permissions',
    });
    yield put(setIsLoading(false));
  }
}

function * getCurrentUser() {
  const { data, error } = yield meService.getInfo();
  if (error) {
    history.push('/login');
  } else {
    yield put(setCurrentUser(data));
  }
}

function * logoutUser() {
  localStorage.removeItem('refreshToken');
  localStorage.removeItem('token');
  localStorage.removeItem('userId');
  yield put(setCurrentUser(null));
  history.push('/');
}

export default function * loginSaga() {
  yield all([
    takeLeading(types.LOGIN, login),
    takeLatest(types.GET_CURRENT_USER, getCurrentUser),
    takeLatest(types.LOGOUT_USER, logoutUser),
  ]);
}
