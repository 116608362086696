import React, { useEffect, useState, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  Button, Upload, notification,
} from 'antd';
import { PaperClipOutlined } from '@ant-design/icons';
import * as tus from 'tus-js-client';

import UploadThumbnail from 'utils/UploadThumbnail';
import vimeoService from 'services/vimeoService';
import { vimeoUploadEndpoint } from 'utils/constants';
import VideoItem from '../VideoItem';
import '../createLesson.scss';
import './addFiles.scss';

const AddFiles = (props) => {
  const {
    t,
    isCurrentStep,
    lesson,
    editQuiz,
    setQuiz,
    editMode,
    videos,
    setVideos,
    quizDataOnSubmit,
    handleSetImage,
    image,
    setDeletedVideos,
    setDeletedQuizzes,
    setUpdatedQuizzes,
  } = props;
  const [currentVideoUploading, setCurrentVideoUploading] = useState(null);

  const handleUpload = async (uploadData) => {
    const { onSuccess: onFinish, file } = uploadData;

    // Get the selected file from the input element
    const fileName = file.name;
    const fileSize = file.size.toString();

    const response = await vimeoService.uploadVideoOnVimeo({
      upload: {
        approach: 'tus',
        size: fileSize,
      },
    });

    const newVideo = {
      videoName: fileName,
      url: response.data.link,
      order: videos.length + 1,
    };

    setVideos((prevVideos) => [...prevVideos, newVideo]);
    setCurrentVideoUploading(newVideo);
    // Create a new tus upload
    const upload = new tus.Upload(file, {
      endPoint: vimeoUploadEndpoint,
      uploadUrl: response.data.upload.upload_link,
      retryDelays: [0, 3000, 5000, 10000, 20000],
      metadata: {
        filename: file.name,
        filetype: file.type,
      },
      onError(error) {
        notification.error({
          message: t('createLesson addFiles uploadVideoFail'),
          description: error,
        });
      },
      onProgress(bytesUploaded, bytesTotal) {
        const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        setCurrentVideoUploading((prevState) => ({
          ...prevState,
          uploadPercentage: percentage,
        }));
      },

      async onSuccess() {
        onFinish();
        setCurrentVideoUploading(null);

        // Set uploaded video in specific folder on vimeo
        const videoId = response.data.uri.split('/')[2];
        await vimeoService.updateVimeoVideoLocation(videoId);
      },
    });

    // Start the upload
    upload.start();
  };

  useEffect(() => {
    if (lesson) {
      const currentVideos = lesson.get('videos');

      if (currentVideos) {
        setVideos(currentVideos.toJS());
      }
    } else {
      setVideos([]);
    }
  }, [lesson]);

  const removeVideo = async (video) => {
    setVideos((prevVideos) => prevVideos.filter((currVideo) => currVideo.url !== video.url));
    setDeletedVideos((prev) => [...prev, video.id]);
  };

  const setVideosHandler = (data) => {
    setVideos(data);
  };

  const videosMapping = useMemo(() => (
    videos.map((video) => (
      <VideoItem
        key={video?.id}
        video={video}
        setQuiz={setQuiz}
        removeVideo={removeVideo}
        currentVideoUploading={currentVideoUploading}
        editQuiz={editQuiz}
        editMode={editMode}
        t={t}
        quizDataOnSubmit={quizDataOnSubmit}
        setVideosHandler={setVideosHandler}
        videos={videos}
        setDeletedVideos={setDeletedVideos}
        setDeletedQuizzes={setDeletedQuizzes}
        setUpdatedQuizzes={setUpdatedQuizzes}
      />
    ))
  ), [
    currentVideoUploading,
    editMode, editQuiz,
    quizDataOnSubmit,
    setVideosHandler,
    removeVideo,
    t,
    videos,
    setQuiz,
    setDeletedQuizzes, setDeletedVideos, setUpdatedQuizzes,
  ]);

  const videoAcceptCriteria = 'video/mp4, video/mov, video/quicktime, video/wmv, video/x-m4v, video/*';

  if (!isCurrentStep) return null;
  return (
    <div>
      <div className="create-lesson-upload-wrapper">
        <div className="upload-label-wrapper">
          <span className="create-lesson-input-label">Thumbnail</span>
          <UploadThumbnail
            handleSetImage={handleSetImage}
            image={image}
          />
        </div>
        <div className="upload-label-wrapper">
          <span className="create-lesson-input-label">Content</span>
          <Upload
            className="upload-wrapper"
            showUploadList={false}
            customRequest={handleUpload}
            accept={videoAcceptCriteria}
          >
            <Button className="upload-button" size="large" icon={<PaperClipOutlined />}>
              {t('createLesson addFiles uploadVideo')}
            </Button>
          </Upload>
        </div>
      </div>

      <div className="create-lesson-form-divider" />
      {videosMapping}
    </div>
  );
};

AddFiles.propTypes = {
  t: PropTypes.func.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  lesson: ImmutablePropTypes.map,
  editQuiz: ImmutablePropTypes.map,
  setQuiz: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  videos: PropTypes.string.isRequired,
  setVideos: PropTypes.func.isRequired,
  quizDataOnSubmit: PropTypes.bool.isRequired,
  image: PropTypes.string.isRequired,
  handleSetImage: PropTypes.func.isRequired,
  setDeletedVideos: PropTypes.func.isRequired,
  setDeletedQuizzes: PropTypes.func.isRequired,
  setUpdatedQuizzes: PropTypes.func.isRequired,
};

AddFiles.defaultProps = {
  lesson: null,
  editQuiz: null,
  editMode: false,
};

export default withTranslation()(AddFiles);
