import history from '../localHistory';

/* eslint-disable import/prefer-default-export */
export const getOverviewTableData = (data, pathname, parentPathname) => {
  const pathnameTableData = data.toJS()[pathname]?.value;
  if (!pathnameTableData) {
    history.push(parentPathname);
  }
  return pathnameTableData;
};
