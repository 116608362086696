import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { requestPasswordReset, resetPassword } from './modules/actions';
import { selectIsLoading } from './modules/selectors';
import View from './View';

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      requestPasswordReset,
      resetPassword,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
