import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Progress } from 'antd';
import { analyticsColor, deviceFilterItems } from './options';
import '../styles.scss';

const Subscriptions = ({
  subscriptionMemberships,
  getSubscriptionMemberships,
}) => {
  const [selectedItem, setSelectedItem] = useState(4);
  const [deviceFilterValue, setDeviceFilterValue] = useState('ALL');

  const onItemClick = (value, key) => () => {
    setSelectedItem(key);
    setDeviceFilterValue(value);
  };
  const totalNum = Object.values(subscriptionMemberships)
    .reduce((acc, value) => acc + Number(value), 0);
  const activeNumber = subscriptionMemberships.active;
  const trialsNumber = subscriptionMemberships.trials;
  const cancelledNumber = subscriptionMemberships.cancels;

  useEffect(() => {
    getSubscriptionMemberships({ deviceFilter: deviceFilterValue });
  }, [selectedItem]);

  const mappedItems = deviceFilterItems.map((item) => (
    <span
      className={classNames({
        'time-range-filter-item': true,
        'time-range-filter-item-active': selectedItem === item.key,
      })}
      key={item.key}
      onClick={onItemClick(item.value, item.key)}
    >
      {item.value}
    </span>
  ));

  return (
    <div className="memebership-wrapper">
      <div className="trials-wrapper">
        <div className="analytics-header-wrapper">
          <span className="analytics-title">Current Subscriptions</span>
          <div>
            {mappedItems}
          </div>
        </div>
        <div className="analytics-info">
          <span className="value">{totalNum}</span>
          <span className="label">Active Subscriptions</span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">Active</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.iosColor} showInfo={false} percent={(activeNumber / totalNum) * 100} />
          <span className="value">{activeNumber}</span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">Trials</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.androidColor} showInfo={false} percent={(trialsNumber / totalNum) * 100} />
          <span className="value">{trialsNumber}</span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">Cancelled</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.webColor} showInfo={false} percent={(cancelledNumber / totalNum) * 100} />
          <span className="value">{cancelledNumber}</span>
        </div>
      </div>
    </div>

  );
};

Subscriptions.propTypes = {
  getSubscriptionMemberships: PropTypes.func.isRequired,
  subscriptionMemberships: PropTypes.objectOf.isRequired,
};

export default Subscriptions;
