import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  currentStep: 0,
  courses: IList(),
  createdCourse: IMap(),
  courseImageData: IMap(),
  courseImageLandscape: IMap(),
  isLoading: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_STEP:
      return state.set('currentStep', action.payload.step);
    case types.SET_CREATED_COURSE:
      return state.set('createdCourse', fromJS(action.payload.course));
    case types.SET_COURSE:
      return state.set('course', fromJS(action.payload.course));
    case types.SET_COURSE_IMAGE:
      return state.set('courseImageData', fromJS(action.payload.courseImage));
    case types.SET_COURSE_IMAGE_LANDSCAPE:
      return state.set('courseImageDataLandscape', fromJS(action.payload.courseImage));
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    default:
      return state;
  }
};
