import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import quizzesService from 'services/quizzesService';
import coursesService from 'services/coursesService';
import { getQuizzes } from 'components/ManageQuizzes/modules/actions';
import {
  types, setCreatedQuiz, setCurrentStep, setCourses, setQuizById, setIsLoading,
} from './actions';

export function * createQuiz(action) {
  const { quiz } = action.payload;
  const { data, error } = yield quizzesService.createQuiz(quiz);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setCreatedQuiz(data)),
      put(setCurrentStep(1)),
    ]);
  }
  yield put(setIsLoading(false));
  yield put(getQuizzes({ current: 1, noOfRows: 20, searchVal: '' }));
}

export function * getQuizById(action) {
  const { id } = action.payload;
  const { data, error } = yield quizzesService.getQuizByIdData(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setQuizById(data));
  }
}

export function * updateQuiz(action) {
  const { id, quiz } = action.payload;
  const { data, error } = yield quizzesService.updateQuizData(id, quiz);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setCreatedQuiz(data)),
      put(setCurrentStep(1)),
      yield put(getQuizzes({ current: 1, noOfRows: 20, searchVal: '' })),
    ]);
  }
  yield put(setIsLoading(false));
}

export function * getCourses() {
  const { data, error } = yield coursesService.getCourses();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourses(data));
  }
}

export default function * createQuizSaga() {
  yield all([
    takeLatest(types.CREATE_QUIZ, createQuiz),
    takeLatest(types.GET_QUIZ, getQuizById),
    takeLatest(types.UPDATE_QUIZ, updateQuiz),
  ]);
}
