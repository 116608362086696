import { List as IList, Map as IMap } from 'immutable';
import { createSelector } from 'reselect';

const subscriptionsPerUserSelector = (state) => state.analyticsReducer.get('subscriptionPaymentPerUser', IList());
const trialsPerUserSelector = (state) => state.analyticsReducer.get('trialsPaymentPerUser', IList());
const subscriptionPaymentPerUserHasMoreData = (state) => state.analyticsReducer.get('subscriptionPaymentPerUserHasMoreData', Boolean);
const trialsPaymentPerUserHasMoreData = (state) => state.analyticsReducer.get('trialsPaymentPerUserHasMoreData', Boolean);
const averagePaymentByUserDataSelector = (state) => state.analyticsReducer.get('averagePaymentByUserData', IList());
const averagePaymentByUserDataByDeviceSelector = (state) => state.analyticsReducer.get('averagePaymentByUserDataByDevice', IMap());
const averagePaymentCalculationDataSelector = (state) => state.analyticsReducer.get('averagePaymentCalculationData', IMap());
const monthlyRevenueDataSelector = (state) => state.analyticsReducer.get('monthlyRevenue', IMap());
const trialMembershipsDataSelector = (state) => state.analyticsReducer.get('trialMemberships', IMap());
const subscriptionMembershipsDataSelector = (state) => state.analyticsReducer.get('subscriptionMemberships', IMap());
const selectPlatformInstallations = (state) => state.analyticsReducer.get('platformInstallations', IList());
const timeRangeFilterSelector = (state) => state.analyticsReducer.get('timeRangeFilter', IMap());
const activeUsersSelector = (state) => state.analyticsReducer.get('activeUsers', IMap());
const userSubscriptionsOverviewSelector = (state) => state.analyticsReducer.get('userSubscriptionsOverview', IList());
const courseMetricsSelector = (state) => state.analyticsReducer.get('courseMetrics', IList());
const lessonMetricsSelector = (state) => state.analyticsReducer.get('lessonMetrics', IList());
const quizMetricsSelector = (state) => state.analyticsReducer.get('quizMetrics', IList());
const currentTrialSelector = (state) => state.analyticsReducer.get('currentMembershipTrials', IMap());
const currentSubscriptionSelector = (state) => state.analyticsReducer.get('currentMembershipSubscriptions', IMap());
const typeOfUsersDataSelector = (state) => state.analyticsReducer.get('typeOfUsersData', IMap());
const wauAnalyticsSelector = (state) => state.analyticsReducer.get('wauAnalytics', IMap());

export const selectWauAnalytics = createSelector(
  [wauAnalyticsSelector],
  (wauAnalytics) => wauAnalytics.toJS(),
);

export const selectTypeOfUsersData = createSelector(
  [typeOfUsersDataSelector],
  (typeOfUsersData) => typeOfUsersData.toJS(),
);

export const selectCurrentTrial = createSelector(
  [currentTrialSelector],
  (currentTrial) => currentTrial.toJS(),
);

export const selectCurrentSubscription = createSelector(
  [currentSubscriptionSelector],
  (currentSubscription) => currentSubscription.toJS(),
);

export const selectCourseMetrics = createSelector(
  [courseMetricsSelector],
  (courseMetrics) => courseMetrics.toJS(),
);

export const selectLessonMetrics = createSelector(
  [lessonMetricsSelector],
  (lessonMetrics) => lessonMetrics.toJS(),
);

export const selectQuizMetrics = createSelector(
  [quizMetricsSelector],
  (quizMetrics) => quizMetrics.toJS(),
);

export const selectSubscriptionsPerUser = createSelector(
  [subscriptionsPerUserSelector],
  (subscriptionsPerUser) => subscriptionsPerUser.toJS(),
);

export const selectTrialsPerUser = createSelector(
  [trialsPerUserSelector],
  (trialsPerUser) => trialsPerUser.toJS(),
);

export const selectAveragePaymentCalculationData = createSelector(
  [averagePaymentCalculationDataSelector],
  (averagePaymentCalculationData) => averagePaymentCalculationData.toJS(),
);

export const selectSubscriptionPaymentPerUserHasMoreData = createSelector(
  [subscriptionPaymentPerUserHasMoreData],
  (hasMoreData) => hasMoreData,
);
export const selectTrialsPaymentPerUserHasMoreData = createSelector(
  [trialsPaymentPerUserHasMoreData],
  (hasMoreData) => hasMoreData,
);

export const selectUserSubscriptionsOverview = createSelector(
  [userSubscriptionsOverviewSelector],
  (userSubscriptionsOverview) => userSubscriptionsOverview.toJS(),
);

export const selectAveragePaymentByUserData = createSelector(
  [averagePaymentByUserDataSelector],
  (averagePaymentByUserData) => averagePaymentByUserData.toJS(),
);

export const selectAveragePaymentByUserDataByDevice = createSelector(
  [averagePaymentByUserDataByDeviceSelector],
  (averagePaymentByUserDataByDevice) => averagePaymentByUserDataByDevice.toJS(),
);

export const selectMonthlyRevenueData = createSelector(
  [monthlyRevenueDataSelector],
  (monthlyRevenue) => monthlyRevenue.toJS(),
);

export const selectTrialMembershipsData = createSelector(
  [trialMembershipsDataSelector],
  (trialMemberships) => trialMemberships.toJS(),
);

export const selectSubscriptionMembershipsData = createSelector(
  [subscriptionMembershipsDataSelector],
  (subscriptionMemberships) => subscriptionMemberships.toJS(),
);

export const platformInstallationsSelector = createSelector(
  [selectPlatformInstallations],
  (platformInstallations) => platformInstallations.toJS(),
);

export const selectActiveUsersData = createSelector(
  [activeUsersSelector],
  (activeUsers) => activeUsers.toJS(),
);

export const selectTimeRangeFilter = createSelector(
  [timeRangeFilterSelector],
  (timeRangeFilter) => timeRangeFilter.toJS(),
);
