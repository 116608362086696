import { vimeoEditVideoFolderEndpoint, vimeoHeaderPost, vimeoUploadEndpoint } from 'utils/constants';
import axios from 'axios';

export default {
  uploadVideoOnVimeo: async (data) => {
    try {
      return await axios({
        method: 'post',
        url: vimeoUploadEndpoint,
        headers: vimeoHeaderPost,
        data,
      });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateVimeoVideoLocation: async (videoId) => {
    try {
      return await axios({
        method: 'put',
        url: `${vimeoEditVideoFolderEndpoint}/${videoId}`,
        headers: vimeoHeaderPost,
      });
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
