import React from 'react';
import PropTypes from 'prop-types';

import {
  Button, Tag, Modal,
} from 'antd';

const PreviewModal = ({
  visible, handleCancel, finalTitle, selectedWords,
}) => (
  <div className="dragAndDrop-preview-modal">
    <Modal
      visible={visible}
      title="Drag and drop question preview"
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Ok
        </Button>,
      ]}
    >
      <div className="preview-wrapper">
        <div className="preview-question-wrapper">

          {finalTitle?.map((item) => {
            if (item === 'BLANK') {
              return (
                <div className="preview-blank-word" />
              );
            }
            return (
              <p>{item}</p>
            );
          })}
        </div>
        <div className="preview-answers-wrapper">

          {selectedWords?.map((item) => (
            <Tag color="gold">{item.title}</Tag>
          ))}
        </div>

      </div>
    </Modal>
  </div>
);

PreviewModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  finalTitle: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedWords: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  handleCancel: PropTypes.func.isRequired,

};

export default PreviewModal;
