import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { Result } from 'antd';

import CheckCircle from 'components/CheckCircle';
import OnFinishResult from 'utils/OnFinishResult';
import { cloudinaryCloudName, cloudinaryUploadPreset } from 'utils/constants';
import { renameFile } from 'utils/helpers';
import CreateCourseForm from './CreateCourseForm';

const CreateCourse = (props) => {
  const {
    actions,
    currentStep,
    course,
    courseImageData,
    courseImageLandscapeData,
    isLoading,
    itemForEdit,
  } = props;
  const id = itemForEdit;
  const history = useHistory();
  const [courseValues, setCourseValues] = useState({});

  useEffect(() => {
    actions.setCurrentStep(0);

    if (id) {
      actions.getCourse(id);
    } else {
      actions.setCourse(null);
    }
  }, [id]);

  useEffect(() => {
    if (courseImageData.size > 0 && courseImageLandscapeData.size > 0) {
      if (course) {
        actions.updateCourse(
          id,
          courseValues,
          courseImageData.toJS(),
          courseImageLandscapeData.toJS(),
        );
      } else {
        actions.createCourse(courseValues, courseImageData.toJS(), courseImageLandscapeData.toJS());
      }
    }
  }, [courseImageData, courseImageLandscapeData]);

  const uploadImage = (image, action) => {
    const data = new FormData();
    data.append('file', image);
    data.append('upload_preset', cloudinaryUploadPreset);
    data.append('cloud_name', cloudinaryCloudName);
    action(data);
  };

  const createCourse = (values, image, imageLandscape) => {
    actions.setIsLoading(true);
    setCourseValues(values);
    const newImage = renameFile(image);
    const newLandscape = renameFile(imageLandscape);
    uploadImage(newLandscape, actions.postCourseImageLandscape);
    uploadImage(newImage, actions.postCourseImage);
  };

  const pushToHome = () => {
    history.push('/');
  };

  return currentStep === 0 ? (
    <CreateCourseForm
      onFinish={createCourse}
      cancel={pushToHome}
      course={course}
      isLoading={isLoading}
    />
  ) : (
    <Result
      status="success"
      extra={[
        <OnFinishResult />,
      ]}
      icon={<CheckCircle />}
    />
  );
};

CreateCourse.propTypes = {
  actions: PropTypes.shape({
    createCourse: PropTypes.func.isRequired,
    updateCourse: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    getCourse: PropTypes.func.isRequired,
    setCourse: PropTypes.func.isRequired,
    postCourseImage: PropTypes.func.isRequired,
    postCourseImageLandscape: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  currentStep: PropTypes.number.isRequired,
  course: ImmutablePropTypes.map,
  courseImageData: ImmutablePropTypes.map.isRequired,
  courseImageLandscapeData: ImmutablePropTypes.map.isRequired,
  isLoading: PropTypes.bool.isRequired,
  itemForEdit: PropTypes.string.isRequired,
};

CreateCourse.defaultProps = {
  course: null,
};

export default withTranslation()(CreateCourse);
