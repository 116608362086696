/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-mixed-operators */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PieChart, Pie, ResponsiveContainer, Legend, Cell, Tooltip,
} from 'recharts';
import CustomTooltipSingleValue from './CustomTooltipSingleValue';
import {
  analyticsColor,
  ageGroupLegend,
  timeRangeFilterComponents,
} from './options';
import { selectTypeOfUsersData } from '../modules/selectors';
import { getTypeOfUsers } from '../modules/actions';
import '../styles.scss';
import UserStats from './UserStats';
import TimeRangeFilter from './TimeRangeFilter';

const TypeOfUsers = ({
  typeOfUsers,
  getTypeOfUsersActions,
  timeRangeFilter,
}) => {
  const [ageBreakDownEmptyState, setAgeBreakDownEmptyState] = useState(false);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,
  }) => {
    if (percent < 0.1) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

    return (
      <text
        x={cx + radius * Math.cos(-midAngle * RADIAN)}
        y={cy + radius * Math.sin(-midAngle * RADIAN)}
        fill="#ffffff"
        textAnchor="middle"
        dominantBaseline="middle"
        className="pie-chart-inner-text"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };
  const parentsPercentage = (typeOfUsers.typeOfUsers?.parents
  / typeOfUsers.typeOfUsers?.usersCount * 100).toFixed(0);
  const addedChildrenPercentage = (typeOfUsers.typeOfUsers?.addedChild
        / typeOfUsers.typeOfUsers?.childrenCount * 100).toFixed(0);
  const enrolledChildPercentage = (typeOfUsers.typeOfUsers?.enrolledChild
      / typeOfUsers.typeOfUsers?.childrenCount * 100).toFixed(0);
  const activeChildPercentage = (typeOfUsers.typeOfUsers?.activeChild
      / typeOfUsers.typeOfUsers?.childrenCount * 100).toFixed(0);

  useEffect(() => {
    getTypeOfUsersActions({ dateFilter: timeRangeFilter });
  }, [timeRangeFilter]);

  useEffect(() => {
    if (typeOfUsers?.childAgeCount?.some((item) => item.value > 0)) {
      setAgeBreakDownEmptyState(false);
    } else {
      setAgeBreakDownEmptyState(true);
    }
  }, [typeOfUsers?.childAgeCount]);
  return (
    <div className="type-of-users">
      <div className="type-of-users-wrapper">
        <div className="analytics-header-wrapper">
          <span className="analytics-title">User Stats</span>
          <TimeRangeFilter
            timeRangeFilter={timeRangeFilterComponents.installs}
            filterKey={timeRangeFilter}
          />
        </div>
        <div className="user-stats">
          <UserStats label="Parents" value={typeOfUsers.typeOfUsers?.parents} percentage={parentsPercentage} color={analyticsColor.webColor} />
          <UserStats label="Added Children" value={typeOfUsers.typeOfUsers?.addedChild} percentage={addedChildrenPercentage} color={analyticsColor.androidColor} />
          <UserStats label="Enrolled Children" value={typeOfUsers.typeOfUsers?.enrolledChild} percentage={enrolledChildPercentage} color={analyticsColor.iosColor} />
          <UserStats label="Active Children" value={typeOfUsers.typeOfUsers?.activeChild} percentage={activeChildPercentage} color={analyticsColor.primaryBlue} />
        </div>
      </div>
      <div className="type-of-users-wrapper">
        <div className="analytics-header-wrapper">
          <span className="analytics-title">Children Age Breakdown</span>
          <TimeRangeFilter
            timeRangeFilter={timeRangeFilterComponents.installs}
            filterKey={timeRangeFilter}
          />
        </div>
        {ageBreakDownEmptyState ? <div className="empty-message-state-center"><span>There is no data for this time range</span></div> : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart width={500} height={500}>
              <Pie
                data={typeOfUsers?.childAgeCount}
                label={renderCustomizedLabel}
                labelLine={false}
              >
                <Cell fill={analyticsColor.webColor} />
                <Cell fill={analyticsColor.androidColor} />
                <Cell fill={analyticsColor.iosColor} />
              </Pie>
              <Tooltip content={<CustomTooltipSingleValue />} />
              <Legend payload={ageGroupLegend} />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

TypeOfUsers.propTypes = {
  typeOfUsers: ImmutablePropTypes.map.isRequired,
  getTypeOfUsersActions: PropTypes.func.isRequired,
  timeRangeFilter: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  typeOfUsers: selectTypeOfUsersData(state),
});

const mapDispatchToProps = (dispatch) => ({
  getTypeOfUsersActions: (params) => dispatch(getTypeOfUsers(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(TypeOfUsers);
