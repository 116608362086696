export const types = {
  SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
  LOAD_DATA: 'LOAD_DATA',
  SET_TABLE_DATA: 'SET_TABLE_DATA',
  SET_SORT_ORDER: 'SET_SORT_ORDER',
  SET_TITLE_FILTER: 'SET_TITLE_FILTER',
  SET_CURRENT_PAGE: 'SET_CURRENT_PAGE',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  GET_STUDENTS_DATA: 'GET_STUDENTS_DATA',
  SET_STUDENTS_DATA: 'SET_STUDENTS_DATA',
  SET_STUDENTS_TABLE_VISIBLE: 'SET_STUDENTS_TABLE_VISIBLE',
  SET_MONTH_FILTER: 'SET_MONTH_FILTER',
  SET_NUM_OF_ROWS: 'SET_NUM_OF_ROWS',
  SET_OFFSET: 'SET_OFFSET',
};

export const setActiveTab = (activeTab) => ({
  type: types.SET_ACTIVE_TAB,
  payload: { activeTab },
});

export const loadData = (
  activeTab,
  pagination,
  titleFilter,
  sorter,
  numOfRows,
  monthFilter,
  offset,
) => ({
  type: types.LOAD_DATA,
  payload: {
    activeTab, pagination, titleFilter, sorter, numOfRows, monthFilter, offset,
  },
});

export const setTableData = (data) => ({
  type: types.SET_TABLE_DATA,
  payload: { data },
});

export const setCurrentPage = (currentPage) => ({
  type: types.SET_CURRENT_PAGE,
  payload: { currentPage },
});

export const setTitleFilter = (titleFilter) => ({
  type: types.SET_TITLE_FILTER,
  payload: { titleFilter },
});

export const setNumOfRows = (numOfRows) => ({
  type: types.SET_NUM_OF_ROWS,
  payload: { numOfRows },
});

export const setOffset = (offset) => ({
  type: types.SET_OFFSET,
  payload: { offset },
});

export const setMonthFilter = (monthFilter) => ({
  type: types.SET_MONTH_FILTER,
  payload: { monthFilter },
});

export const setSortOrder = (sortOrder) => ({
  type: types.SET_SORT_ORDER,
  payload: { sortOrder },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const setTotalCount = (total) => ({
  type: types.SET_TOTAL_COUNT,
  payload: { total },
});
export const getStudentsData = (courseId, lessonId, quizId, activeTab, firstDay, lastDay) => ({
  type: types.GET_STUDENTS_DATA,
  payload: {
    courseId, lessonId, quizId, activeTab, firstDay, lastDay,
  },
});

export const setStudentsData = (studentsData) => ({
  type: types.SET_STUDENTS_DATA,
  payload: { studentsData },
});

export const setStudentsTableVisible = (studentsTable) => ({
  type: types.SET_STUDENTS_TABLE_VISIBLE,
  payload: { studentsTable },
});
