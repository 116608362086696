import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  isLoading: false,
  currentUser: {},
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    case types.SET_CURRENT_USER:
      return state.set('currentUser', fromJS({ ...action.payload.user }));
    default:
      return state;
  }
};
