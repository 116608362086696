import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { timeRangeFilterItems } from '../options';
import '../../styles.scss';

const View = ({ timeRangeFilter, actions, filterKey }) => {
  const [selectedItem, setSelectedItem] = useState(2);

  const onItemClick = (value, key) => () => {
    actions.setTimeRangeFilterSingleOption({ key: timeRangeFilter, value });
    setSelectedItem(key);
  };

  useEffect(() => {
    const correctItem = timeRangeFilterItems.find((item) => item.value === filterKey);
    setSelectedItem(correctItem?.key || 2);
  }, [filterKey]);

  const mappedItems = timeRangeFilterItems.map((item) => (
    <span
      className={classNames({
        'time-range-filter-item': true,
        'time-range-filter-item-active': selectedItem === item.key,
      })}
      key={item.key}
      onClick={onItemClick(item.value, item.key)}
    >
      {item.value}
    </span>
  ));

  return (
    <div>
      {mappedItems}
    </div>
  );
};

View.propTypes = {
  timeRangeFilter: PropTypes.string.isRequired,
  filterKey: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    setTimeRangeFilterSingleOption: PropTypes.func.isRequired,
  }).isRequired,
};
export default View;
