import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectPagination, selectShowEntries, selectOverviewTableData } from 'components/Tables/modules/selectors';
import View from './View';
import {
  getChildren,
  getChildById,
  setChildById,
} from './modules/actions';
import { selectChildren, selectChildById } from './modules/selectors';
import {
  setPaginationCurrent,
  setOffset,
  setPaginationTotal,
} from '../Tables/modules/actions';

const mapStateToProps = (state) => ({
  children: selectChildren(state),
  childProfile: selectChildById(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
  overviewTableData: selectOverviewTableData(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getChildren,
      getChildById,
      setChildById,
      setPaginationCurrent,
      setOffset,
      setPaginationTotal,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
