import React from 'react';
import PropTypes from 'prop-types';

const CustomTooltipWauAnalytics = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip-monthly-revenue">
        <span>{`Watched Lessons: ${payload[0].payload.watchedCount}`}</span>
        <span>{`Completed Quizzes: ${payload[0].payload.quizCompletedCount}`}</span>
        <span>{`Active Users: ${payload[0].payload.activeUsersCount}`}</span>
      </div>
    );
  }

  return null;
};

CustomTooltipWauAnalytics.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf.isRequired,
};

export default CustomTooltipWauAnalytics;
