import React, { useMemo } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import PaymentPerUserTable from './PaymentPerUserTable';
import TimeRangeFilter from './TimeRangeFilter';
import { timeRangeFilterComponents } from './options';

const PaymentPerUser = ({
  subscriptionPaymentPerUser,
  getSubscriptionPaymentPerUser, subscriptionPaymentPerUserHasMoreData,
  resetSubscriptionPaymentPerUserState,
  timeRangeFilter,
  userSubscriptionsOverview,
  getUserSubscriptionOverview,
  exportData,
  plan,
  title,
}) => {
  const scrollableElement = useMemo(
    () => document.getElementsByClassName(`scroll-${plan}`),
    [plan],
  );

  const loadMoreData = async () => {
    if (scrollableElement[0].scrollTop === 0) return;
    const limit = 5;
    const offset = subscriptionPaymentPerUser.length;

    getSubscriptionPaymentPerUser({
      noOfRows: limit, offset, dateFilter: timeRangeFilter, plan,
    });
  };

  const scrollToTop = () => {
    scrollableElement[0].scrollTop = 0;
  };

  const resetData = () => {
    scrollToTop();
    resetSubscriptionPaymentPerUserState();
    const limit = 10;
    const offset = 0;
    getSubscriptionPaymentPerUser({
      noOfRows: limit, offset, dateFilter: timeRangeFilter, plan,
    });
  };

  return (
    <div className="payment-per-user-wrapper">
      <div className="analytics-header-wrapper">
        <span className="analytics-title">{title}</span>
        <TimeRangeFilter
          timeRangeFilter={plan === 'TRIAL' ? timeRangeFilterComponents.trialsPerUsers : timeRangeFilterComponents.subscriptionPerUsers}
          filterKey={timeRangeFilter}
        />
      </div>
      <PaymentPerUserTable
        data={subscriptionPaymentPerUser}
        loadMoreData={loadMoreData}
        hasMoreData={subscriptionPaymentPerUserHasMoreData}
        userSubscriptionsOverview={userSubscriptionsOverview}
        getUserSubscriptionOverview={getUserSubscriptionOverview}
        exportData={exportData}
        plan={plan}
        timeRangeFilter={timeRangeFilter}
        resetData={resetData}
      />
    </div>
  );
};

PaymentPerUser.propTypes = {
  subscriptionPaymentPerUser: ImmutablePropTypes.list.isRequired,
  getSubscriptionPaymentPerUser: PropTypes.func.isRequired,
  resetSubscriptionPaymentPerUserState: PropTypes.func.isRequired,
  getUserSubscriptionOverview: PropTypes.func.isRequired,
  exportData: PropTypes.func.isRequired,
  subscriptionPaymentPerUserHasMoreData: PropTypes.bool.isRequired,
  timeRangeFilter: PropTypes.objectOf.isRequired,
  userSubscriptionsOverview: PropTypes.arrayOf.isRequired,
  plan: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default PaymentPerUser;
