export const types = {
  LOAD_STUDENTS_DATA: 'LOAD_STUDENTS_DATA',
  SET_STUDENTS_TABLE_DATA: 'SET_STUDENTS_TABLE_DATA',
  SET_STUDENTS_SORT_ORDER: 'SET_STUDENTS_SORT_ORDER',
  SET_NAME_FILTER: 'SET_NAME_FILTER',
  SET_STUDENTS_CURRENT_PAGE: 'SET_STUDENTS_CURRENT_PAGE',
  SET_STUDENTS_IS_LOADING: 'SET_STUDENTS_IS_LOADING',
  SET_STUDENTS_TOTAL_COUNT: 'SET_STUDENTS_TOTAL_COUNT',
};

export const loadData = (pagination, name, sorter, quiz = null, lesson = null) => ({
  type: types.LOAD_STUDENTS_DATA,
  payload: {
    pagination, name, sorter, quiz, lesson,
  },
});

export const setTableData = (data) => ({
  type: types.SET_STUDENTS_TABLE_DATA,
  payload: { data },
});

export const setCurrentPage = (currentPage) => ({
  type: types.SET_STUDENTS_CURRENT_PAGE,
  payload: { currentPage },
});

export const setNameFilter = (nameFilter) => ({
  type: types.SET_NAME_FILTER,
  payload: { nameFilter },
});

export const setSortOrder = (sortOrder) => ({
  type: types.SET_STUDENTS_SORT_ORDER,
  payload: { sortOrder },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_STUDENTS_IS_LOADING,
  payload: { isLoading },
});

export const setTotalCount = (total) => ({
  type: types.SET_STUDENTS_TOTAL_COUNT,
  payload: { total },
});
