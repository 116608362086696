import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCourseFilter } from 'components/ManageCourses/modules/selectors';
import View from './View';
import {
  selectCreatedLesson,
  selectCurrentStep,
  selectDisabledStartTimes, selectLesson, selectLessons, selectSkills, selectLessonSubmit,
} from './modules/selectors';
import { getCourseFilter } from '../ManageCourses/modules/actions';
import {
  getSkills,
  getLessons,
  createLesson, setCurrentStep, getLesson, setLesson, updateLesson, setCourses,
} from './modules/actions';
import {
  setQuizById,
} from '../CreateQuiz/modules/actions';
import {
  selectQuiz,
} from '../CreateQuiz/modules/selectors';

const mapStateToProps = (state) => ({
  skills: selectSkills(state),
  lessons: selectLessons(state),
  courses: selectCourseFilter(state),
  disabledStartTimes: selectDisabledStartTimes(state),
  currentStep: selectCurrentStep(state),
  createdLesson: selectCreatedLesson(state),
  lesson: selectLesson(state),
  quiz: selectQuiz(state),
  lessonSubmit: selectLessonSubmit(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getSkills,
      getLessons,
      getCourseFilter,
      createLesson,
      setCurrentStep,
      getLesson,
      setLesson,
      setCourses,
      updateLesson,
      setQuizById,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
