import { fromJS } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  isLoading: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.TOGGLE_SUCCESS_SCREEN:
      return state.set('successScreen', !state.get('successScreen', true));
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    default:
      return state;
  }
};
