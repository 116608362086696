import React, { useState } from 'react';
import classNames from 'classnames';
import Dashboard from 'components/Dashboard';
import AuthHeader from 'components/AuthHeader';
import CreateLesson from 'components/CreateLesson';
import CreateCourse from 'components/CreateCourse';
import CreateQuiz from 'components/CreateQuiz';
import Schedule from 'components/Schedule';
import ManageCourses from 'components/ManageCourses';
import ManageQuizzes from 'components/ManageQuizzes';
import NotFound from 'components/NotFound';
import ChangePassword from 'components/ChangePassword';
import Children from 'components/Children';
import Parents from 'components/Parents';
import ChildProfile from 'components/ChildProfile';
import CourseOverview from 'components/CourseOverview';
import QuizOverview from 'components/QuizOverview';
import LessonOverview from 'components/LessonOverview';
import ChildOverview from 'components/ChildOverview';
import AnalyticsDashboard from 'components/AnalyticsDashboard';
import {
  Switch, Route, Redirect, Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { Layout as AntdLayout, Menu } from 'antd';
import Footer from '../Footer';
import logoPng from '../Logo/logo.png';
import './layout.scss';
import { defaultMenuItems, marketingGuardMenuItems } from './components/menuItems';
import { getPageTitle } from './components/helpers';

const {
  Header, Content, Sider,
} = AntdLayout;

const Layout = ({ history, user }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { location } = history;
  const stateData = location?.state?.data;
  const pageTitle = getPageTitle(location.pathname.split('/')[1], stateData);

  const onCollapse = (isCollapsed) => {
    setCollapsed(isCollapsed);
  };

  const menu = (
    user.get('type') === 'MARKETING' ? (
      <Menu className="menu" selectedKeys={[history.location.pathname]}>
        {marketingGuardMenuItems.map((menuItem) => (
          <Menu.Item className="menu-item" key={menuItem.key}>
            <img src={menuItem.imgSrc} alt="dashboard icon" />
            <Link to={menuItem.key} />
            {menuItem.label}
          </Menu.Item>
        ))}
      </Menu>
    ) : (
      <Menu className="menu" selectedKeys={[history.location.pathname]}>
        {defaultMenuItems.map((menuItem) => (
          <Menu.Item className="menu-item" key={menuItem.key}>
            <img src={menuItem.imgSrc} alt="dashboard icon" />
            <Link to={menuItem.key} />
            {menuItem.label}
          </Menu.Item>
        ))}

      </Menu>
    )
  );

  const router = (
    user.get('type') === 'MARKETING' ? (
      <Switch location={history.location}>
        <Route path="/" exact render={() => (<Redirect to="/analytics-dashboard" />)} />
        <Route path="/analytics-dashboard" exact component={AnalyticsDashboard} />
      </Switch>
    ) : (
      <Switch location={history.location}>
        <Route path="/" exact render={() => (<Redirect to="/analytics-dashboard" />)} />
        <Route path="/dashboard" exact component={Dashboard} />
        <Route path="/children" exact component={Children} />
        <Route path="/parents" exact component={Parents} />
        <Route path="/create-course" exact component={CreateCourse} />
        <Route path="/edit-course/:id" exact component={CreateCourse} />
        <Route path="/course-overview" exact component={CourseOverview} />
        <Route path="/lesson-overview" exact component={LessonOverview} />
        <Route path="/quiz-overview" exact component={QuizOverview} />
        <Route path="/child-overview" exact component={ChildOverview} />
        <Route path="/create-lesson" exact component={CreateLesson} />
        <Route path="/create-quiz" exact render={() => <CreateQuiz />} />
        <Route path="/schedule" exact component={Schedule} />
        <Route path="/manage-courses" exact component={ManageCourses} />
        <Route path="/manage-quizzes" exact component={ManageQuizzes} />
        <Route path="/edit-lesson/:id" exact render={() => <CreateLesson editMode />} />
        <Route path="/edit-quiz/:id" exact render={() => <CreateQuiz editMode />} />
        <Route path="/settings" exact component={ChangePassword} />
        <Route path="/child-profile" exact component={ChildProfile} />
        <Route path="/analytics-dashboard" exact component={AnalyticsDashboard} />
        <Route component={NotFound} />
      </Switch>
    )
  );
  return (!localStorage.getItem('token')
    ? (<Redirect to="/login" />)
    : (
      <AntdLayout className="general-layout">
        <Sider className="side-menu" breakpoint="lg" collapsible trigger={null} collapsed={collapsed} onCollapse={onCollapse}>
          <div>
            <Link to="/">
              <img className="logo" src={logoPng} alt="Kutuby" />
            </Link>
          </div>
          {menu}
        </Sider>
        <AntdLayout>
          <Header className="header">
            <p className="layout-page-title">
              {pageTitle}
            </p>
            <AuthHeader />
          </Header>
          <Content className={classNames({
            content: true,
            'content--white': true,
            'content--gray': history.location.pathname === '/analytics-dashboard',
          })}

          >
            {router}
          </Content>
          <Footer />
        </AntdLayout>
      </AntdLayout>
    ));
};

Layout.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      state: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  user: PropTypes.objectOf.isRequired,
};

export default Layout;
