import React from 'react';
import PropTypes from 'prop-types';
import { Button, Upload } from 'antd';
import { PaperClipOutlined, DeleteFilled } from '@ant-design/icons';
import { nameLength } from 'utils/helpers';

const UploadThumbnail = ({ image, handleSetImage, courseImages }) => {
  const thumbnailAcceptCriteria = 'image/png, image/jpeg, image/jpg';
  const thumbnailHandler = (e) => {
    const { status } = e.file;
    if (status !== 'removed') {
      handleSetImage(e.file);
    }
  };
  const uploadOnRemove = () => {
    handleSetImage(null);
  };

  const beforeUpload = () => false;

  const contentText = courseImages ? nameLength(courseImages.name, 20) : 'Choose Thumbnail';

  return (
    image ? (
      <div className="upload-image-preview">
        <span>{nameLength(image?.name, 20)}</span>
        <DeleteFilled onClick={uploadOnRemove} />
      </div>
    ) : (
      <Upload
        beforeUpload={beforeUpload}
        className="upload-wrapper"
        listType="picture"
        onChange={thumbnailHandler}
        accept={thumbnailAcceptCriteria}
        showUploadList={false}
      >
        <Button className="upload-button" icon={<PaperClipOutlined />}>
          {contentText}
        </Button>
      </Upload>
    )
  );
};

UploadThumbnail.propTypes = {
  image: PropTypes.objectOf().isRequired,
  courseImages: PropTypes.objectOf().isRequired,
  handleSetImage: PropTypes.func.isRequired,
};

export default UploadThumbnail;
