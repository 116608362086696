import { all, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import userService from 'services/usersService';
import {
  types,
  setChildren,
  setParentsByEmail,
  setChildById,
} from './actions';

export function * getChildren() {
  const { data, error } = yield userService.getChildren();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setChildren(data));
  }
}
export function * getParentsByEmail(email) {
  const { data, error } = yield userService.getParentsByEmail(email.payload.email);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setParentsByEmail(data));
  }
}

export function * getChildProfileById(action) {
  const { data, error } = yield userService.getChildById(action.payload.id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setChildById(data));
  }
}

export default function * userSaga() {
  yield all([
    takeLatest(types.GET_ALL_CHILDREN, getChildren),
    takeLatest(types.GET_PARENT_BY_EMAIL, getParentsByEmail),
    takeLatest(types.GET_CHILD_BY_ID, getChildProfileById),
  ]);
}
