import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, Button, Checkbox,
} from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import Logo from '../Logo';
import Footer from '../Footer';
import './login.scss';

const Login = ({ actions, isLoading, t }) => {
  const [remember, setRemember] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const handleLogin = (values) => {
    actions.setIsLoading(true);
    const { email, password } = values;
    actions.login(email, password, remember);
  };

  const rememberChangeHandler = (e) => {
    setRemember(e.target.checked);
  };

  const handleEmailValue = (e) => {
    setEmailValue(e.target.value);
  };
  const handlePasswordValue = (e) => {
    setPasswordValue(e.target.value);
  };

  return (
    <div className="login-wrapper">
      <Logo />
      <div className="login-form-wrapper">
        <p className="login-title">Welcome To The Kutuby Dashboard</p>
        <p className="login-subtitle">Please login with your admin credentials</p>
        <Form
          className="login-form"
          onFinish={handleLogin}
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: t('email mandatory errorMessage') },
              { type: 'email', message: t('email invalid errorMessage') },
            ]}
          >
            <Input
              placeholder={t('email placeholder')}
              size="large"
              prefix={<MailOutlined className="input-icons" />}
              className="login-input"
              onChange={handleEmailValue}
            />
          </Form.Item>
          <Form.Item
            name="password"
            rules={[{ required: true, message: t('password mandatory errorMessage') }]}
          >
            <Input.Password
              autoComplete="off"
              placeholder={t('password placeholder')}
              size="large"
              prefix={<LockOutlined className="input-icons" />}
              className="login-input"
              onChange={handlePasswordValue}
            />
          </Form.Item>
          <div className="row">
            <Form.Item className="keep-me-logged-in" name="remember" valuePropName="checked">
              <Checkbox onChange={rememberChangeHandler}>{t('login rememberCheckbox text')}</Checkbox>
            </Form.Item>
          </div>
          <Form.Item>
            <div className="login-button-wrapper">
              <Button
                loading={isLoading}
                htmlType="submit"
                disabled={emailValue === '' || passwordValue === ''}
              >
                {t('button login text')}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </div>
      <Link to="/reset-password" className="forgot-password">{t('login resetPassword link')}</Link>
      <Footer />
    </div>
  );
};

Login.propTypes = {
  actions: PropTypes.shape({
    login: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(Login);
