import React from 'react';
import PropTypes from 'prop-types';

import {
  Select,
} from 'antd';

import './showNoOfEntries.scss';

const showNoOfEntries = ({ parentSetNumOfRows }) => {
  const { Option } = Select;
  const rowOptions = [5, 10, 20, 50, 100];

  return (
    <div>

      <Select
        size="small"
        className="select_num_of_record"
        onChange={((item) => {
          parentSetNumOfRows(item);
        })}
        defaultValue={rowOptions[0]}
      >
        {rowOptions.map((item) => (
          <Option key={item} value={item}>
            {item}
          </Option>
        ))}

      </Select>
    </div>

  );
};

showNoOfEntries.propTypes = {
  parentSetNumOfRows: PropTypes.func.isRequired,
};

export default showNoOfEntries;
