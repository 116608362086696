export const dateTimeFormat = 'YYYY-MM-DD HH:mmZZ';
export const shortDateTimeFormat = 'YYYY-MM-DD h:mZZ';
export const dateFormat = 'YYYY-MM-DD';
export const timeFormat = 'HH:mmZZ';
export const displayDateFormat = 'DD/MM/YYYY';
export const displayTimeFormat = 'hh:mm A';
export const displayDateTimeFormat = 'DD/MM/YYYY hh:mm A';
export const scheduledStatus = 'SCHEDULED';
export const inProgressStatus = 'IN_PROGRESS';
export const endedStatus = 'ENDED';
export const vimeoUploadEndpoint = 'https://api.vimeo.com/me/videos';
export const vimeoEditVideoFolderEndpoint = `https://api.vimeo.com/me/projects/${process.env.REACT_APP_VIMEO_STORAGE_FOLDER_ID}/videos`;
export const vimeoDeleteEndpoint = 'https://api.vimeo.com/videos';
export const vimeoAccessToken = process.env.REACT_APP_VIMEO_ACCESS_TOKEN;
export const vimeoHeaderPost = {
  Accept: 'application/vnd.vimeo.*+json;version=3.4',
  Authorization: `bearer ${vimeoAccessToken}`,
  'Content-Type': 'application/json',
};
export const fullstoryOrgId = process.env.REACT_APP_FULLSTORY_ORG_ID;
export const sentryDsn = process.env.REACT_APP_SENTRY_DSN;
export const sentryOrgSlug = process.env.REACT_APP_SENTRY_ORG_SLUG;
export const environment = process.env.NODE_ENV;

export const cloudinaryUploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
export const cloudinaryCloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
export const cloudinaryCloudUrl = process.env.REACT_APP_CLOUDINARY_CLOUD_URL;

export const singleAnswerQuestionType = 'SINGLE_ANSWER_QUESTION_TYPE';
export const multipleChoiceQuestionType = 'MULTIPLE_CHOICE_QUESTION_TYPE';
export const singleAnswerQuizType = 'SINGLE_ANSWER_QUIZ_TYPE';
export const multipleChoiceQuizType = 'MULTIPLE_CHOICE_QUIZ_TYPE';
export const dragAndDropQuizType = 'DRAG_AND_DROP_QUIZ_TYPE';
export const dragAndDropQuestionType = 'DRAG_AND_DROP_QUESTION_TYPE';
export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export const timeRangeOptions = {
  week: '1W', month: '1M', year: '1Y', all: 'ALL',
};
export const exportFileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const exportFileExtension = '.xlsx';
export const paymentPlanOptions = ['All', 'Premium', 'Trial', 'Expired', 'Basic', 'Code'];
