export const types = {
  GET_COURSE_BATCHES: 'GET_COURSE_BATCHES',
  SET_COURSE_BATCHES: 'SET_COURSE_BATCHES',
  GET_COURSE_FILTER: 'GET_COURSE_FILTER',
  SET_COURSE_FILTER: 'SET_COURSE_FILTER',
  DELETE_COURSE: 'DELETE_COURSE',
  COURSE_DELETED: 'COURSE_DELETED',
  SET_SELECTED_COURSE_FOR_FILTER: 'SET_SELECTED_COURSE_FOR_FILTER',
  GET_COURSE_OVERVIEW: 'GET_COURSE_OVERVIEW',
  SET_COURSE_OVERVIEW: 'SET_COURSE_OVERVIEW',
  SET_MAX_COURSE_WEEK: 'SET_MAX_COURSE_WEEK',
};

export const getCourseFilter = () => ({
  type: types.GET_COURSE_FILTER,
});

export const setCourseFilter = (coursesForFilter) => ({
  type: types.SET_COURSE_FILTER,
  payload: { coursesForFilter },
});

export const getCourseOverview = (id) => ({
  type: types.GET_COURSE_OVERVIEW,
  payload: { id },
});

export const setCourseOverview = (courseOverview) => ({
  type: types.SET_COURSE_OVERVIEW,
  payload: { courseOverview },
});

export const getCourseBatches = (courseId) => ({
  type: types.GET_COURSE_BATCHES,
  payload: { courseId },
});

export const setCourseBatches = (courseBatches) => ({
  type: types.SET_COURSE_BATCHES,
  payload: { courseBatches },
});

export const deleteCourse = (id, getCourseData) => ({
  type: types.DELETE_COURSE,
  payload: { id, getCourseData },
});

export const courseDeleted = (id) => ({
  type: types.COURSE_DELETED,
  payload: { id },
});

export const setMaxCourseWeek = (data) => ({
  type: types.SET_MAX_COURSE_WEEK,
  payload: { data },
});

export const setSelectedCourseForFilter = (selectedCourseForFilter) => ({
  type: types.SET_SELECTED_COURSE_FOR_FILTER,
  payload: { selectedCourseForFilter },
});
