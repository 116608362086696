/* eslint-disable no-case-declarations */
import { fromJS, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  isLoading: false,
  pagination: {
    current: 1,
    noOfRows: 20,
    total: 0,
    offset: 0,
  },
  showEntries: 20,
  overviewTableData: IMap(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    case types.SET_PAGINATION_TOTAL:
      return state.setIn(['pagination', 'total'], action.payload.total);
    case types.SET_PAGINATION_CURRENT:
      return state.setIn(['pagination', 'current'], action.payload.current);
    case types.SET_PAGINATION_NO_OF_ROWS:
      return state.setIn(['pagination', 'noOfRows'], action.payload.noOfRows);
    case types.SET_OFFSET:
      return state.setIn(['pagination', 'offset'], action.payload.offset);
    case types.SET_SHOW_ENTRIES:
      return state.set('showEntries', action.payload.showEntries);
    case types.RESET_OVERVIEW_TABLE_DATA:
      return state.set('overviewTableData', IMap());
    case types.SET_OVERVIEW_TABLE_DATA:
      const key = action.payload.pathname;
      const value = action.payload.overviewTableData;
      const index = state.getIn(['overviewTableData', key]);

      if (index === undefined) {
        const newItem = fromJS({ value });
        return state.setIn(['overviewTableData', key], newItem);
      }
      return state.setIn(['overviewTableData', key, 'value'], value);

    default:
      return state;
  }
};
