import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import View from './View';
import {
  createCourse, setCurrentStep, getCourse, setCourse, updateCourse, postCourseImage,
  postCourseImageLandscape, setIsLoading,
} from './modules/actions';
import {
  selectCreatedCourse, selectCurrentStep, selectCourse, selectCourseImage,
  selectIsLoading, selectCourseImageLandscape,
} from './modules/selectors';

const mapStateToProps = (state) => ({
  currentStep: selectCurrentStep(state),
  createdCourse: selectCreatedCourse(state),
  course: selectCourse(state),
  courseImageData: selectCourseImage(state),
  courseImageLandscapeData: selectCourseImageLandscape(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createCourse,
      setCurrentStep,
      getCourse,
      setCourse,
      updateCourse,
      postCourseImage,
      postCourseImageLandscape,
      setIsLoading,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
