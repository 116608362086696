import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Result,
} from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';

import CheckCircle from 'components/CheckCircle';
import './changePassword.scss';

const ChangePassword = ({
  actions, isLoading, t, isSuccess, history,
}) => {
  useEffect(() => {
    actions.setIsSuccess(false);
    return () => actions.setIsSuccess(false);
  }, []);

  const handleChangePassword = (values) => {
    actions.setIsLoading(true);
    const { oldPassword, newPassword } = values;
    actions.changePassword(oldPassword, newPassword);
  };

  const [oldPasswordValidateTrigger, setOldPasswordValidateTrigger] = useState('onBlur');
  const [newPasswordValidateTrigger, setNewPasswordValidateTrigger] = useState('onBlur');
  const [repeatNewPasswordValidateTrigger, setRepeatNewPasswordValidateTrigger] = useState('onBlur');

  return (
    !isSuccess
      ? (
        <div className="change-password-wrapper">
          <Row>
            <Col span={8}>
              <Form
                className="change-password-form"
                initialValues={{ remember: false }}
                onFinish={handleChangePassword}
                requiredMark={false}
              >
                <Form.Item
                  label={t('changePassword oldPassword label')}
                  labelCol={{ span: 24 }}
                  name="oldPassword"
                  rules={[
                    { required: true, message: t('changePassword oldPassword mandatory errorMessage') },
                    { min: 6, message: t('password minLength errorMessage') },
                  ]}
                  validateTrigger={oldPasswordValidateTrigger}
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder={t('changePassword oldPassword placeholder')}
                    size="medium"
                    prefix={<LockOutlined style={{ color: '#BFBFBF' }} />}
                    onBlur={() => setOldPasswordValidateTrigger('onChange')}
                  />
                </Form.Item>
                <Form.Item
                  label={t('changePassword newPassword label')}
                  labelCol={{ span: 24 }}
                  name="newPassword"
                  rules={[
                    { required: true, message: t('changePassword newPassword mandatory errorMessage') },
                    { min: 6, message: t('password minLength errorMessage') },
                  ]}
                  validateTrigger={newPasswordValidateTrigger}
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder={t('changePassword newPassword placeholder')}
                    size="medium"
                    prefix={<LockOutlined style={{ color: '#BFBFBF' }} />}
                    onBlur={() => setNewPasswordValidateTrigger('onChange')}
                  />
                </Form.Item>
                <Form.Item
                  label={t('changePassword repeatNewPassword label')}
                  labelCol={{ span: 24 }}
                  name="confirmNewPassword"
                  rules={[
                    { required: true, message: t('changePassword repeatNewPassword mandatory errorMessage') },
                    { min: 6, message: t('password minLength errorMessage') },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || !getFieldValue('newPassword') || getFieldValue('newPassword') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error(t('repeatPassword invalid errorMessage')));
                      },
                    }),
                  ]}
                  validateTrigger={repeatNewPasswordValidateTrigger}
                >
                  <Input.Password
                    autoComplete="off"
                    placeholder={t('changePassword repeatNewPassword placeholder')}
                    size="medium"
                    prefix={<LockOutlined style={{ color: '#BFBFBF' }} />}
                    onBlur={() => setRepeatNewPasswordValidateTrigger('onChange')}
                  />
                </Form.Item>
                <Form.Item>
                  <Button className="change-password-form-submit-btn" size="large" loading={isLoading} type="primary" htmlType="submit">
                    {t('button save text')}
                  </Button>
                </Form.Item>
              </Form>
            </Col>
          </Row>
        </div>
      ) : (
        <Result
          status="success"
          title={t('changePassword passwordChanged title')}
          extra={[
            <div key="info-wrapper">
              <p>{t('changePassword passwordChanged subtitle')}</p>
              <Button type="primary" onClick={() => history.push('/')}>
                {t('changePassword passwordChanged confirmButton text')}
              </Button>
            </div>,
          ]}
          icon={<CheckCircle />}
        />
      )
  );
};

ChangePassword.propTypes = {
  actions: PropTypes.shape({
    changePassword: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    setIsSuccess: PropTypes.func.isRequired,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  isSuccess: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withTranslation()(ChangePassword);
