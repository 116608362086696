import React from 'react';
import { Button } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import '../../../tables.scss';
import ReactExport from 'react-data-export';
import ExportIcon from '../../../../../images/exportIcon.png';

const { ExcelFile } = ReactExport;
const { ExcelSheet } = ReactExport.ExcelFile;
const { ExcelColumn } = ReactExport.ExcelFile;

const Export = ({ data, columns }) => (

  <ExcelFile element={(
    <Button className="export-button">
      <img className="export-icon" alt="export-icon" src={ExportIcon} />
      Export
    </Button>
)}
  >
    <ExcelSheet data={data} name="Parents">
      {columns?.map((item) => (<ExcelColumn label={item?.title} value={item?.key} />))}
    </ExcelSheet>
  </ExcelFile>
);

Export.propTypes = {
  data: ImmutablePropTypes.list.isRequired,
  columns: ImmutablePropTypes.list.isRequired,

};
export default Export;
