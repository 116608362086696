import React, { useState, useEffect, useMemo } from 'react';
import { withTranslation } from 'react-i18next';
import uuid from 'react-uuid';
import ImmutablePropTypes from 'react-immutable-proptypes';

import {
  Form,
  Input, Button,
  Select,
  Tag,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { List as IList } from 'immutable';
import {
  multipleChoiceQuizType,
  singleAnswerQuestionType,
  singleAnswerQuizType,
  multipleChoiceQuestionType,
  dragAndDropQuizType,
  dragAndDropQuestionType,
} from 'utils/constants';
import {
  getPopupContainer,
  quizCourseRules, quizTitleRules, quizTypeRules, quizWeekRules,
} from 'utils/inputRules';
import QuestionType from './QuestionType';

import './createQuizForm.scss';

const { Option } = Select;

const quizTypes = [
  {
    key: 0,
    name: 'Single answer quiz',
    value: singleAnswerQuizType,
    videoQuiz:true,
  },
  {
    key: 1,
    name: 'Multiple choice quiz',
    value: multipleChoiceQuizType,
    videoQuiz: true,

  },
  // this is disabled for now
  // {
  //   key: 2,
  //   name: 'Drag and drop quiz',
  //   value: dragAndDropQuizType,
  //   videoQuiz: false,
  // },
];

const getAnswerLetter = (index) => {
  const pairs = ['A', 'B', 'C', 'D', 'E'];
  return pairs[index];
};

const CreateQuizForm = (props) => {
  const {
    onFinish,
    t,
    isVideoQuiz,
    quiz,
    editQuiz,
    courses,
    isLoading,
    restartQuizData,
    editMode,
    quizDataOnSubmit,
    activeVideo,
  } = props;

  const [questions, setQuestions] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState({});
  const [selectedCourse, setSelectedCourse] = useState('');
  const [selectedCourseTotalWeek, setSelectedCourseTotalWeek] = useState(null);
  const [selectedQuizType, setSelectedQuizType] = useState(null);
  const [dragAndDropQuestionData, setDragAndDropQuestionData] = useState([]);
  const [multipleChoiceCorrectAnswersError, setMultipleChoiceCorrectAnswersError] = useState(false);
  const [loadComponent, setLoadComponent] = useState(false);

  const handleDragAndDropQuestionData = (item) => {
    setDragAndDropQuestionData((prev) => [...prev, item]);
  };

  const addQuestion = (type, isReset) => {
    const key = uuid();
    const getTheQuestionType = () => {
      switch (type) {
        case singleAnswerQuizType:
          return singleAnswerQuestionType;
        case multipleChoiceQuizType:
          return multipleChoiceQuestionType;
        default:
          return dragAndDropQuestionType;
      }
    };

    const questionType = getTheQuestionType();
    const answers = type === singleAnswerQuizType
      ? [{ key: uuid() }, { key: uuid() }, { key: uuid() }]
      : [{ key: uuid() }, { key: uuid() }, { key: uuid() }, { key: uuid() }, { key: uuid() }];

    let questionsData = [];
    if (isReset) {
      questionsData = [{
        key,
        questionType,
        answers,
      }];
    } else {
      questionsData = [
        ...questions,
        {
          key,
          questionType,
          answers,
        },
      ];
    }
    setQuestions(questionsData);

    setCorrectAnswers({ ...correctAnswers, [key]: type === singleAnswerQuizType ? 'A' : [] });
  };

  const removeQuestion = (key) => () => {
    setQuestions(questions.filter((question) => question.key !== key));
    setCorrectAnswers({ ...correctAnswers, [key]: undefined });
  };

  const [form] = Form.useForm();

  const resetFormFields = () => {
    form.resetFields();
    setSelectedCourse('');
    setSelectedCourseTotalWeek(null);
    setSelectedQuizType(null);
  };

  const createQuiz = (values) => {
    const questionsJSON = questions.map((question) => {
      const questionJSON = {
        title: values[`q${question.key}`],
        questionType: question.questionType,
        answers: Object.entries(values)
          .filter(
            ([key]) => key.endsWith(`q${question.key}`) && key.startsWith('a'),
          )
          .sort(([keyA], [keyB]) => keyA > keyB)
          .map(([, value], index) => ({
            value,
            correct: selectedQuizType === multipleChoiceQuizType
              ? correctAnswers[question.key].some((x) => x === getAnswerLetter(index))
              : correctAnswers[question.key] === getAnswerLetter(index),
          })),
      };

      delete questionJSON.key;
      return questionJSON;
    });
    const quizJSON = {
      title: values?.title,
      questions: questionsJSON,
      courseId: values?.course,
      week: values?.week,
      quizType: selectedQuizType,
    };

    if (editQuiz && editMode) {
      quizJSON.id = editQuiz.get('id', null);
    }
    onFinish(quizJSON, activeVideo);
  };

  const createDragAndDropQuiz = (values) => {
    const questionsData = dragAndDropQuestionData.map((item) => {
      const questionsItem = {
        title: item.title.join(' '),
        questionType: dragAndDropQuestionType,
        answers: item.answers,
      };

      return questionsItem;
    });

    const quizJSON = {
      title: values.title,
      questions: questionsData,
      courseId: values.course,
      week: values.week,
      quizType: selectedQuizType,
    };

    if (editQuiz) {
      quizJSON.id = editQuiz.get('id', null);
    }

    onFinish(quizJSON);
  };

  const updateCorrect = (e, question) => {
    setCorrectAnswers({
      ...correctAnswers,
      [question.key]: selectedQuizType === singleAnswerQuizType
        ? e.target.value : e,
    });
  };
  const onChangeCourse = (_value, option) => {
    setSelectedCourse(option.children);
    setSelectedCourseTotalWeek(option.totalWeeks);
  };
  const onChangeQuizType = (_value, option) => {
    setSelectedQuizType(option.value);
    addQuestion(option.value, true);
  };
  const removeCourse = () => {
    form.setFieldsValue({
      course: null,
    });
    setSelectedCourse('');
    setSelectedCourseTotalWeek(null);
  };

  useEffect(() => {
    if (selectedQuizType === multipleChoiceQuizType) {
      Object.keys(correctAnswers).map((itemKey) => {
        if (correctAnswers[itemKey]) {
          setMultipleChoiceCorrectAnswersError(correctAnswers[itemKey].length < 2);
        }
        return itemKey;
      });
    }
  }, [correctAnswers]);

  useEffect(() => {
    if (quiz) {
      form.setFieldsValue({
        title: quiz.title,
        week: quiz.week,
      });
      const currentQuestions = [...quiz.questions];
      const newQuestions = [];
      currentQuestions.forEach((question) => {
        question.key = question.id || uuid();
        newQuestions.push({ ...question });
      });
      setQuestions(newQuestions);
      let ca = {};
      newQuestions.forEach((question) => {
        form.setFieldsValue({
          [`q${question.key}`]: question.title,
        });
        question.answers.forEach((answer, index) => {
          form.setFieldsValue({
            [`a${index}q${question.key}`]: answer.value,
          });
          if (answer.correct) {
            const correctAnswer = getAnswerLetter(index);
            ca = { ...ca, [question.key]: correctAnswer };
          }
        });
      });
      setCorrectAnswers({ ...ca });
    }
  }, [quiz]);
  const editQuizDependencyArray = isVideoQuiz ? [] : [editQuiz];

  useEffect(() => {
    if (!editMode && !isVideoQuiz) {
      restartQuizData();
    }
    if (editQuiz) {
      form.setFieldsValue({
        title: editQuiz.get('title', null),
        week: editQuiz.get('week', null),
        course: editQuiz.getIn(['courseId'], null),
        quizType: editQuiz.getIn(['quizType'], null),
      });
      setSelectedQuizType(editQuiz.getIn(['quizType'], null));
      setSelectedCourseTotalWeek(editQuiz.get('week', null));
      const tempQuestions = editQuiz.get('questions');
      if (tempQuestions) {
        const currentQuestions = tempQuestions.toJS();
        const newQuestions = [];
        currentQuestions.forEach((question) => {
          question.key = question.id || uuid();
          newQuestions.push({ ...question });
        });
        setQuestions(newQuestions);
        let ca = {};
        newQuestions.forEach((question) => {
          form.setFieldsValue({
            [`q${question.key}`]: question.title,
          });
          const correctAnswersArray = [];
          question.answers.forEach((answer, index) => {
            form.setFieldsValue({
              [`a${index}q${question.key}`]: answer.value,
            });
            if (question.questionType === singleAnswerQuestionType && answer.correct) {
              const correctAnswer = getAnswerLetter(index);
              ca = { ...ca, [question.key]: correctAnswer };
            } else if (question.questionType === multipleChoiceQuestionType && answer.correct) {
              correctAnswersArray.push(getAnswerLetter(index));
              ca = { ...ca, [question.key]: correctAnswersArray };
            }
          });
        });
        setCorrectAnswers({ ...ca });
      }
    } else {
      form.resetFields();
      setSelectedCourse(null);
      setSelectedCourseTotalWeek(null);
    }
  }, editQuizDependencyArray);

  const dragAndDropButtonDisable = dragAndDropQuestionData.length !== questions.length
    && selectedQuizType === dragAndDropQuizType;
  const multipleChoiceButtonDisable = selectedQuizType === multipleChoiceQuizType
    && multipleChoiceCorrectAnswersError;

  const publishButtonDisable = [
    dragAndDropButtonDisable, multipleChoiceButtonDisable,
  ].some(Boolean);

  const quizTypeFilter = isVideoQuiz ? quizTypes.filter((e) => e.videoQuiz) : quizTypes;
  const quizTypeMapping = useMemo(() => (
    quizTypeFilter.map((quizType) => (
      <Option key={quizType.key} value={quizType.value}>
        {quizType.name}
      </Option>
    ))), [quizTypeFilter]);

  const courseMapping = useMemo(() => courses?.map((course) => (
    <Option key={course.key} value={course.value} totalWeeks={course.totalWeeks}>
      {course.name}
    </Option>
  )), [courses]);

  const questionMapping = useMemo(() => selectedQuizType && questions.map((question, i) => (
    <QuestionType
      question={question}
      i={i}
      handleDragAndDropQuestionData={handleDragAndDropQuestionData}
      removeQuestion={removeQuestion}
      updateCorrect={updateCorrect}
      isVideoQuiz={isVideoQuiz}
      correctAnswers={correctAnswers}
      getAnswerLetter={getAnswerLetter}
      questions={questions}
      t={t}
    />
  )), [correctAnswers, isVideoQuiz, questions, removeQuestion, selectedQuizType, t, updateCorrect]);

  const addQuestionOnClick = () => {
    addQuestion(selectedQuizType, false);
  };

  useEffect(() => {
    if (isVideoQuiz && loadComponent) {
      createQuiz(form.getFieldsValue());
    }
    setLoadComponent(true);
  }, [quizDataOnSubmit]);

  return (
    <Form form={form} className="create-quiz-form" onFinish={dragAndDropQuestionData.length > 0 ? createDragAndDropQuiz : createQuiz}>
      <div className="create-quiz-form-fields-wrapper">
        <span className="section-labels">BASIC INFORMATION</span>
        <div className="create-quiz-form-divider">{ }</div>
        <div className="create-quiz-basic-info-row">
          <div className="create-quiz-input-wrapper">
            <span className="plain-text">{t('createQuiz titleField title')}</span>
            <Form.Item
              name="title"
              rules={quizTitleRules}
              className="title-field"
            >
              <Input
                placeholder={t('createQuiz titleField placeholder')}
                maxLength={30}
              />
            </Form.Item>
          </div>
          <div className="create-quiz-input-wrapper">

            {!isVideoQuiz && (

              <>
                <span className="plain-text">{t('createQuiz course label')}</span>
                <Form.Item
                  name="course"
                  rules={quizCourseRules}
                >
                  <Select
                    placeholder={t('createLesson basicInfo course placeholder')}
                    size="medium"
                    onChange={onChangeCourse}
                    getPopupContainer={getPopupContainer}
                    disabled={editQuiz?.size}
                  >
                    {courseMapping}
                  </Select>
                </Form.Item>

              </>
            )}

            <div className="skill-tags-wrapper">
              <Tag
                color="#FEB983"
                closable
                visible={selectedCourse}
                onClose={removeCourse}
              >
                {selectedCourse}
              </Tag>
            </div>
          </div>

        </div>
        <div className="create-quiz-basic-info-row">
          <div className="create-quiz-input-wrapper">

            <span className="plain-text">{t('createQuiz quizType title')}</span>
            <Form.Item
              name="quizType"
              rules={quizTypeRules}
            >
              <Select
                placeholder={t('createQuiz quizType placeholder')}
                size="medium"
                onChange={onChangeQuizType}
                getPopupContainer={getPopupContainer}
              >
                {quizTypeMapping}
              </Select>
            </Form.Item>
          </div>
          {!isVideoQuiz && (
            <div className="create-quiz-input-wrapper">

              <span className="plain-text">{t('createQuiz week label')}</span>
              <Form.Item
                name="week"
                rules={quizWeekRules(selectedCourseTotalWeek)}
                className="title-field"
              >
                <Input disabled={!selectedCourseTotalWeek} type="number" className="create-lesson-form-week" placeholder={t('createLesson contentAndChapters week placeholder')} size="medium" />
              </Form.Item>
            </div>
          )}
        </div>
        {selectedQuizType && (
          <>
            <span className="section-labels">QUIZ CONTENT</span>
            <div className="create-quiz-form-divider" />
          </>
        )}

        {questionMapping}

        {selectedQuizType && (
          <Button
            className="add-new-question"
            size="large"
            type="primary"
            htmlType="button"
            onClick={addQuestionOnClick}
          >
            <PlusOutlined />
            {t('button addQuestion text')}
          </Button>
        )}
      </div>
      <div className="create-quiz-button-row">
        {dragAndDropQuestionData.length > 0
          && <p>Please approve all questions to submit the quiz</p>}
        {!isVideoQuiz && (
        <div className="create-quiz-form-action-buttons">
          <Button
            size="large"
            type="secondary"
            htmlType="button"
            onClick={resetFormFields}
            className="clear-all-items-quiz"
          >
            {t('button clearAllInputs text')}
          </Button>
          <Button
            loading={isLoading}
            size="large"
            type="primary"
            htmlType="submit"
            disabled={publishButtonDisable}
            className="add-new-quiz"
          >
            {isVideoQuiz ? t('button save text') : t('button publish text')}
          </Button>
        </div>
        )}
      </div>
    </Form>
  );
};

CreateQuizForm.propTypes = {
  courses: ImmutablePropTypes.list,
  editMode: PropTypes.bool.isRequired,
  restartQuizData: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isVideoQuiz: PropTypes.bool,
  quiz: PropTypes.shape({
    title: PropTypes.string.isRequired,
    week: PropTypes.number,
    questions: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        answers: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            correct: PropTypes.bool.isRequired,
          }),
        ),
      }),
    ),
  }),
  editQuiz: ImmutablePropTypes.map,
  isLoading: PropTypes.bool.isRequired,
  quizDataOnSubmit: PropTypes.bool.isRequired,
  activeVideo: ImmutablePropTypes.map.isRequired,
};

CreateQuizForm.defaultProps = {
  courses: IList(),
  isVideoQuiz: false,
  quiz: null,
  editQuiz: null,
};

export default withTranslation()(CreateQuizForm);
