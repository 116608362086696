import React from 'react';
import PropTypes from 'prop-types';
import { monthAbbreviationsToFullName } from './options';

const CustomTooltipSingleValue = ({ active, payload }) => {
  if (active && payload && payload.length) {
    const rightName = monthAbbreviationsToFullName[payload[0].payload.name]
      ? monthAbbreviationsToFullName[payload[0].payload.name] : payload[0].payload.name;
    return (
      <div className="custom-tooltip">
        <span className="label">{`${rightName}: ${typeof payload[0].value === 'number' ? payload[0].value.toFixed(2) : payload[0].value}`}</span>
      </div>
    );
  }

  return null;
};

CustomTooltipSingleValue.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.arrayOf.isRequired,
};

export default CustomTooltipSingleValue;
