/* eslint-disable no-lonely-if */
import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import coursesService from 'services/coursesService';
import {
  types, setTableData, setIsLoading, setTotalCount, setStudentsData,
} from './actions';

function * loadData(action) {
  yield put(setIsLoading(true));
  const {
    activeTab, pagination, titleFilter, sorter, monthFilter, numOfRows, offset,
  } = action.payload;

  const dataType = activeTab === '1' ? 'lesson' : 'quiz';
  const sortOrder = sorter === 'descend' ? 'DESC' : 'ASC';

  const dataToService = {
    type: dataType || undefined,
    pagination: pagination || undefined,
    title: titleFilter || '',
    sortOrder: sortOrder || 'undefined',
    month: monthFilter || '',
    numOfRows,
    offset,
  };

  const dataFromService = dataType === 'lesson' ? yield coursesService.getLessonsForDashboard(dataToService) : yield coursesService.getQuizzesForDashboard(dataToService);

  const { data, error } = dataFromService;

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setTableData(data.data)),
      put(setTotalCount(data.count)),
    ]);
  }
  yield put(setIsLoading(false));
}

// This saga function will provide data about students by activeTab property
export function * getStudentsData(action) {
  const { data, error } = yield coursesService
    .getAttendeesForAdminDashboard(
      action.payload.activeTab === '1' ? 'lessons' : 'quizzes',
      action.payload.courseId,
      action.payload.activeTab === '1' ? action.payload.lessonId : action.payload.quizId,
      action.payload.firstDay,
      action.payload.lastDay,
    );

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setStudentsData(data.data));
  }
}

export default function * dashboardSaga() {
  yield all([
    takeLatest(types.LOAD_DATA, loadData),
    takeLatest(types.GET_STUDENTS_DATA, getStudentsData),
  ]);
}
