export const types = {
  GET_ALL_PARENTS: 'GET_ALL_PARENTS',
  SET_ALL_PARENTS: 'SET_ALL_PARENTS',
  CREATE_COUPON_CODE: 'CREATE_COUPON_CODE',
  SET_COUPON_DATA: 'SET_COUPON_DATA',
};

export const getParents = (pagination) => ({
  type: types.GET_ALL_PARENTS,
  payload: { pagination },
});

export const setParents = (parents) => ({
  type: types.SET_ALL_PARENTS,
  payload: { parents },
});

export const createCouponCode = (data) => ({
  type: types.CREATE_COUPON_CODE,
  payload: { data },
});

export const setCouponData = (data) => ({
  type: types.SET_COUPON_DATA,
  payload: { data },
});
