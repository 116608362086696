import React from 'react';
import classNames from 'classnames';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import trashIcon from '../../../images/icons_trash-can.svg';
import editIcon from '../../../images/icons_edit.svg';

import '../manageCourses.scss';

// eslint-disable-next-line import/prefer-default-export
export const getColumns = (editCourse, cautionModalVisibleOn, user) => {
  const renderStatus = (item) => (
    <div className={classNames({
      'status-wrapper': true,
      'status-wrapper-active': item.status === 'Active',
      'status-wrapper-inactive': item.status === 'Inactive',
    })}
    >
      <p>{item?.status}</p>
    </div>
  );

  const renderActionButtons = (item) => {
    const actionEdit = (e) => {
      e.stopPropagation();
      editCourse(item);
    };
    const actionDelete = (e) => {
      e.stopPropagation();
      cautionModalVisibleOn(item.id);
    };
    return (
      <>
        <Button
          className="edit-item-icon"
          size="small"
          icon={<img src={editIcon} alt="edit icon" />}
          onClick={actionEdit}
        />
        {user.get('type') !== 'TEACHER' && (
        <Button
          className="delete-item-icon"
          size="small"
          icon={<img src={trashIcon} alt="delete icon" />}
          onClick={actionDelete}
        />
        )}
      </>
    );
  };

  const premiumRender = (item) => (
    <div>
      {item.premium && <CheckOutlined className="premium-icon" />}
    </div>
  );

  return ([
    {
      title: 'Course',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Students',
      dataIndex: 'students',
      key: 'students',
    },
    {
      title: 'Premium',
      key: 'premium',
      render: premiumRender,
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus,
    },
    {
      key: 'id',
      render: renderActionButtons,
    },
  ]);
};
