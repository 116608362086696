import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { setPaginationTotal } from 'components/Tables/modules/actions';
import { selectOverviewTableData } from 'components/Tables/modules/selectors';
import { selectCourseOverview } from 'components/ManageCourses/modules/selectors';
import { getCourseOverview } from 'components/ManageCourses/modules/actions';
import View from './View';

const mapStateToProps = (state) => ({
  overviewTableData: selectOverviewTableData(state),
  courseOverview: selectCourseOverview(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      setPaginationTotal,
      getCourseOverview,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
