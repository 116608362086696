import React, { useEffect } from 'react';
import {
  Button, Result,
} from 'antd';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import moment from 'moment';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { useHistory } from 'react-router-dom';

import CheckCircle from 'components/CheckCircle';
import { dateTimeFormat } from 'utils/constants';
import CreateQuizForm from './CreateQuizForm';

const CreateQuiz = (props) => {
  const {
    actions,
    currentStep,
    t,
    courses,
    quiz,
    isLoading,
    editMode,
    itemForEdit,
  } = props;
  const id = itemForEdit;
  const history = useHistory();

  const courseOptions = courses.map((item) => ({
    value: item.id,
    name: item.title,
    key: item.id,
    totalWeeks: item.totalWeeks,
  }));

  useEffect(() => {
    actions.getCourseFilter();
    actions.setCurrentStep(0);

    if (id) {
      actions.getQuizById(id);
    } else {
      actions.setQuizById(null);
    }

    return () => actions.setCurrentStep(0);
  }, [id]);

  const createQuiz = (values) => {
    actions.setIsLoading(true);
    const { expiresAt, ...rest } = values;
    const createdQuiz = { ...rest };
    if (expiresAt) {
      createdQuiz.expiresAt = `${moment(expiresAt).format(dateTimeFormat)}`;
    }
    if (!quiz || quiz.size === 0) {
      actions.createQuiz(createdQuiz);
    } else {
      actions.updateQuiz(createdQuiz.id, createdQuiz);
    }
  };

  return (
    currentStep === 0 ? (
      <CreateQuizForm
        onFinish={createQuiz}
        cancel={() => history.push('/')}
        courses={courseOptions}
        editQuiz={quiz}
        isLoading={isLoading}
        restartQuizData={actions.restartQuizData}
        editMode={editMode}
      />
    ) : (
      <Result
        status="success"
        title={t('createQuiz quizCreated title')}
        extra={[
          <div key="info-wrapper" className="stream-lesson-info-wrapper">
            <p className="stream-lesson-subtitle">{t('createQuiz quizCreated subtitle')}</p>
            <Button type="primary" onClick={() => history.push('/')}>
              {t('createQuiz quizCreated confirmButton text')}
            </Button>
          </div>,
        ]}
        icon={<CheckCircle />}
      />
    )
  );
};

CreateQuiz.propTypes = {
  t: PropTypes.func.isRequired,
  editMode: PropTypes.bool,
  actions: PropTypes.shape({
    createQuiz: PropTypes.func.isRequired,
    updateQuiz: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    getCourseFilter: PropTypes.func.isRequired,
    getQuizById: PropTypes.func.isRequired,
    setQuizById: PropTypes.func.isRequired,
    setIsLoading: PropTypes.func.isRequired,
    restartQuizData: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  currentStep: PropTypes.number.isRequired,
  isLoading: PropTypes.bool.isRequired,
  courses: ImmutablePropTypes.list.isRequired,
  quiz: ImmutablePropTypes.map,
  itemForEdit: PropTypes.string.isRequired,
};

CreateQuiz.defaultProps = {
  quiz: null,
  editMode: false,
};

export default withTranslation()(CreateQuiz);
