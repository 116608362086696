import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import activitiesService from 'services/activitiesService';
import studentsService from 'services/studentsService';
import {
  types, setTableData, setIsLoading, setTotalCount,
} from './actions';

function * loadData(action) {
  yield put(setIsLoading(true));
  const {
    quiz, lesson, sorter, pagination, name,
  } = action.payload;
  const sortOrder = sorter === 'descend' ? 'desc' : 'asc';
  const { data, error } = quiz || lesson ? (
    yield activitiesService.getAttendees({
      id: quiz || lesson,
      type: quiz ? 'quiz' : 'lesson',
      sortBy: `${sortOrder}(name)` || undefined,
      page: pagination.current || undefined,
      name: name || undefined,
    })
  ) : (
    yield studentsService.getStudents({
      sortBy: `${sortOrder}(name)` || undefined,
      page: pagination.current || undefined,
      name: name || undefined,
    })
  );
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setTableData(data.rows)),
      put(setTotalCount(data.count)),
    ]);
  }
  yield put(setIsLoading(false));
}

export default function * studentsSaga() {
  yield all([
    takeLatest(types.LOAD_STUDENTS_DATA, loadData),
  ]);
}
