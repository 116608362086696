import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch, withRouter } from 'react-router-dom';
import SuccessScreen from 'components/ResetPassword/components/SuccessScreen';
import Layout from './components/Layout';
import Login from './components/Login';
import ResetPassword from './components/ResetPassword';

const AppContainer = ({ history }) => (
  <div className="app-wrapper">
    <Switch location={history.location}>
      <Route path="/login" exact component={Login} />
      <Route path="/reset-password" exact component={ResetPassword} />
      <Route path="/reset-password/success" exact component={SuccessScreen} />
      <Route path="/" component={Layout} />
    </Switch>
  </div>
);

AppContainer.propTypes = {
  history: PropTypes.shape({
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default withRouter(AppContainer);
