import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { fromJS } from 'immutable';
import ImmutablePropTypes from 'react-immutable-proptypes';
import {
  Button, Progress, Row, Col,
} from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import CreateQuizForm from 'components/CreateQuiz/CreateQuizForm';
import { nameLength } from 'utils/helpers';
import trashIcon from '../../../images/icons_trash-can.svg';
import '../createLesson.scss';

const VideoItem = ({
  video,
  currentVideoUploading,
  editMode,
  t,
  quizDataOnSubmit,
  removeVideo,
  setQuiz,
  setVideosHandler,
  videos,
  setDeletedQuizzes,
  setUpdatedQuizzes,
}) => {
  const [quizVisible, setQuizVisible] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);

  const handleVisibleOn = () => {
    setQuizVisible(true);
  };
  const handleVisibleOff = () => {
    setQuizVisible(false);
  };
  const onCancelHandler = () => {
    removeVideo(video);
  };
  const addQuiz = () => {
    setActiveVideo(video);

    if (video?.quizzes?.length > 0) {
      setQuiz(video?.quizzes[0]);
      setUpdatedQuizzes((prev) => [...prev, video?.quizzes[0].id]);
    } else if (!editMode) {
      setQuiz(null);
    }

    handleVisibleOn();
  };

  const saveQuiz = (values) => {
    const prevVideos = [...videos];
    prevVideos[prevVideos.indexOf(activeVideo)].quizzes = [values];
    setVideosHandler(prevVideos);
    setQuiz(null);
  };

  const deleteQuiz = () => {
    const prevVideos = [...videos];
    const removedQuiz = prevVideos[prevVideos.indexOf(activeVideo)].quizzes[0];
    prevVideos[prevVideos.indexOf(activeVideo)].quizzes = null;
    setVideosHandler(videos);
    handleVisibleOff();
    if (removedQuiz.id) {
      setDeletedQuizzes((prev) => [...prev, removedQuiz.id]);
    }
  };

  const progressPercentage = currentVideoUploading && video?.url === currentVideoUploading.url
    ? currentVideoUploading.uploadPercentage : 100;

  const videoButtonText = editMode ? 'Delete' : 'Cancel';

  return (
    <div className="videos-container" key={video?.url}>
      <Row className="quiz-name-buttons">
        <Col>
          <CloudUploadOutlined className="upload-row-icon" />
        </Col>

        <Col className="upload-row-text">
          {nameLength(video?.videoName, 28)}
        </Col>

        <Col className="add-quiz-button-col">
          <Button
            className="add-quiz-row-button"
            type="secondary"
            size="medium"
            htmlType="button"
            onClick={addQuiz}
          >
            {video?.quizzes?.length > 0
              ? t('createLesson editQuiz text')
              : t('createLesson addQuiz text')}
          </Button>
        </Col>

        <Col className="cancel-button">
          <Button
            className="add-quiz-row-button"
            size="medium"
            type="secondary"
            htmlType="button"
            onClick={onCancelHandler}
          >
            {videoButtonText}
          </Button>
        </Col>
      </Row>

      <Row>
        <Progress percent={progressPercentage} strokeColor="#64a7b5" />
      </Row>
      {quizVisible && (
        <div className="create-quiz-modal-wrapper">
          <div className="create-quiz-modal-header-wrapper">
            <h4 className="header-title">
              {t('createLesson createQuizModal title')}
            </h4>
            <div onClick={deleteQuiz}>
              <img src={trashIcon} alt="remove-answer-icon" />
            </div>
          </div>
          <CreateQuizForm
            isVideoQuiz
            onCancel={handleVisibleOff}
            onFinish={saveQuiz}
            editQuiz={fromJS(video?.quizzes?.[0])}
            isLoading={false}
            editMode={editMode}
            quizDataOnSubmit={quizDataOnSubmit}
            activeVideo={activeVideo}
          />
        </div>
      )}
    </div>
  );
};

VideoItem.propTypes = {
  t: PropTypes.func.isRequired,
  removeVideo: PropTypes.func.isRequired,
  currentVideoUploading: ImmutablePropTypes.map.isRequired,
  video: ImmutablePropTypes.list.isRequired,
  videos: ImmutablePropTypes.list.isRequired,
  editMode: PropTypes.bool.isRequired,
  quizDataOnSubmit: PropTypes.bool.isRequired,
  setQuiz: PropTypes.func.isRequired,
  setVideosHandler: PropTypes.func.isRequired,
  setDeletedQuizzes: PropTypes.func.isRequired,
  setUpdatedQuizzes: PropTypes.func.isRequired,
};

export default VideoItem;
