import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {
  ComposedChart, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, Line,
} from 'recharts';
import {
  activeUsersLegend, analyticsColor, axisOptions, timeRangeFilterComponents,
} from './options';
import TimeRangeFilter from './TimeRangeFilter';
import CustomTooltipMultipleValues from './CustomTooltipMultipleValues';
import '../styles.scss';

const ActiveUsers = ({
  getActiveUsers,
  activeUsers,
  timeRangeFilter,
}) => {
  useEffect(() => {
    getActiveUsers({ dateFilter: timeRangeFilter });
  }, [timeRangeFilter]);

  return (
    <div className="active-users-wrapper">
      <div className="analytics-header-wrapper">
        <span className="analytics-title">Users</span>
        <TimeRangeFilter
          timeRangeFilter={timeRangeFilterComponents.activeUsers}
          filterKey={timeRangeFilter}
        />
      </div>
      <ResponsiveContainer>
        <ComposedChart
          data={activeUsers}
        >
          <XAxis tickMargin={axisOptions.tickMargin} tickLine={false} dataKey="date" axisLine={false} />
          <YAxis tickMargin={axisOptions.tickMargin} tickLine={false} axisLine={false} />
          <Tooltip content={<CustomTooltipMultipleValues />} />
          <Legend payload={activeUsersLegend} />
          <Line dataKey="registered" strokeWidth="1.5" stroke={analyticsColor.primaryBlue} />
          <Line dataKey="subscription" strokeWidth="1.5" stroke={analyticsColor.iosColor} />
          <Line dataKey="cancel" strokeWidth="1.5" stroke={analyticsColor.androidColor} />
          <Line dataKey="trials" strokeWidth="1.5" stroke={analyticsColor.webColor} />

        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

ActiveUsers.propTypes = {
  activeUsers: ImmutablePropTypes.list.isRequired,
  getActiveUsers: PropTypes.func.isRequired,
  timeRangeFilter: PropTypes.string.isRequired,
};

export default ActiveUsers;
