import React, { useEffect } from 'react';
import {
  Popover,
  Button,
} from 'antd';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import HeaderUserIcon from '../../images/header-user-icon.png';

import './authHeader.scss';

export const AuthHeader = ({ actions, user, t }) => {
  const logoutButton = <Button onClick={actions.logoutUser}>{t('button logout text')}</Button>;
  useEffect(() => {
    if (user.size === 0 && localStorage.getItem('token')) {
      actions.getCurrentUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);
  return (
    <div className="auth-header">
      <Popover content={logoutButton} trigger="click">
        <div className="auth-header-avatar">
          <img src={HeaderUserIcon} alt="user-icon" />
          <span>{`${user.get('firstName', '')}`}</span>
        </div>
      </Popover>
    </div>
  );
};

AuthHeader.propTypes = {
  actions: PropTypes.shape({
    getCurrentUser: PropTypes.func.isRequired,
    logoutUser: PropTypes.func.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    get: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(AuthHeader);
