/* eslint-disable import/prefer-default-export */
import { cloudinaryCloudUrl } from '../utils/constants';

export const createCourseThumbnail = async (imageData) => {
  try {
    return await fetch(cloudinaryCloudUrl, {
      method: 'post',
      body: imageData,
    }).then((resp) => resp.json())
      .then((uploadedImageData) => {
        const data = {
          url: uploadedImageData.url,
          name: uploadedImageData.original_filename,
          publicId: uploadedImageData.public_id,
        };
        return {
          data,
          error: null,
        };
      })
      .catch((err) => console.error(err));
  } catch (e) {
    return {
      data: null,
      error: e,
    };
  }
};
