/* eslint-disable react/no-unstable-nested-components */
import React, { useEffect, useState } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Modal } from 'antd';
import { nameLength } from 'utils/helpers';
import { paymentPlanOptions } from 'utils/constants';
import Tables from '../Tables';
import { getColumns } from './components/columns';
import GenerateCoupon from './components/GenerateCoupon';
import './parents.scss';

const Parents = ({
  actions, parents, pagination, showEntries, couponData,
}) => {
  const [searchVal, setSearchVal] = useState('');
  const [visible, setVisible] = useState(false);
  const [couponUserId, setCouponUserId] = useState(null);
  const [paymentPlan, setPaymentPlan] = useState(null);

  useEffect(() => {
    actions.getParents({
      ...pagination.toJS(), searchVal, showEntries, paymentPlan,
    });
    actions.resetOverviewTableData();
  }, [searchVal, pagination, paymentPlan]);

  const parentsData = parents?.toJS();

  const parentsTableData = parentsData.filter((item) => item?.email.toLowerCase()
    .includes(searchVal.toLowerCase())).map((item) => {
    const parentsItem = {
      id: item?.id,
      name: nameLength(`${item?.firstName} ${item?.lastName}`, 25),
      noOfChildren: item?.children.length,
      email: nameLength(item?.email, 25),
      createdAt: moment(item?.createdAt).format('DD/MM/YYYY'),
      enrolled: item?.enrolledNumber,
      premium: item?.paymentStatus || 'Expired',
      status: item?.deletedAt ? 'Deleted' : 'Active',
      children: item?.children,
      location: item?.country,
    };
    return parentsItem;
  });

  const showModal = () => {
    setVisible(true);
  };
  const closeModal = () => {
    setVisible(false);
  };
  const generateCoupon = (id) => {
    actions.setCouponData(null);
    setCouponUserId(id);
    showModal();
  };
  const columns = getColumns(generateCoupon);

  return (
    <div>
      <Tables
        dataSource={parentsTableData}
        columns={columns}
        setSearchVal={setSearchVal}
        search
        exportData
        showEntries
        searchPlaceholder="Search by email"
        tableTitle="Parents"
        pathname="/children"
        paymentStatusFilter
        setPaymentPlan={setPaymentPlan}
        paymentPlanOption={paymentPlanOptions}
        dropDownMinWidth
      />
      <Modal destroyOnClose footer className="generate-coupon-modal" maskClosable={false} title="Generate Coupon" visible={visible} onCancel={closeModal}>
        <GenerateCoupon
          createCoupon={actions.createCouponCode}
          couponData={couponData}
          userId={couponUserId}
        />
      </Modal>
    </div>
  );
};

Parents.propTypes = {
  parents: ImmutablePropTypes.list.isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  showEntries: PropTypes.string.isRequired,
  couponData: ImmutablePropTypes.map.isRequired,
  actions: PropTypes.shape({
    getParents: PropTypes.func.isRequired,
    setCouponData: PropTypes.func.isRequired,
    setPaginationCurrent: PropTypes.func.isRequired,
    setOffset: PropTypes.func.isRequired,
    createCouponCode: PropTypes.func.isRequired,
    resetOverviewTableData: PropTypes.func,
  }).isRequired,
};
export default Parents;
