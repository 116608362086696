/* eslint-disable no-unsafe-optional-chaining */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ComposedChart, Bar, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer, AreaChart,
  Area,
} from 'recharts';
import moment from 'moment';
import CustomTooltipWauAnalytics from './CustomTooltipWauAnalytics';
import {
  analyticsColor, areaChartOptions, axisOptions, wauAnalyticsLegend,
} from './options';
import '../styles.scss';

const WauAnalytics = ({
  getWauAnalyticsData,
  wauAnalyticsData,
  timeRangeFilter,
}) => {
  const [activeUsers, setActiveUsers] = useState(0);

  useEffect(() => {
    getWauAnalyticsData({ dateFilter: timeRangeFilter });
  }, []);

  useEffect(() => {
    let activeUsersTotal = 0;
    wauAnalyticsData.monthlyData?.forEach((item) => {
      activeUsersTotal += parseInt(item.activeUsersCount, 10);
    });
    setActiveUsers(activeUsersTotal);
  }, [wauAnalyticsData]);

  const monthlyRevenuePercentage = Math.sign(wauAnalyticsData?.percentageFromLastMonth) === 1 ? (
    <span className="monthly-revenue-last-month-value">
      &#8593;
      {parseInt(wauAnalyticsData.percentageFromLastMonth, 10) || 0}
      %
    </span>
  ) : (
    <span className="monthly-revenue-last-month-value-negative">
      &#8595;
      {parseInt(wauAnalyticsData.percentageFromLastMonth, 10) || 0}
      %
    </span>
  );

  const firstMonth = moment(wauAnalyticsData?.[0]?.startDate).format('MMMM YYYY');
  return (
    <div className="monthly-revenue-wrapper">
      <div className="analytics-header-wrapper">
        <span className="analytics-title">WAU (Any activity: lesson watched, quiz complete, etc)</span>
      </div>
      <div className="charts-wrapper">
        <div className="monthly-revenue-left-area">
          <span className="monthly-revenue-value">

            {activeUsers}
          </span>
          <span className="monthly-revenue-label">Active Users</span>
          <span className="monthly-revenue-label">
            {firstMonth}

          </span>
          <ResponsiveContainer width="100%" height="45%">
            <AreaChart
              data={wauAnalyticsData.monthlyData}
            >
              <defs>
                <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                  <stop
                    offset={areaChartOptions.startOffset}
                    stopColor={analyticsColor.primaryBlue}
                    stopOpacity={areaChartOptions.stopOpacityStart}
                  />
                  <stop
                    offset={areaChartOptions.endOffset}
                    stopColor={analyticsColor.primaryBlue}
                    stopOpacity={areaChartOptions.stopOpacityEnd}
                  />
                </linearGradient>
              </defs>
              <Area type="monotone" dataKey="activeUsersCount" stroke={analyticsColor.primaryBlue} fill="url(#colorUv)" />
            </AreaChart>
          </ResponsiveContainer>
          <div className="monthly-revenue-last-month">
            {monthlyRevenuePercentage}
            <span className="monthly-revenue-label">from last week</span>
          </div>
        </div>
        <ResponsiveContainer width="80%" height="95%">

          <ComposedChart
            data={wauAnalyticsData.data}
          >
            <XAxis tickMargin={axisOptions.tickMargin} tickLine={false} dataKey="weekLabel" axisLine={false} />
            <YAxis tickMargin={axisOptions.tickMargin} tickLine={false} axisLine={false} />
            <Tooltip content={<CustomTooltipWauAnalytics />} />
            <Legend payload={
                          wauAnalyticsLegend
                        }
            />
            <Bar dataKey="watchedCount" fill={analyticsColor.iosColor} background={{ fill: analyticsColor.backgroundFill }} />
            <Bar dataKey="quizCompletedCount" fill={analyticsColor.androidColor} background={{ fill: analyticsColor.backgroundFill }} />
            <Bar dataKey="activeUsersCount" fill={analyticsColor.webColor} background={{ fill: analyticsColor.backgroundFill }} />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

WauAnalytics.propTypes = {
  wauAnalyticsData: PropTypes.objectOf.isRequired,
  getWauAnalyticsData: PropTypes.func.isRequired,
  timeRangeFilter: PropTypes.string.isRequired,
};

export default WauAnalytics;
