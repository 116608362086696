/* eslint-disable import/prefer-default-export */
import { authorizedApiService } from './apiService';

const BASE_PATH = '/frontend-logs';

export const createLog = async (data) => {
  try {
    const response = await authorizedApiService.post(`${BASE_PATH}`, data);
    return response;
  } catch (err) {
    return {
      data: null,
      error: err,
    };
  }
};
