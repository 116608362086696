import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCourseFilter, selectMaxCourseWeek, selectSelectedCourseFilter } from 'components/ManageCourses/modules/selectors';
import { resetOverviewTableData } from 'components/Tables/modules/actions';
import { selectCurrentUser } from 'components/Login/modules/selectors';
import { selectPagination, selectShowEntries } from '../Tables/modules/selectors';
import View from './View';
import { selectCourses } from '../CreateLesson/modules/selectors';
import { getCourses } from '../CreateLesson/modules/actions';
import { getQuizzes, deleteQuiz } from './modules/actions';
import { selectQuizzes } from './modules/selectors';

const mapStateToProps = (state) => ({
  courses: selectCourses(state),
  quizzes: selectQuizzes(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
  coursesForFilter: selectCourseFilter(state),
  selectedCourseForFilter: selectSelectedCourseFilter(state),
  user: selectCurrentUser(state),
  maxWeek: selectMaxCourseWeek(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCourses,
      getQuizzes,
      deleteQuiz,
      resetOverviewTableData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
