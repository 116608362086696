import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';
import { Map as IMap } from 'immutable';

import PropTypes from 'prop-types';
import { Result, Button, Form } from 'antd';

import CheckCircle from 'components/CheckCircle';
import OnFinishResult from '../../utils/OnFinishResult';
import BasicInfo from './BasicInfo';
import AddFiles from './AddFiles';

import './createLesson.scss';

const CreateLesson = (props) => {
  const {
    actions,
    skills,
    t,
    lessons,
    disabledStartTimes,
    currentStep,
    lesson,
    courses,
    quiz,
    editMode,
    itemForEdit,
    lessonSubmit,
  } = props;
  const id = itemForEdit;
  const [form] = Form.useForm();
  const [videos, setvideos] = useState([]);
  const [quizDataOnSubmit, setQuizDataOnSubmit] = useState(false);
  const [image, setImage] = useState(null);
  const [deletedVideos, setDeletedVideos] = useState([]);
  const [updatedQuizzes, setUpdatedQuizzes] = useState([]);
  const [deletedQuizzes, setDeletedQuizzes] = useState([]);

  const handleSetImage = (value) => {
    setImage(value);
  };
  const setVideos = (action) => {
    setvideos(action);
  };

  const decrementStep = () => {
    actions.setCurrentStep(currentStep - 1);
  };
  const skillOptions = skills.map((item) => ({
    value: item.id,
    name: item.name,
    key: item.id,
  }));

  const courseOptions = courses.map((item) => ({
    value: item.id,
    name: item.title,
    key: item.id,
    totalWeeks: item.totalWeeks,
  }));
  const isCurrentStep = (step) => currentStep === step;
  const clearAllInputs = () => {
    form.resetFields();
    setvideos([]);
    setImage(null);
  };
  useEffect(() => {
    actions.getSkills();
    actions.getCourseFilter();
    actions.setCurrentStep(0);

    if (id) {
      actions.getLesson(id);
    } else {
      actions.setLesson(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (lesson) {
      const lessonValues = {
        title: lesson.get('title', null),
        description: lesson.get('description', null),
        week: lesson.get('week', null),
        order: lesson.get('order', null),
        date: moment(lesson.get('from', null)),
        from: moment(lesson.get('from', null)),
        to: moment(lesson.get('to', null)),
        course: lesson.getIn(['course', 'id'], null),
      };
      form.setFieldsValue(lessonValues);
    } else {
      form.resetFields();
    }
  }, [lesson]);

  const publish = (values) => {
    setQuizDataOnSubmit(!quizDataOnSubmit);

    const {
      course, title, description, week, order,
    } = values;
    const basicInfo = {
      courseId: course,
      title,
      description,
      week,
      order,
    };
    const lessonData = { ...basicInfo, videos, image };
    if (!lesson) {
      actions.createLesson(lessonData);
    } else {
      actions.updateLesson(lesson.get('id'), {
        updatedData: lessonData, deletedVideos, deletedQuizzes, updatedQuizzes,
      });
    }
  };

  useEffect(() => {
    actions.setQuizById(IMap());
  }, []);

  return isCurrentStep(2) ? (
    <Result
      status="success"
      extra={[
        <OnFinishResult />,
      ]}
      icon={<CheckCircle />}
    />
  ) : (
    <div className="step-wrapper">
      <Form
        form={form}
        className="create-lesson-form"
        initialValues={{ remember: false }}
        requiredMark={false}
        onFinish={publish}
      >
        <BasicInfo
          skills={skillOptions}
          courses={courseOptions}
          lessons={lessons}
          getLessons={actions.getLessons}
          disabledStartTimes={disabledStartTimes}
          isCurrentStep={isCurrentStep(0)}
          lesson={lesson}
        />
        <AddFiles
          isCurrentStep={isCurrentStep(0)}
          goToPreviousStep={decrementStep}
          handleSubmit={publish}
          lesson={lesson}
          setQuiz={actions.setQuizById}
          editQuiz={quiz}
          editMode={editMode}
          videos={videos}
          setVideos={setVideos}
          quizDataOnSubmit={quizDataOnSubmit}
          handleSetImage={handleSetImage}
          image={image}
          setDeletedVideos={setDeletedVideos}
          setDeletedQuizzes={setDeletedQuizzes}
          setUpdatedQuizzes={setUpdatedQuizzes}

        />
        <div className="content-and-chapters-form-fields-wrapper">
          <Button size="large" htmlType="button" onClick={clearAllInputs} className="form-btn-clear-all">
            {t('button clearAllInputs text')}
          </Button>
          <Button className="form-btn-publish" size="large" htmlType="submit" disabled={lessonSubmit} loading={lessonSubmit}>
            {t('button publish text')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

CreateLesson.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    getSkills: PropTypes.func.isRequired,
    getCourseFilter: PropTypes.func.isRequired,
    getLessons: PropTypes.func.isRequired,
    createLesson: PropTypes.func.isRequired,
    setCurrentStep: PropTypes.func.isRequired,
    getLesson: PropTypes.func.isRequired,
    setLesson: PropTypes.func.isRequired,
    updateLesson: PropTypes.func.isRequired,
    setQuizById: PropTypes.func.isRequired,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  skills: ImmutablePropTypes.list.isRequired,
  lessons: ImmutablePropTypes.list.isRequired,
  courses: ImmutablePropTypes.list.isRequired,
  disabledStartTimes: ImmutablePropTypes.list.isRequired,
  lessonSubmit: PropTypes.bool.isRequired,
  currentStep: PropTypes.number.isRequired,
  lesson: ImmutablePropTypes.map,
  quiz: ImmutablePropTypes.map,
  editMode: PropTypes.bool,
  itemForEdit: PropTypes.string.isRequired,
};

CreateLesson.defaultProps = {
  lesson: null,
  quiz: null,
  editMode: false,
};

export default withTranslation()(CreateLesson);
