import { fromJS, List as IList, Map as IMap } from 'immutable';
import { timeRangeOptions } from 'utils/constants';
import { types } from './actions';

const defaultState = fromJS({
  subscriptionPaymentPerUser: IList(),
  subscriptionPaymentPerUserHasMoreData: true,
  trialsPaymentPerUser: IList(),
  trialsPaymentPerUserHasMoreData: true,
  monthlyRevenue: IMap(),
  averagePaymentByUserData: IList(),
  averagePaymentByUserDataByDevice: IMap(),
  averagePaymentCalculationData: IMap(),
  trialMemberships: IMap(),
  subscriptionMemberships: IMap(),
  platformInstallations: IMap(),
  activeUsers: IList(),
  timeRangeFilter: IMap({
    installs: timeRangeOptions.month,
    activeUsers: timeRangeOptions.month,
    trials: timeRangeOptions.month,
    subscriptions: timeRangeOptions.month,
    averagePayment: timeRangeOptions.month,
    subscriptionPerUsers: timeRangeOptions.month,
    trialsPerUsers: timeRangeOptions.month,
    monthlyRevenue: timeRangeOptions.month,
  }),
  userSubscriptionsOverview: IList(),
  courseMetrics: IList(),
  lessonMetrics: IList(),
  quizMetrics: IList(),
  currentMembershipSubscriptions: IMap(),
  currentMembershipTrials: IMap(),
  typeOfUsersData: IMap(),
  wauAnalytics: IMap(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_TRIALS_PAYMENT_PER_USER: {
      if (state.get('trialsPaymentPerUser').size === 0) {
        return state.set('trialsPaymentPerUser', IList(action.payload.data));
      }
      if (action.payload.data.length) {
        return state.set('trialsPaymentPerUser', state.get('trialsPaymentPerUser').concat(action.payload.data));
      }
      return state.set('trialsPaymentPerUserHasMoreData', false);
    }
    case types.RESET_TRIALS_PAYMENT_PER_USER:
      return state.set(
        'trialsPaymentPerUserHasMoreData',
        true,
      ).set(
        'trialsPaymentPerUser',
        IList(),
      );
    case types.SET_SUBSCRIPTIONS_PAYMENT_PER_USER: {
      if (state.get('subscriptionPaymentPerUser').size === 0) {
        return state.set('subscriptionPaymentPerUser', IList(action.payload.data));
      }
      if (action.payload.data.length) {
        return state.set('subscriptionPaymentPerUser', state.get('subscriptionPaymentPerUser').concat(action.payload.data));
      }
      return state.set('subscriptionPaymentPerUserHasMoreData', false);
    }
    case types.RESET_SUBSCRIPTIONS_PAYMENT_PER_USER:
      return state.set(
        'subscriptionPaymentPerUserHasMoreData',
        true,
      ).set(
        'subscriptionPaymentPerUser',
        IList(),
      );
    case types.SET_AVERAGE_PAYMENT_PER_USER:
      return state.set(
        'averagePaymentByUserData',
        IList(action.payload.data),
      );
    case types.SET_AVERAGE_PAYMENT_PER_USER_BY_DEVICE: {
      const data = {
        iosNumber: (action.payload.data?.IOS)?.toFixed() || 0,
        webNumber: (action.payload.data?.WEB)?.toFixed() || 0,
        androidNumber: (action.payload.data?.ANDROID)?.toFixed() || 0,
      };
      return state.set(
        'averagePaymentByUserDataByDevice',
        IMap(data),
      ); }
    case types.SET_AVERAGE_PAYMENT_CALCULATION_DATA: {
      return state.set(
        'averagePaymentCalculationData',
        IMap({
          paymentCount: action.payload.data.paymentsCount,
          paymentSum: action.payload.data.paymentsSum,
        }),
      );
    }
    case types.SET_MONTHLY_REVENUE:
      return state.set(
        'monthlyRevenue',
        IMap(action.payload.data),
      );
    case types.SET_USER_SUBSCRIPTIONS_OVERVIEW:
      return state.set(
        'userSubscriptionsOverview',
        IList(action.payload.data),
      );
    case types.SET_TRIAL_MEMBERSHIPS: {
      return state.set('trialMemberships', IMap(action.payload.data));
    }
    case types.SET_SUBSCRIPTION_MEMBERSHIPS: {
      return state.set('subscriptionMemberships', IMap(action.payload.data));
    }
    case types.SET_PLATFORM_INSTALLATIONS: {
      return state.set('platformInstallations', IMap(action.payload.data));
    }
    case types.SET_ACTIVE_USERS: {
      return state.set('activeUsers', IList(action.payload.data));
    }
    case types.SET_COURSE_METRICS: {
      return state.set('courseMetrics', IList(action.payload.data));
    }
    case types.SET_LESSON_METRICS: {
      return state.set('lessonMetrics', IList(action.payload.data));
    }
    case types.SET_QUIZ_METRICS: {
      return state.set('quizMetrics', IList(action.payload.data));
    }
    case types.SET_CURRENT_MEMBERSHIP_SUBSCRIPTIONS: {
      return state.set('currentMembershipSubscriptions', IMap(action.payload.data));
    }
    case types.SET_CURRENT_MEMBERSHIP_TRIALS: {
      return state.set('currentMembershipTrials', IMap(action.payload.data));
    }
    case types.SET_TYPE_OF_USERS: {
      return state.set('typeOfUsersData', IMap(action.payload.data));
    }
    case types.SET_WAU_ANALYTICS: {
      return state.set('wauAnalytics', IMap(action.payload.data));
    }
    case types.SET_TIME_RANGE_FILTER_SINGLE_OPTION:
      return state.setIn(
        ['timeRangeFilter', action.payload.data.key],
        action.payload.data.value,
      );
    case types.SET_TIME_RANGE_FILTER_GLOBAL_OPTION:
      return state.set(
        'timeRangeFilter',
        IMap({
          installs: action.payload.data.value,
          activeUsers: action.payload.data.value,
          trials: action.payload.data.value,
          subscriptions: action.payload.data.value,
          averagePayment: action.payload.data.value,
          subscriptionPerUsers: action.payload.data.value,
          trialsPerUsers: action.payload.data.value,
          monthlyRevenue: action.payload.data.value,
        }),
      );
    default:
      return state;
  }
};
