import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { resetOverviewTableData } from 'components/Tables/modules/actions';
import { selectCurrentUser } from 'components/Login/modules/selectors';
import View from './View';
import { selectCourses } from '../CreateLesson/modules/selectors';
import { getCourses } from '../CreateLesson/modules/actions';
import { getCourseBatches, deleteCourse } from './modules/actions';
import { selectCourseBatches } from './modules/selectors';
import { selectPagination, selectShowEntries } from '../Tables/modules/selectors';

const mapStateToProps = (state) => ({
  courses: selectCourses(state),
  courseBatches: selectCourseBatches(state),
  pagination: selectPagination(state),
  showEntries: selectShowEntries(state),
  user: selectCurrentUser(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCourses,
      getCourseBatches,
      deleteCourse,
      resetOverviewTableData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
