import {
  all,
  put,
  takeLeading,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import authService from 'services/authService';
import { setIsLoading, setIsSuccess, types } from './actions';

export function * changePassword(action) {
  const { oldPassword, newPassword } = action.payload;
  const { data, error } = yield authService.changePassword(oldPassword, newPassword);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
    yield put(setIsLoading(false));
  } else {
    localStorage.setItem('token', data.accessToken);
    if (localStorage.getItem('refreshToken')) {
      localStorage.setItem('refreshToken', data.refreshToken);
    }
    yield all([
      put(setIsLoading(false)),
      put(setIsSuccess(true)),
    ]);
  }
}

export default function * changePasswordSaga() {
  yield all([
    takeLeading(types.CHANGE_PASSWORD, changePassword),
  ]);
}
