export const types = {
  REQUEST_PASSWORD_RESET: 'REQUEST_PASSWORD_RESET',
  RESET_PASSWORD: 'RESET_PASSWORD',
  TOGGLE_SUCCESS_SCREEN: 'TOGGLE_SUCCESS_SCREEN',
  SET_SUCCESS_SCREEN: 'SET_SUCCESS_SCREEN',
  SET_IS_LOADING: 'SET_IS_LOADING',
};

export const requestPasswordReset = (email) => ({
  type: types.REQUEST_PASSWORD_RESET,
  payload: { email },
});

export const resetPassword = (newPassword, email, token) => ({
  type: types.RESET_PASSWORD,
  payload: { email, token, newPassword },
});

export const toggleSuccessScreen = () => ({
  type: types.TOGGLE_SUCCESS_SCREEN,
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});
