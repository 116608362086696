import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { searchArray } from 'utils/helpers';
import Tables from 'components/Tables';
import CautionModal from 'components/Tables/components/CautionModal';
import CreateQuiz from 'components/CreateQuiz';
import { getColumns } from './components/columns';

const ManageQuizzes = (props) => {
  const {
    actions, quizzes, coursesForFilter, pagination, showEntries, selectedCourseForFilter, user,
    maxWeek,
  } = props;

  const [visible, setVisible] = useState(false);
  const [cautionModalVisible, setCautionModalVisible] = useState(false);
  const [quizForEdit, setQuizForEdit] = useState(null);
  const [quizForDelete, setQuizForDelete] = useState(null);
  const [searchVal, setSearchVal] = useState('');
  const [week, setWeek] = useState(null);

  const showModal = () => {
    setVisible(true);
  };
  const editQuiz = (item) => {
    setQuizForEdit(item);
    setVisible(true);
  };
  const closeModal = () => {
    setQuizForEdit(null);
    setVisible(false);
    setCautionModalVisible(false);
  };

  const deleteQuiz = () => {
    actions.deleteQuiz(quizForDelete);
    setCautionModalVisible(false);
  };

  const cautionModalVisibleOn = (item) => {
    setCautionModalVisible(true);
    setQuizForDelete(item);
  };

  useEffect(() => {
    actions.getQuizzes({
      ...pagination.toJS(), searchVal, showEntries, selectedCourseForFilter, week,
    });
    actions.resetOverviewTableData();
  }, [searchVal, pagination, selectedCourseForFilter, week]);

  const columns = getColumns(editQuiz, cautionModalVisibleOn, user);

  const quizzesData = quizzes?.toJS();

  const quizTableData = searchArray(quizzesData, 'title', searchVal).map((item) => {
    const quizItem = {
      id: item?.id,
      courseId: item?.course?.id,
      userQuizzes: item?.userQuizzes,
      courseAttendees: item?.course?.courseAttendees,
      title: item?.title,
      week: item?.week,
      status: item?.deletedAt ? 'Inactive' : 'Active',
      students: item?.course?.courseAttendees?.length,
      course: item?.course?.title,
    };
    return quizItem;
  });

  const courseOptions = coursesForFilter?.map((item) => item.title);

  return (
    <div>
      <Tables
        dataSource={quizTableData}
        columns={columns}
        exportData
        showEntries
        tableTitle="Quizzes"
        addNew
        addNewComponent={CreateQuiz}
        showModal={showModal}
        closeModal={closeModal}
        visible={visible}
        itemForEdit={quizForEdit?.id}
        modalTitle={quizForEdit ? `Edit ${quizForEdit?.title}` : 'Add new quiz'}
        pathname="/quiz-overview"
        setSearchVal={setSearchVal}
        search
        searchPlaceholder="Search By Quiz"
        courseFilter
        courseOptions={courseOptions}
        weekFilter
        weekOptions={maxWeek}
        setWeekFilter={setWeek}
        dropDownMinWidth
      />
      <CautionModal
        visible={cautionModalVisible}
        cautionDescription="Are you sure you want to delete this quiz?"
        closeModal={closeModal}
        onYes={deleteQuiz}
      />
    </div>
  );
};

ManageQuizzes.propTypes = {
  actions: PropTypes.shape({
    getCourses: PropTypes.func.isRequired,
    getQuizzes: PropTypes.func.isRequired,
    deleteQuiz: PropTypes.func.isRequired,
    resetOverviewTableData: PropTypes.func,
  }).isRequired,
  quizzes: ImmutablePropTypes.list.isRequired,
  coursesForFilter: ImmutablePropTypes.list.isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  showEntries: PropTypes.string.isRequired,
  selectedCourseForFilter: PropTypes.string.isRequired,
  maxWeek: PropTypes.number.isRequired,
  user: PropTypes.shape({
    get: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
};

export default withTranslation()(ManageQuizzes);
