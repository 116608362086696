export const types = {
  GET_LESSONS_BY_COURSE: 'GET_LESSONS_BY_COURSE',
  SET_SCHEDULED_LESSONS: 'SET_SCHEDULED_LESSONS',
  DELETE_LESSON: 'DELETE_LESSON',
  LESSON_DELETED: 'LESSON_DELETED',
};

export const getLessonsByCourse = (pagination) => ({
  type: types.GET_LESSONS_BY_COURSE,
  payload: { pagination },
});

export const setScheduledLessons = (lessons) => ({
  type: types.SET_SCHEDULED_LESSONS,
  payload: { lessons },
});

export const deleteLesson = (id) => ({
  type: types.DELETE_LESSON,
  payload: { id },
});

export const lessonDeleted = (id) => ({
  type: types.LESSON_DELETED,
  payload: { id },
});
