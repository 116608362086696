import {
  all,
  put,
  takeLatest,
  takeEvery,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import quizzesService from 'services/quizzesService';
import { arrayOfWeeks } from 'utils/helpers';
import { getCourseFilter, setMaxCourseWeek } from 'components/ManageCourses/modules/actions';
import {
  types,
  setQuizzes,
  quizDeleted,
} from './actions';
import { setIsLoading, setPaginationTotal, setPaginationNoOfRows } from '../../Tables/modules/actions';

export function * getQuizzes(action) {
  yield put(setIsLoading(true));

  const { pagination } = action.payload;
  const { data, error } = yield quizzesService.getManageQuizzesData(pagination);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setQuizzes(data.rows));
    const weeks = ['All', ...arrayOfWeeks(data.maxWeek)];
    yield put(setMaxCourseWeek(weeks));
    yield put(getCourseFilter());
    yield put(setPaginationTotal(data.count));
    if (pagination.showEntries === 'All') {
      yield put(setPaginationNoOfRows(data.count));
    }
  }
  yield put(setIsLoading(false));
}

function * deleteQuiz(action) {
  const { id } = action.payload;
  const { error } = yield quizzesService.deleteQuizData(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('manageQuizzes quizModal deleted message'),
    });
    yield put(quizDeleted(id));
  }
}

export default function * manageQuizzesSaga() {
  yield all([
    takeLatest(types.GET_QUIZZES, getQuizzes),
    takeEvery(types.DELETE_QUIZ, deleteQuiz),
  ]);
}
