import parentIconActive from '../../../images/parent-icon-active.svg';
import lessonIconActive from '../../../images/lesson-icon-active.svg';
import courseIconActive from '../../../images/course-icon-active.svg';
import quizIconActive from '../../../images/quiz-icon-active.svg';
import childIconActive from '../../../images/child-icon-active.svg';
import dashboard from '../../../images/dashboard.svg';

export const defaultMenuItems = [
  {
    key: '/analytics-dashboard',
    imgSrc: dashboard,
    alt: 'dashboard icon',
    label: 'Dashboard',
  },
  {
    key: '/parents',
    imgSrc: parentIconActive,
    alt: 'parent icon',
    label: 'Parents',
  },
  {
    key: '/children',
    imgSrc: childIconActive,
    alt: 'child icon',
    label: 'Children',
  },
  {
    key: '/manage-courses',
    imgSrc: courseIconActive,
    alt: 'course icon',
    label: 'Courses',
  },
  {
    key: '/schedule',
    imgSrc: lessonIconActive,
    alt: 'lesson icon',
    label: 'Lessons',
  },
  {
    key: '/manage-quizzes',
    imgSrc: quizIconActive,
    alt: 'quiz icon',
    label: 'Quizzes',
  },
];

export const marketingGuardMenuItems = [
  {
    key: '/analytics-dashboard',
    imgSrc: dashboard,
    alt: 'dashboard icon',
    label: 'Dashboard',
  },
];
