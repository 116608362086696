import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import moment from 'moment';

import {
  Form, Input, Button, Switch, DatePicker, InputNumber,
} from 'antd';

import './createCourseForm.scss';
import {
  ageGroupFromRules,
  ageGroupToRules,
  courseDescriptionRules,
  courseTitleRules,
  courseTotalWeekRules,
} from 'utils/inputRules';
import UploadThumbnail from 'utils/UploadThumbnail';

const CreateCourseForm = (props) => {
  const {
    onFinish,
    t,
    course,
    isLoading,
  } = props;

  const [form] = Form.useForm();

  const { TextArea } = Input;

  const [image, setImage] = useState(null);
  const [imageLandscape, setImageLandscape] = useState(null);
  const [freeCourse, setFreeCourse] = useState(true);
  const [activeCourse, setActiveCourse] = useState(true);
  const [startDate, setStartDate] = useState(moment(Date.now()));

  const clearAllInputs = () => {
    form.resetFields();
    setImage('');
    setImageLandscape('');
    setActiveCourse(true);
    setFreeCourse(true);
    setStartDate(moment());
  };

  const freeCourseCheckbox = () => {
    setFreeCourse((status) => !status);
  };
  const activeCourseCheckbox = () => {
    setActiveCourse((status) => !status);
  };
  const startDateHandler = (date) => {
    setStartDate(date);
  };

  const disabledDate = (current) => current && current < moment().startOf('day');
  useEffect(() => {
    if (course?.size > 0) {
      form.setFieldsValue({
        title: course.get('title', null),
        description: course.get('description', null),
        totalWeeks: course.get('totalWeeks', null),
        fromAge: course.get('fromAge', null),
        toAge: course.get('toAge', null),
      });
      setFreeCourse(course.get('free', true));
      setActiveCourse(course.get('isActive', true));
      setStartDate(moment(course.get('startDate', moment())));
    } else {
      clearAllInputs();
    }
  }, [course]);
  const createCourse = (values) => {
    const courseJSON = {
      title: values.title,
      description: values.description,
      totalWeeks: values.totalWeeks,
      free: freeCourse,
      isActive: activeCourse,
      startDate,
      fromAge: values.fromAge,
      toAge: values.toAge,
    };
    onFinish(courseJSON, image, imageLandscape);
  };
  const courseDisabledCondition = !image || !imageLandscape;
  const imageError = !image && !course;
  const imageLandscapeError = !imageLandscape && !course;

  return (
    <Form form={form} className="create-course-form" onFinish={createCourse}>
      <div className="create-course-wrapper">
        <div className="create-course-column">
          <p className="add-course-labels">Title</p>
          <Form.Item
            name="title"
            rules={courseTitleRules}
            className="title-field add-course-inputs"
          >
            <Input className="add-course-inputs" placeholder="Enter" maxLength={30} />
          </Form.Item>
          <p className="add-course-labels">Description</p>
          <Form.Item
            name="description"
            rules={courseDescriptionRules}
            className="title-field add-course-description"
          >
            <TextArea className="add-course-textarea" placeholder="Enter" />
          </Form.Item>
          <div className="thumbnail-wrapper">
            <p className="add-course-labels ">Thumbnail Portrait</p>
            <Form.Item className="title-field add-course-inputs">
              <UploadThumbnail
                courseImages={course?.toJS()?.courseImages?.[0]}
                handleSetImage={setImage}
                image={image}
              />
            </Form.Item>
            <p className="error-message">{imageError && 'Please upload thumbnail'}</p>
          </div>
          <div className="thumbnail-wrapper thumbnail-landscape">
            <p className="add-course-labels ">Thumbnail Landscape</p>
            <Form.Item className="title-field add-course-inputs" rules={courseTitleRules}>
              <UploadThumbnail
                courseImages={course?.toJS()?.courseImagesLandscape?.[0]}
                handleSetImage={setImageLandscape}
                image={imageLandscape}
              />
            </Form.Item>
            <p className="error-message">{imageLandscapeError && 'Please upload thumbnail'}</p>
          </div>
        </div>
        <div className="create-course-column">
          <p className="add-course-labels">Duration</p>
          <div className="time-inputs-wrapper">
            <Form.Item
              name="totalWeeks"
              rules={courseTotalWeekRules}
              className="title-field add-course-inputs"
            >
              <InputNumber
                className="add-course-inputs"
                placeholder="Enter"
                min={1}
              />
            </Form.Item>
          </div>
          <p className="add-course-labels">Start Date</p>
          <div className="time-inputs-wrapper">
            <DatePicker
              className="add-course-inputs course-start-date"
              placeholder="Enter"
              onChange={startDateHandler}
              defaultValue={moment()}
              value={startDate}
              disabledDate={disabledDate}
              allowClear
            />
          </div>
          <p className="add-course-labels">Age Group</p>
          <div className="time-inputs-wrapper age-group-inputs">
            <Form.Item
              name="fromAge"
              rules={ageGroupFromRules}
              className="title-field add-course-inputs"
            >
              <InputNumber
                className="add-course-inputs"
                placeholder="From Age"

              />
            </Form.Item>
            <Form.Item
              name="toAge"
              rules={ageGroupToRules}
              className="title-field add-course-inputs"
            >
              <InputNumber
                className="add-course-inputs"
                placeholder="To Age"

              />
            </Form.Item>
          </div>
          {!startDate && <span className="error-message">Please enter start Date</span>}

          <div className="switch-course-wrapper">
            <div className="free-course-wrapper">
              <p className="add-course-labels">Premium</p>
              <Switch
                onChange={freeCourseCheckbox}
                checked={!freeCourse}
              />
            </div>
            <div className="active-course-wrapper">
              <p className="add-course-labels">Active</p>
              <Switch
                onChange={activeCourseCheckbox}
                checked={activeCourse}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="create-course-buttons">
        <Button
          size="large"
          htmlType="submit"
          onClick={clearAllInputs}
          className="clear-all-inputs-create-course"
        >
          Clear all Inputs
        </Button>
        {course ? (
          <Button
            disabled={!startDate}
            size="large"
            className="add-course-button"
            htmlType="submit"
          >
            {t('createCourse editCourseButton')}
          </Button>
        )
          : (
            <Button
              loading={isLoading}
              size="large"
              className="add-course-button"
              htmlType="submit"
              disabled={courseDisabledCondition}
            >
              {t('createCourse addCourseButton')}
            </Button>
          )}
      </div>
    </Form>
  );
};

CreateCourseForm.propTypes = {
  onFinish: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  course: ImmutablePropTypes.map,
  isLoading: PropTypes.bool.isRequired,
};

CreateCourseForm.defaultProps = {
  course: null,
};

export default withTranslation()(CreateCourseForm);
