import { authorizedApiService } from './apiService';

const BASE_PATH = '/activities';

export default {
  getActivities: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getAttendees: async ({ id, ...params }) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}/attendees`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
