import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Progress } from 'antd';
import { calculatePercentage } from 'utils/helpers';
import { analyticsColor, timeRangeFilterComponents } from './options';
import '../styles.scss';
import TimeRangeFilter from './TimeRangeFilter';

const Trials = ({
  averagePaymentByUserDataByDevice,
  getAveragePaymentOfUsers,
  timeRangeFilter,
  averagePaymentCalculationData,
}) => {
  const totalNumber = 10;
  const customLabel = (value) => `$ ${value.toFixed(2)}`;
  const [averageAmount, setAverageAmount] = useState(0);

  useEffect(() => {
    getAveragePaymentOfUsers({ dateFilter: timeRangeFilter });
  }, [timeRangeFilter]);

  useMemo(() => {
    setAverageAmount((averagePaymentCalculationData.paymentSum
      / averagePaymentCalculationData.paymentCount) || 0);
  }, [averagePaymentCalculationData]);

  const iosNumber = calculatePercentage(averagePaymentByUserDataByDevice?.iosNumber, totalNumber);
  // eslint-disable-next-line max-len
  const androidNumber = calculatePercentage(averagePaymentByUserDataByDevice?.androidNumber, totalNumber);
  const webNumber = calculatePercentage(averagePaymentByUserDataByDevice?.webNumber, totalNumber);

  return (
    <div className="memebership-wrapper">
      <div className="trials-wrapper">
        <div className="analytics-header-wrapper">
          <span className="analytics-title">Avg. Payment per user</span>
          <TimeRangeFilter
            timeRangeFilter={timeRangeFilterComponents.averagePayment}
            filterKey={timeRangeFilter}
          />
        </div>
        <div className="analytics-info">
          <span className="value">{customLabel(averageAmount)}</span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">iOS</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.iosColor} showInfo={false} percent={iosNumber} />
          <span span className="value">
            $
            {averagePaymentByUserDataByDevice?.iosNumber}
          </span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">Android</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.androidColor} showInfo={false} percent={androidNumber} />
          <span className="value">
            $
            {averagePaymentByUserDataByDevice?.androidNumber}
          </span>
        </div>
        <div className="analytics-progress-item">
          <span className="label">Web</span>
          <Progress strokeLinecap="square" strokeColor={analyticsColor.webColor} showInfo={false} percent={webNumber} />
          <span className="value">
            $
            {averagePaymentByUserDataByDevice?.webNumber}
          </span>
        </div>
      </div>
    </div>
  );
};

Trials.propTypes = {
  getAveragePaymentOfUsers: PropTypes.func.isRequired,
  averagePaymentByUserDataByDevice: PropTypes.objectOf.isRequired,
  averagePaymentCalculationData: PropTypes.objectOf.isRequired,
  timeRangeFilter: PropTypes.objectOf.isRequired,
};

export default Trials;
