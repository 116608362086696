import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'antd';
import i18n from 'i18n';

const OnFinishResult = () => {
  const history = useHistory();
  const pushToHome = () => {
    history.push('/');
  };
  return (
    <div key="info-wrapper" className="stream-lesson-info-wrapper">
      <Button type="primary" onClick={pushToHome}>
        {i18n.t('createQuiz quizCreated confirmButton text')}
      </Button>
    </div>
  );
};

export default OnFinishResult;
