import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { selectCourseFilter } from 'components/ManageCourses/modules/selectors';
import { getCourseFilter } from '../ManageCourses/modules/actions';
import View from './View';
import {
  selectCreatedQuiz,
  selectCurrentStep,
  selectQuiz,
  selectIsLoading,
} from './modules/selectors';
import {
  createQuiz,
  setCurrentStep,
  setCourses,
  getQuizById,
  setQuizById,
  updateQuiz,
  setIsLoading,
  restartQuizData,
} from './modules/actions';

const mapStateToProps = (state) => ({
  currentStep: selectCurrentStep(state),
  createdQuiz: selectCreatedQuiz(state),
  courses: selectCourseFilter(state),
  quiz: selectQuiz(state),
  isLoading: selectIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      createQuiz,
      setCurrentStep,
      getCourseFilter,
      setCourses,
      getQuizById,
      setQuizById,
      updateQuiz,
      setIsLoading,
      restartQuizData,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
