import { authorizedApiService } from './apiService';

const BASE_PATH = '/students';

export default {
  getStudents: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getStudent: async (id) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getStudentActivities: async (id, params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}/activities`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
