export const types = {
  GET_QUIZZES: 'GET_QUIZZES',
  SET_QUIZZES: 'SET_QUIZZES',
  DELETE_QUIZ: 'DELETE_QUIZ',
  QUIZ_DELETED: 'QUIZ_DELETED',
};

export const getQuizzes = (pagination) => ({
  type: types.GET_QUIZZES,
  payload: { pagination },
});

export const setQuizzes = (quizzes) => ({
  type: types.SET_QUIZZES,
  payload: { quizzes },
});

export const deleteQuiz = (id) => ({
  type: types.DELETE_QUIZ,
  payload: { id },
});

export const quizDeleted = (id) => ({
  type: types.QUIZ_DELETED,
  payload: { id },
});
