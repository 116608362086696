import {
  all,
  put,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import lessonsService from 'services/lessonsService';
import { getCourseFilter, setMaxCourseWeek } from 'components/ManageCourses/modules/actions';
import { arrayOfWeeks } from 'utils/helpers';
import {
  types, setScheduledLessons, lessonDeleted,
} from './actions';

import { setPaginationTotal, setIsLoading, setPaginationNoOfRows } from '../../Tables/modules/actions';

function * getManageLessons(action) {
  yield put(setIsLoading(true));

  const { pagination } = action.payload;
  const { data, error } = yield lessonsService.getLessons(pagination);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setScheduledLessons(data.rows));
    const weeks = ['All', ...arrayOfWeeks(data.maxWeek)];
    yield put(setMaxCourseWeek(weeks));
    yield put(getCourseFilter());
    yield put(setPaginationTotal(data.count));
    if (pagination.showEntries === 'All') {
      yield put(setPaginationNoOfRows(data.count));
    }
  }
  yield put(setIsLoading(false));
}

function * deleteLesson(action) {
  const { id } = action.payload;
  const { error } = yield lessonsService.deleteLesson(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('schedule lessonModal deleted message'),
    });
    yield put(lessonDeleted(id));
  }
}

export default function * scheduleSaga() {
  yield all([
    takeLatest(types.GET_LESSONS_BY_COURSE, getManageLessons),
    takeEvery(types.DELETE_LESSON, deleteLesson),
  ]);
}
