import {
  all,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import studentsService from 'services/studentsService';
import {
  types, setCurrentStudent, setIsLoadingStudent,
  setIsLoadingTable, setUserActivities, setTotalCount,
} from './actions';

function * loadCurrentUser(action) {
  yield put(setIsLoadingStudent(true));
  const {
    id,
  } = action.payload;
  const { data, error } = yield studentsService.getStudent(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setCurrentStudent(data)),
    ]);
  }
  yield put(setIsLoadingStudent(false));
}

function * loadUserActivities(action) {
  yield put(setIsLoadingTable(true));
  const {
    id, activeTab, pagination,
  } = action.payload;
  const type = (() => {
    switch (activeTab) {
      case '1':
        return undefined;
      case '2':
        return 'lesson';
      default:
        return 'quiz';
    }
  })();
  const { data, error } = yield studentsService.getStudentActivities(id, {
    type,
    page: pagination.current || undefined,
  });
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([
      put(setUserActivities(data.rows)),
      put(setTotalCount(data.count)),
    ]);
  }
  yield put(setIsLoadingTable(false));
}

export default function * studentProfileSaga() {
  yield all([
    takeLatest(types.LOAD_USER, loadCurrentUser),
    takeLatest(types.LOAD_USER_ACTIVITIES, loadUserActivities),
  ]);
}
