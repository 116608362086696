import React from 'react';
import Logo from '../Logo/logo.png';
import './styles.scss';

const MobileView = () => (
  <div className="mobile-view-wrapper">
    <img src={Logo} className="logo" alt="kutuby-logo" />
    <span className="description">
      <span className="description-bold">
        This app has been deactivated for use on your mobile device.
      </span>
    </span>
  </div>
);

export default MobileView;
