import { all, spawn } from 'redux-saga/effects';

import userSaga from 'components/Users/modules/saga';
import tableSaga from 'components/Tables/modules/saga';
import loginSaga from '../components/Login/modules/saga';
import createLessonSaga from '../components/CreateLesson/modules/saga';
import createCourseSaga from '../components/CreateCourse/modules/saga';
import resetPasswordSaga from '../components/ResetPassword/modules/saga';
import scheduleSaga from '../components/Schedule/modules/saga';
import createQuizSaga from '../components/CreateQuiz/modules/saga';
import manageCoursesSaga from '../components/ManageCourses/modules/saga';
import manageQuizzesSaga from '../components/ManageQuizzes/modules/saga';
import dashboardSaga from '../components/Dashboard/modules/saga';
import studentsSaga from '../components/Students/modules/saga';
import studentProfileSaga from '../components/StudentProfile/modules/saga';
import changePasswordSaga from '../components/ChangePassword/modules/saga';
import childrenSaga from '../components/Children/modules/saga';
import parentsSaga from '../components/Parents/modules/saga';
import analyticsSaga from '../components/AnalyticsDashboard/modules/saga';

const sagas = [
  loginSaga,
  childrenSaga,
  parentsSaga,
  createLessonSaga,
  userSaga,
  createCourseSaga,
  resetPasswordSaga,
  scheduleSaga,
  createQuizSaga,
  manageCoursesSaga,
  manageQuizzesSaga,
  dashboardSaga,
  studentsSaga,
  studentProfileSaga,
  changePasswordSaga,
  tableSaga,
  analyticsSaga,
];

export default function * rootSaga() {
  yield all(sagas.map(spawn));
}
