import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  parents: IList(),
  children: IList(),
  parent: {},
  parentChildTable: false,
  childProfile: IMap(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ALL_PARENTS:
      return state.set('parents', IList(action.payload.parents));
    case types.SET_ALL_CHILDREN:
      return state.set('children', IList(action.payload.children));
    case types.SET_PARENT_BY_EMAIL:
      return state.set('parent', action.payload.parent);
    case types.SET_PARENT_CHILD_TABLE_TRUE:
      return state.set('parentChildTable', true);
    case types.SET_PARENT_CHILD_TABLE_FALSE:
      return state.set('parentChildTable', false);
    case types.SET_CHILD_BY_ID:
      return state.set('childProfile', action.payload.data);
    default:
      return state;
  }
};
