import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  parents: IList(),
  couponData: IMap(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ALL_PARENTS:
      return state.set('parents', IList(action.payload.parents));
    case types.SET_COUPON_DATA:
      return state.set('couponData', IMap(action.payload.data));
    default:
      return state;
  }
};
