import React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import trashIcon from '../../../images/icons_trash-can.svg';
import editIcon from '../../../images/icons_edit.svg';

import '../../ManageCourses/manageCourses.scss';

// eslint-disable-next-line import/prefer-default-export
export const getColumns = (editQuiz, cautionModalVisibleOn, user) => {
  const renderStatus = (item) => (
    <div
      className={classNames({
        'status-wrapper': true,
        'status-wrapper-active': item.status === 'Active',
        'status-wrapper-inactive': item.status === 'Inactive',
      })}
    >
      <p>{item?.status}</p>
    </div>
  );

  const renderActionButtons = (item) => {
    const actionButtonEdit = (e) => {
      e.stopPropagation();
      editQuiz(item);
    };

    const actionButtonDelete = (e) => {
      e.stopPropagation();
      cautionModalVisibleOn(item.id);
    };
    return (
      item.status === 'Active' && (
        <>
          <Button
            className="edit-item-icon"
            size="small"
            icon={<img src={editIcon} alt="edit icon" />}
            onClick={actionButtonEdit}
          />
          {user.get('type') !== 'TEACHER' && (
            <Button
              className="delete-item-icon"
              size="small"
              icon={<img src={trashIcon} alt="delete icon" />}
              onClick={actionButtonDelete}
            />
          )}
        </>
      )
    );
  };

  return ([
    {
      title: 'Quiz',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Course',
      dataIndex: 'course',
      key: 'course',
    },
    {
      title: 'Week',
      dataIndex: 'week',
      key: 'week',
    },
    {
      title: 'Students',
      dataIndex: 'students',
      key: 'students',
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus,
    },
    {
      key: 'id',
      render: renderActionButtons,
    },
  ]);
};
