import React from 'react';
import classNames from 'classnames';

const pointsScoredValue = (value) => (Number.isInteger(value) ? (<p>{value * 10}</p>) : (<p>-</p>));

// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    title: 'Content',
    dataIndex: 'content',
    key: 'content',
  },
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
  },
  {
    title: 'Points Scored',
    key: 'pointsScored',
    render: (item) => pointsScoredValue(item.pointsScored),
  },
  {
    title: 'Course Week',
    dataIndex: 'courseWeek',
    key: 'courseWeek',
  },
  {
    title: 'Status',
    key: 'status',
    render: (item) => (
      <div className={classNames({
        'status-wrapper': true,
        'status-wrapper-active': item.status === 'Completed',
        'status-wrapper-inprogress': item.status === 'Pending',
      })}
      >
        <p>{item?.status}</p>
      </div>
    ),
  },
];
