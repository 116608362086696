import { fromJS, List as IList, Map as IMap } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  courseBatches: IList(),
  coursesForFilter: IList(),
  selectedCourseForFilter: '',
  courseOverview: IMap(),
  maxCourseWeeks: [],
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_COURSE_BATCHES:
      return state.set('courseBatches', IList(action.payload.courseBatches));
    case types.SET_COURSE_FILTER:
      return state.set('coursesForFilter', IList(action.payload.coursesForFilter));
    case types.COURSE_DELETED:
      return state.set('courses', state.get('courses', IList()).filter((course) => course.get('id', '') !== action.payload.id));
    case types.SET_SELECTED_COURSE_FOR_FILTER:
      return state.set('selectedCourseForFilter', action.payload.selectedCourseForFilter);
    case types.SET_COURSE_OVERVIEW:
      return state.set('courseOverview', IMap(action.payload.courseOverview));
    case types.SET_MAX_COURSE_WEEK:
      return state.set('maxCourseWeeks', action.payload.data);
    default:
      return state;
  }
};
