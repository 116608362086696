/* eslint-disable no-mixed-operators */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useEffect } from 'react';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  PieChart, Pie, ResponsiveContainer, Legend, Cell, Label, Tooltip,
} from 'recharts';
import CustomTooltipSingleValue from './CustomTooltipSingleValue';
import { analyticsColor, installationsLegend, timeRangeFilterComponents } from './options';
import { platformInstallationsSelector } from '../modules/selectors';
import { getPlatformInstallations } from '../modules/actions';
import '../styles.scss';
import TimeRangeFilter from './TimeRangeFilter';

const RegisteredUsers = ({
  platformInstallations,
  fetchPlatformInstallations,
  timeRangeFilter,
}) => {
  useEffect(() => {
    fetchPlatformInstallations({ dateFilter: timeRangeFilter });
  }, [timeRangeFilter]);

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx, cy, midAngle, innerRadius, outerRadius, percent,
  }) => {
    if (percent < 0.1) {
      return null;
    }
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;

    return (
      <text
        x={cx + radius * Math.cos(-midAngle * RADIAN)}
        y={cy + radius * Math.sin(-midAngle * RADIAN)}
        fill="#ffffff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    );
  };

  return (
    <div className="installs-wrapper">
      <div className="analytics-header-wrapper">
        <span className="analytics-title">Registered</span>
        <TimeRangeFilter
          timeRangeFilter={timeRangeFilterComponents.installs}
          filterKey={timeRangeFilter}
        />
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart width={500} height={500}>
          <Pie
            data={platformInstallations?.data}
            innerRadius={45}
            label={renderCustomizedLabel}
            cx="50%"
            cy="50%"
            labelLine={false}
          >
            <Label className="total-number" value={platformInstallations?.registeredUsers} position="center" fill="black" />
            <Cell fill={analyticsColor.webColor} />
            <Cell fill={analyticsColor.androidColor} />
            <Cell fill={analyticsColor.iosColor} />
          </Pie>
          <Tooltip content={<CustomTooltipSingleValue />} />
          <Legend payload={
                      installationsLegend
                    }
          />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

RegisteredUsers.propTypes = {
  platformInstallations: ImmutablePropTypes.list.isRequired,
  fetchPlatformInstallations: PropTypes.func.isRequired,
  timeRangeFilter: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  platformInstallations: platformInstallationsSelector(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchPlatformInstallations: (params) => dispatch(getPlatformInstallations(params)),
});

export default connect(mapStateToProps, mapDispatchToProps)(RegisteredUsers);
