export const types = {
  LOGIN: 'LOGIN',
  SET_IS_LOADING: 'SET_IS_LOADING',
  GET_CURRENT_USER: 'GET_CURRENT_USER',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  LOGOUT_USER: 'LOGOUT_USER',
};

export const login = (email, password, remember) => ({
  type: types.LOGIN,
  payload: {
    email,
    password,
    remember,
  },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const getCurrentUser = (id) => ({
  type: types.GET_CURRENT_USER,
  payload: { id },
});

export const setCurrentUser = (user) => ({
  type: types.SET_CURRENT_USER,
  payload: { user },
});

export const logoutUser = () => ({
  type: types.LOGOUT_USER,
});
