import React, { useEffect } from 'react';
import {
  Tabs, Button, Input, Select,
} from 'antd';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';
import ShowNoOfEntries from 'components/ShowNoOfEntries';
import DashboardTable from './components/DashboardTable';
import StudentsTable from './components/StudentsTable';

import './dashboard.scss';

const { TabPane } = Tabs;
const { Option } = Select;

const Dashboard = (props) => {
  const {
    t, actions, activeTab, pagination,
    sortOrder, titleFilter, isLoading,
    tableData, studentsData, studentsTable, monthFilter, numOfRows, offset,
  } = props;

  const handleMonthChange = (value) => {
    actions.setMonthFilter(value);
  };

  useEffect(() => {
    if (!tableData || tableData.size < 1) {
      actions.loadData(
        activeTab,
        pagination.toJS(),
        titleFilter,
        sortOrder,
        numOfRows,
        monthFilter,
        offset,
      );
    }
  }, [tableData]);

  useEffect(() => {
    actions.loadData(
      activeTab,
      pagination.toJS(),
      titleFilter,
      sortOrder,
      numOfRows,
      monthFilter,
      offset,
    );
  }, [numOfRows, offset, monthFilter]);

  const handleTableFilter = (filter = titleFilter) => {
    actions.loadData(
      activeTab,
      pagination.toJS(),
      filter,
      sortOrder,
      numOfRows,
      monthFilter,
      offset,
    );
  };

  const resetTableFilter = () => {
    actions.setTitleFilter('');
    actions.setMonthFilter(null);
    handleTableFilter('');
  };

  const parentSetNumOfRows = (item) => {
    actions.setNumOfRows(item);
  };

  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  const filterTable = (
    <div className="title-filter">
      <ShowNoOfEntries parentSetNumOfRows={parentSetNumOfRows} />
      <Select onChange={handleMonthChange} defaultValue="Month" value={monthFilter} placeholder="Month Filter">
        {months?.map((item) => (
          <Option key={item} value={item}>{item}</Option>
        ))}
      </Select>
      <Input
        value={titleFilter}
        onChange={(e) => actions.setTitleFilter(e.target.value)}
        onPressEnter={() => handleTableFilter()}
        placeholder="Search by course title..."
      />
      <Button
        type="primary"
        onClick={() => handleTableFilter()}
      >
        {t('dashboard filterActivitiesButton text')}
      </Button>
      <Button
        onClick={resetTableFilter}
      >
        {t('dashboard resetFilterButton text')}
      </Button>
    </div>
  );

  const tableQuizColumns = [
    {
      title: t('dashboard courseTitleColumn'),
      dataIndex: 'courseTitle',
    },
    {
      title: t('dashboard batchColumn'),
      dataIndex: 'batchMonth',
    },
    {
      title: t('quizzesTable quizTitleColumn'),
      dataIndex: 'quizTitle',
    },
    {
      title: t('dashboard studentsColumn'),
      dataIndex: 'noOfStudents',
    },
    {
      title: t('dashboard attendeesColumn'),
      dataIndex: 'finished',
    },
    {
      title: t('dashboard viewStudentsColumn'),
      key: 'action',
      render: (item) => (
        <Button onClick={() => {
          actions.getStudentsData(
            item.courseId,
            item.lessonId,
            item.quizId,
            activeTab,
            item.firstDay,
            item.lastDay,
          );
          actions.setStudentsTableVisible(true);
        }}
        >
          View
        </Button>
      ),
    },
  ];

  const tableLessonColumns = [
    {
      title: t('dashboard courseTitleColumn'),
      dataIndex: 'courseTitle',
      key: 'courseTitle',
    },
    {
      title: t('dashboard batchColumn'),
      dataIndex: 'batchMonth',
      key: 'batchMonth',
    },
    {
      title: t('lessonsTable lessonTitleColumn'),
      dataIndex: 'lessonTitle',
      key: 'lessonTitle',
    },
    {
      title: t('dashboard studentsColumn'),
      dataIndex: 'noOfStudents',
      key: 'noOfStudents',
    },
    {
      title: t('dashboard attendeesColumn'),
      dataIndex: 'watched',
      key: 'watched',
    },
    {
      title: t('dashboard viewStudentsColumn'),
      key: 'action',
      render: (item) => (
        <Button onClick={() => {
          actions.getStudentsData(
            item.courseId,
            item.lessonId,
            item.quizId,
            activeTab,
            item.firstDay,
            item.lastDay,
          );
          actions.setStudentsTableVisible(true);
        }}
        >
          View
        </Button>
      ),
    },
  ];

  const tableStudentsDataQuizTab = [
    {
      title: t('dashboard studentsTableNameColumn'),
      dataIndex: 'name',
    },
    {
      title: t('dashboard studentsTableAgeColumn'),
      dataIndex: 'age',
    },
    {
      title: t('Points Collected'),
      dataIndex: 'noOfPoints',
    },
    {
      title: t('dashboard studentsTableGenderColumn'),
      dataIndex: 'gender',
    },
  ];

  const tableStudentsDataLessonTab = [
    {
      title: t('dashboard studentsTableNameColumn'),
      dataIndex: 'name',
    },
    {
      title: t('dashboard studentsTableAgeColumn'),
      dataIndex: 'age',
    },
    {
      title: t('Watched'),
      dataIndex: 'watched',
    },
    {
      title: t('dashboard studentsTableGenderColumn'),
      dataIndex: 'gender',
    },
  ];

  const onTabChange = (tab) => {
    actions.setActiveTab(tab);
    actions.setCurrentPage(1);
    actions.setTitleFilter('');
    actions.setSortOrder('descend');
    actions.loadData(tab, { ...pagination, current: 1 }, '', 'descend');
  };
  const onStudentsTabChange = () => {
    actions.setStudentsTableVisible(false);
  };
  const handleTableChange = (newPagination, _, sorter) => {
    if (pagination.current !== newPagination.current) {
      actions.setCurrentPage(newPagination.current);
      actions.setOffset((newPagination.current * numOfRows) - numOfRows);
    }
    if (sortOrder !== sorter.order) actions.setSortOrder(sorter.order);
    actions.loadData(
      activeTab,
      newPagination,
      titleFilter,
      sorter.order,
      numOfRows,
      monthFilter,
      offset,
    );
  };

  const studentsTableData = studentsData;
  const studentsTablesData = studentsTableData.map((item) => {
    const studentsItem = {
      name: item?.name,
      age: item?.age,
      noOfPoints: item?.points,
      watched:item?.watched,
      gender: item?.gender,
    };
    return studentsItem;
  });

  return (
    <div className="dashboard">
      {studentsTable
        ? (
          <Tabs type="card" onChange={onStudentsTabChange}>
            <TabPane key="3" tab="Students">
              <StudentsTable
                activeTab={activeTab}
                columns={activeTab === '2' ? tableStudentsDataQuizTab : tableStudentsDataLessonTab}
                loading={isLoading}
                data={studentsTablesData}
                closeStudentsTable={actions.setStudentsTableVisible}
              />
            </TabPane>
          </Tabs>
        )
        : (
          <Tabs activeKey={activeTab} type="card" onChange={onTabChange}>
            <TabPane key="1" tab="Lessons">
              <DashboardTable
                columns={tableLessonColumns}
                loading={isLoading}
                pagination={pagination.toJS()}
                data={tableData}
                onChange={handleTableChange}
                filterTable={filterTable}
                numOfRows={numOfRows}
                offset={offset}
              />
            </TabPane>
            <TabPane key="2" tab="Quizzes">
              <DashboardTable
                columns={tableQuizColumns}
                loading={isLoading}
                pagination={pagination.toJS()}
                data={tableData}
                onChange={handleTableChange}
                filterTable={filterTable}
                numOfRows={numOfRows}
                offset={offset}
              />
            </TabPane>
          </Tabs>
        )}

    </div>
  );
};

Dashboard.propTypes = {
  t: PropTypes.func.isRequired,
  actions: PropTypes.shape({
    setActiveTab: PropTypes.func,
    loadData: PropTypes.func,
    setSortOrder: PropTypes.func,
    setCurrentPage: PropTypes.func,
    setTitleFilter: PropTypes.func,
    getStudentsData: PropTypes.func,
    setStudentsTableVisible: PropTypes.func,
    setMonthFilter: PropTypes.func,
    setNumOfRows: PropTypes.func,
    setOffset: PropTypes.func,
  }).isRequired,
  activeTab: PropTypes.string.isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    toJS: PropTypes.func,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  sortOrder: PropTypes.string.isRequired,
  titleFilter: PropTypes.string.isRequired,
  monthFilter: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  tableData: ImmutablePropTypes.list.isRequired,
  studentsData: ImmutablePropTypes.list.isRequired,
  studentsTable: PropTypes.bool.isRequired,
  numOfRows: PropTypes.number.isRequired,
  offset: PropTypes.number.isRequired,
};

export default withTranslation()(Dashboard);
