import { authorizedApiService } from './apiService';

const BASE_PATH = '/skills';

export default {
  getSkills: async () => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
