import React, { useEffect, useMemo, useState } from 'react';

import { withTranslation } from 'react-i18next';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import {
  Form, Select, Input, InputNumber,
} from 'antd';
import moment from 'moment';
import '../createLesson.scss';
import './basicInfo.scss';
import {
  shortDateTimeFormat,
} from 'utils/constants';
import {
  getPopupContainer,
  lessonCourseRules, lessonDescriptionRules, lessonTitleRules, lessonWeekRules,
} from 'utils/inputRules';

const { Option } = Select;
const { TextArea } = Input;

export const getStartDisabledHours = (disabledTimes, currentDateString) => {
  const disabledHours = [];
  const repetitions = {};
  for (let i = 0; i < 24; i++) {
    disabledTimes.forEach((disabledTime) => {
      if (disabledTime.hour() === i) {
        if (repetitions[i]) {
          repetitions[i] += 1;
        } else {
          repetitions[i] = 1;
        }
      }
    });
    if (currentDateString) {
      const currentDate = moment(
        `${currentDateString} ${i}:45`,
        shortDateTimeFormat,
      );
      if (currentDate.isBefore(moment())) {
        disabledHours.push(i);
      }
    }
  }
  const entries = Object.entries(repetitions);
  entries.forEach(([key, value]) => {
    if (value === 4) disabledHours.push(Number(key));
  });
  return disabledHours;
};

export const getStartDisabledMinutes = (
  hour,
  currentDateString,
  disabledStartTimes,
) => {
  const disabledMinutes = [];
  for (let i = 0; i < 60; i += 15) {
    disabledStartTimes.forEach((disabledTime) => {
      if (disabledTime.hour() === hour && disabledTime.minute() === i) {
        disabledMinutes.push(i);
      }
    });
    if (currentDateString) {
      const currentDate = moment(
        `${currentDateString} ${hour}:${i}`,
        shortDateTimeFormat,
      );
      if (currentDate.isBefore(moment())) {
        disabledMinutes.push(i);
      }
    }
  }
  return disabledMinutes;
};

export const getEndDisabledHours = (
  currentDateString,
  from,
  dateValue,
  fromValue,
  lessons,
  lesson,
) => {
  const disabledHours = [];
  if (dateValue && fromValue) {
    const hourLimit = from.minute() === 45 ? from.hour() + 1 : from.hour();
    for (let i = 0; i < hourLimit; i++) {
      disabledHours.push(i);
    }
    for (let i = from.hour(); i < 24; i++) {
      const currentDate = moment(
        `${currentDateString} ${i}:${0}`,
        shortDateTimeFormat,
      );
      lessons.forEach((l) => {
        if (
          (!lesson || (lesson && lesson.get('id') !== l.id))
          && from.isBefore(moment(l.to))
          && currentDate.isAfter(l.from)
        ) {
          disabledHours.push(i);
        }
      });
    }
  }
  return disabledHours;
};

export const getEndDisabledMinutes = (
  hour,
  currentDateString,
  from,
  dateValue,
  fromValue,
  lessons,
  lesson,
) => {
  const disabledMinutes = [];
  if (dateValue && fromValue) {
    for (let i = 0; i < 60; i += 15) {
      const currentDate = moment(
        `${currentDateString} ${hour}:${i}`,
        shortDateTimeFormat,
      );
      if (from.isSameOrAfter(currentDate)) {
        disabledMinutes.push(i);
      }
      lessons.forEach((l) => {
        if (
          (!lesson || (lesson && lesson.get('id') !== l.id))
          && from.isBefore(moment(l.to))
          && moment(currentDate).isAfter(moment(l.from))
        ) {
          disabledMinutes.push(i);
        }
      });
    }
  }
  return disabledMinutes;
};

const BasicInfo = (props) => {
  const {
    t,
    isCurrentStep,
    lesson,
    courses,
  } = props;
  const [courseTotalWeeks, setCourseTotalWeeks] = useState(null);
  const onChangeCourse = (_value, option) => {
    const tempSelectedCourse = courses.toJS().filter((course) => course.value === option.value);
    setCourseTotalWeeks(tempSelectedCourse[0].totalWeeks);
  };

  useEffect(() => {
    if (lesson) {
      setCourseTotalWeeks(lesson.getIn(['course', 'totalWeeks'], null));
    }
  }, [lesson]);

  const coursesMapping = useMemo(() => (
    courses?.map((course) => (
      <Option className="create-lesson-input" key={course.key} value={course.value}>
        {course.name}
      </Option>
    ))), [courses]);

  return isCurrentStep ? (
    <div>
      <span className="section-labels">BASIC INFORMATION</span>
      <div className="create-lesson-form-divider">{ }</div>
      <div className="create-lesson-wrapper">
        <div className="create-lesson-form-fields-wrapper">
          <span className="create-lesson-input-label">{t('createLesson contentAndChapters title label')}</span>
          <Form.Item
            name="title"
            rules={lessonTitleRules}
          >
            <Input className="create-lesson-input" placeholder={t('createLesson contentAndChapters title placeholder')} maxLength={40} />
          </Form.Item>
          <span className="create-lesson-input-label">{t('createLesson contentAndChapters description label')}</span>
          <Form.Item
            name="description"
            rules={lessonDescriptionRules}
          >
            <TextArea
              size="medium"
              rows={5}
              maxLength={1000}
              placeholder={t('createLesson contentAndChapters description placeholder')}
              autoSize={{ minRows: 5, maxRows: 5 }}
            />
          </Form.Item>
        </div>

        <div className="create-lesson-form-fields-wrapper">
          <span className="create-lesson-input-label">{t('createLesson basicInfo course label')}</span>

          <Form.Item
            name="course"
            rules={lessonCourseRules}
          >
            <Select
              placeholder={t('createLesson basicInfo course placeholder')}
              onChange={onChangeCourse}
              className="create-lesson-input"
              getPopupContainer={getPopupContainer}
              disabled={lesson?.size}
            >
              {coursesMapping}
            </Select>
          </Form.Item>
          <span className="create-lesson-input-label">{t('createLesson contentAndChapters week label')}</span>
          <Form.Item
            name="week"
            rules={lessonWeekRules(courseTotalWeeks)}
            className="title-field"
          >
            <InputNumber
              className="create-lesson-input"
              disabled={!courseTotalWeeks}
              placeholder={t('createLesson contentAndChapters week placeholder')}
              size="medium"
            />
          </Form.Item>
          <span className="create-lesson-input-label">
            Order
            <span className="input-label-helper-text">(Please enter numbers only)</span>
          </span>
          <Form.Item
            name="order"
            className="title-field"
          >
            <InputNumber
              className="create-lesson-input"
              placeholder="Order"
              size="medium"
            />
          </Form.Item>
        </div>
      </div>
      <span className="section-labels">UPLOAD CONTENT</span>
      <div className="create-lesson-form-divider">{ }</div>
    </div>
  ) : (
    ''
  );
};

BasicInfo.propTypes = {
  t: PropTypes.func.isRequired,
  courses: ImmutablePropTypes.list.isRequired,
  isCurrentStep: PropTypes.bool.isRequired,
  lesson: ImmutablePropTypes.map,
};

BasicInfo.defaultProps = {
  lesson: null,
};

export default withTranslation()(BasicInfo);
