import { fromJS, List as IList } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  quizzes: IList(),
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_QUIZZES:
      return state.set('quizzes', fromJS(action.payload.quizzes));
    case types.QUIZ_DELETED:
      return state.set('quizzes', state.get('quizzes', IList()).filter((quiz) => quiz.get('id', '') !== action.payload.id));
    default:
      return state;
  }
};
