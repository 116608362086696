import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import Search from './components/Search';
import Export from './components/Export';
import Dropdown from './components/Dropdown';
import AddNew from './components/AddNew';
import '../../tables.scss';

const TableOptions = ({
  handleSearchChange,
  handleNumOfRows,
  dataSource,
  columns,
  search,
  exportData,
  showEntries,
  statusFilter,
  searchPlaceholder,
  setStatusFilter,
  rowOptions,
  addNew,
  addNewComponent,
  showModal,
  visible,
  closeModal,
  itemForEdit,
  modalTitle,
  courseFilter,
  setCourseFilter,
  courseOptions,
  weekFilter,
  setWeekFilter,
  weekOptions,
  hideNumberOfEntries,
  paymentPlanOption,
  setPaymentPlan,
  paymentStatusFilter,
  resetPaginationData,
  dropDownMinWidth,
}) => (
  <div className="table-options-wrapper">
    {search && (
      <Search handleSearchChange={handleSearchChange} searchPlaceholder={searchPlaceholder} />
    )}
    {addNew && (
      <AddNew
        Component={addNewComponent}
        showModal={showModal}
        visible={visible}
        closeModal={closeModal}
        itemForEdit={itemForEdit}
        modalTitle={modalTitle}
      />
    )}
    {exportData && <Export data={dataSource} columns={columns} />}
    {showEntries && !hideNumberOfEntries && (
      <Dropdown
        dropdownOnChangeHandler={handleNumOfRows}
        dropdownTitle="Show Entries"
        rowOptions={[20, 50]}
        resetPaginationData={resetPaginationData}
      />
    )}
    {statusFilter && (
      <Dropdown
        dropdownOnChangeHandler={setStatusFilter}
        dropdownTitle="Status"
        rowOptions={rowOptions}
        resetPaginationData={resetPaginationData}
      />
    )}
    {courseFilter && (
      <Dropdown
        dropDownMinWidth={dropDownMinWidth}
        dropdownOnChangeHandler={setCourseFilter}
        dropdownTitle="Course"
        rowOptions={courseOptions}
        resetPaginationData={resetPaginationData}
      />
    )}
    {weekFilter && (
      <Dropdown
        dropdownOnChangeHandler={setWeekFilter}
        dropdownTitle="Week"
        rowOptions={weekOptions}
        resetPaginationData={resetPaginationData}
      />
    )}
    {paymentStatusFilter && (
      <Dropdown
        dropdownOnChangeHandler={setPaymentPlan}
        dropdownTitle="Subscription status"
        rowOptions={paymentPlanOption}
        resetPaginationData={resetPaginationData}
        dropDownMinWidth={dropDownMinWidth}
      />
    )}
  </div>
);
TableOptions.propTypes = {
  handleSearchChange: PropTypes.func.isRequired,
  handleNumOfRows: PropTypes.func.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  dataSource: ImmutablePropTypes.list.isRequired,
  columns: ImmutablePropTypes.list.isRequired,
  rowOptions: ImmutablePropTypes.list.isRequired,
  search: PropTypes.bool,
  exportData: PropTypes.bool,
  showEntries: PropTypes.bool,
  statusFilter: PropTypes.bool,
  searchPlaceholder: PropTypes.string,
  addNew: PropTypes.string.isRequired,
  addNewComponent: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  itemForEdit: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  courseFilter: PropTypes.bool,
  setCourseFilter: PropTypes.func.isRequired,
  courseOptions: ImmutablePropTypes.list.isRequired,
  weekFilter: PropTypes.bool,
  setWeekFilter: PropTypes.func.isRequired,
  weekOptions: ImmutablePropTypes.list.isRequired,
  hideNumberOfEntries: PropTypes.bool.isRequired,
  paymentPlanOption: PropTypes.arrayOf.isRequired,
  setPaymentPlan: PropTypes.func.isRequired,
  paymentStatusFilter: PropTypes.bool.isRequired,
  resetPaginationData: PropTypes.func.isRequired,
  dropDownMinWidth: PropTypes.bool,
};

TableOptions.defaultProps = {
  search: false,
  exportData: false,
  showEntries: false,
  statusFilter: false,
  courseFilter: false,
  dropDownMinWidth: false,
  weekFilter: false,
  searchPlaceholder: 'Search...',
};

export default TableOptions;
