/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import '../styles.scss';

const UserStats = ({
  label,
  value,
  color,
}) => (
  <div className="user-stats-wrapper">
    <span className="user-stats-label">{label}</span>
    <div style={{ backgroundColor: color, width: `${value * 10}px` }} className="user-stats-area">
      {value}
    </div>
  </div>
);
UserStats.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
};
export default UserStats;
