import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { searchArray } from 'utils/helpers';
import Tables from 'components/Tables';
import CreateCourse from 'components/CreateCourse';
import CautionModal from 'components/Tables/components/CautionModal';
import { getColumns } from './components/columns';

import './manageCourses.scss';

const ManageCourses = (props) => {
  const {
    actions, courses, pagination, showEntries, user,
  } = props;
  const [searchVal, setSearchVal] = useState('');
  const [visible, setVisible] = useState(false);
  const [cautionModalVisible, setCautionModalVisible] = useState(false);
  const [courseForEdit, setCourseForEdit] = useState(null);
  const [courseForDelete, setCourseForDelete] = useState(null);

  const showModal = () => {
    setVisible(true);
  };
  const editCourse = (item) => {
    setCourseForEdit(item);
    setVisible(true);
  };
  const closeModal = () => {
    setCourseForEdit(null);
    setVisible(false);
    setCautionModalVisible(false);
  };

  const deleteCourse = () => {
    actions.deleteCourse(courseForDelete, { ...pagination.toJS(), searchVal, showEntries });
    setCautionModalVisible(false);
  };

  const cautionModalVisibleOn = (item) => {
    setCautionModalVisible(true);
    setCourseForDelete(item);
  };

  useEffect(() => {
    actions.getCourses({ ...pagination.toJS(), searchVal, showEntries });
    actions.resetOverviewTableData();
  }, [searchVal, pagination]);

  const coursesData = courses?.toJS();

  const coursesTableData = searchArray(coursesData, 'title', searchVal).map((item) => {
    const coursesItem = {
      id: item?.id,
      title: item?.title,
      premium: !item?.free,
      status: item?.isActive ? 'Active' : 'Inactive',
      students: item?.courseAttendees?.length,
      courseAttendees: item?.courseAttendees,
    };
    return coursesItem;
  });

  const columns = getColumns(editCourse, cautionModalVisibleOn, user);

  return (
    <div>
      <Tables
        dataSource={coursesTableData}
        columns={columns}
        exportData
        showEntries
        tableTitle="Courses"
        addNew
        addNewComponent={CreateCourse}
        showModal={showModal}
        closeModal={closeModal}
        visible={visible}
        itemForEdit={courseForEdit?.id}
        modalTitle={courseForEdit ? `Edit ${courseForEdit?.title}` : 'Add new course'}
        pathname="/course-overview"
        setSearchVal={setSearchVal}
        search
        searchPlaceholder="Search By Course"
      />
      <CautionModal
        visible={cautionModalVisible}
        cautionDescription="Are you sure you want to delete this course?"
        closeModal={closeModal}
        onYes={deleteCourse}
      />
    </div>
  );
};

ManageCourses.propTypes = {
  actions: PropTypes.shape({
    getCourses: PropTypes.func.isRequired,
    getCourseBatches: PropTypes.func.isRequired,
    deleteCourse: PropTypes.func.isRequired,
    getCourse: PropTypes.func,
    resetOverviewTableData: PropTypes.func,
  }).isRequired,
  user: PropTypes.shape({
    get: PropTypes.func.isRequired,
    size: PropTypes.number.isRequired,
  }).isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  courses: ImmutablePropTypes.list.isRequired,
  showEntries: PropTypes.string.isRequired,
};

export default withTranslation()(ManageCourses);
