export const types = {
  LOAD_USER: 'LOAD_USER',
  LOAD_USER_ACTIVITIES: 'LOAD_USER_ACTIVITIES',
  SET_CURRENT_STUDENT: 'SET_CURRENT_STUDENT',
  SET_USER_ACTIVITIES: 'SET_USER_ACTIVITIES',
  SET_IS_LOADING_STUDENT: 'SET_IS_LOADING_STUDENT',
  SET_IS_LOADING_TABLE: 'SET_IS_LOADING_TABLE',
  SET_CURRENT_PAGE_STUDENT_PROFILE: 'SET_CURRENT_PAGE_STUDENT_PROFILE',
  SET_ACTIVE_TAB_STUDENT_PROFILE: 'SET_ACTIVE_TAB_STUDENT_PROFILE',
  SET_TOTAL_COUNT_STUDENT_PROFILE: 'SET_TOTAL_COUNT_STUDENT_PROFILE',
};

export const loadUser = (id) => ({
  type: types.LOAD_USER,
  payload: { id },
});

export const setCurrentStudent = (data) => ({
  type: types.SET_CURRENT_STUDENT,
  payload: { data },
});

export const loadUserActivities = (id, activeTab, pagination) => ({
  type: types.LOAD_USER_ACTIVITIES,
  payload: {
    id, activeTab, pagination,
  },
});

export const setUserActivities = (data) => ({
  type: types.SET_USER_ACTIVITIES,
  payload: { data },
});

export const setIsLoadingStudent = (isLoading) => ({
  type: types.SET_IS_LOADING_STUDENT,
  payload: { isLoading },
});

export const setIsLoadingTable = (isLoading) => ({
  type: types.SET_IS_LOADING_TABLE,
  payload: { isLoading },
});

export const setCurrentPage = (currentPage) => ({
  type: types.SET_CURRENT_PAGE_STUDENT_PROFILE,
  payload: { currentPage },
});

export const setActiveTab = (activeTab) => ({
  type: types.SET_ACTIVE_TAB_STUDENT_PROFILE,
  payload: { activeTab },
});

export const setTotalCount = (totalCount) => ({
  type: types.SET_TOTAL_COUNT_STUDENT_PROFILE,
  payload: { totalCount },
});
