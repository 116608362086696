import { fromJS, Map as IMap, List as IList } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  currentStep: 0,
  createdQuiz: IMap(),
  quiz: IMap(),
  isLoading: false,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_CURRENT_STEP:
      return state.set('currentStep', action.payload.step);
    case types.SET_CREATED_QUIZ:
      return state.set('createdQuiz', fromJS(action.payload.quiz));
    case types.SET_COURSES:
      return state.set('courses', IList(action.payload.courses));
    case types.SET_QUIZ:
      return state.set('quiz', fromJS(action.payload.quiz));
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    case types.RESTART_QUIZ_DATA:
      return state.set('quiz', IMap());
    default:
      return state;
  }
};
