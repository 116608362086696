import React, { useMemo, useState } from 'react';
import { Select, Button } from 'antd';
import ImmutablePropTypes from 'react-immutable-proptypes';
import PropTypes from 'prop-types';
import CopyIcon from '../../../images/copy-icon.svg';
import { ReactComponent as CheckMark } from '../../../images/checkmark.svg';

const GenerateCoupon = ({
  createCoupon,
  couponData,
  userId,
}) => {
  const { Option } = Select;
  const menuProps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const [month, setMonth] = useState(1);
  const [copied, setCopied] = useState(false);

  const menuPropsMapping = useMemo(() => menuProps.map((item) => (
    <Option key={item} value={item}>
      {`${item} Month`}
    </Option>
  )), [menuProps]);

  const handleSubmit = (id) => () => {
    createCoupon({ userId: id, creatorId: id, durability: month });
    setCopied(false);
  };
  const copyToClipboard = (data) => () => {
    navigator.clipboard.writeText(data);
    setCopied(true);
  };
  const monthSelectHandler = (item) => {
    setMonth(item);
  };
  const firstPartOfCode = couponData?.get('code')?.toString()?.slice(0, 3);
  const secondPartOfCode = couponData?.get('code')?.toString()?.slice(3);

  const copyButton = (
    <Button onClick={copyToClipboard(couponData.toJS().code)}>
      {copied ? (
        <div className="wrapper">
          <CheckMark />
        </div>
      ) : <img src={CopyIcon} alt="copy-icon" />}
      {copied ? 'Copied' : 'Copy to Clipboard'}
    </Button>
  );

  return (
    <div className="generate-coupon-wrapper">
      <span className="generate-coupon-label">Duration</span>
      <Select onChange={monthSelectHandler} className="generate-coupon-dropdown" defaultValue={menuProps[0]}>
        {menuPropsMapping}
      </Select>
      <Button onClick={handleSubmit(userId)} className="generate-coupon-button">Generate</Button>
      {couponData.get('code')
        && (
          <div className="copy-to-clipboard-wrapper">
            <div className="code-wrapper">
              <span className="coupon-code-value">{firstPartOfCode}</span>
              <span className="coupon-code-value">{secondPartOfCode}</span>
            </div>
            {copyButton}
          </div>
        )}
    </div>
  );
};

GenerateCoupon.propTypes = {
  couponData: ImmutablePropTypes.map.isRequired,
  createCoupon: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default GenerateCoupon;
