import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getOverviewTableData } from 'utils/overviewTableDataHelper';
import { nameLength, searchArray } from 'utils/helpers';
import Tables from '../Tables';
import { columns } from './components/columns';

const LessonOverview = ({ actions, overviewTableData }) => {
  const singleLessonOverviewData = getOverviewTableData(overviewTableData, '/lesson-overview', '/schedule');
  const totalPagination = singleLessonOverviewData?.courseAttendees?.length;
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    actions.setPaginationTotal(totalPagination);
  }, [totalPagination]);

  const formatName = (item) => nameLength(`${item?.firstName} ${item?.lastName}`, 25);

  const isWatchedCondition = (item) => singleLessonOverviewData
    .lessonWatched?.some((element) => element.userId === item.userId);

  const lessonTableData = searchArray(singleLessonOverviewData?.courseAttendees, 'users.firstName', searchVal)?.map((item) => {
    const lessonItem = {
      childName: formatName(item?.users),
      enrollDate: moment(item?.from).format('DD/MM/YYYY'),
      status: isWatchedCondition(item) ? 'Completed' : 'Pending',
    };
    return lessonItem;
  });

  return (
    <Tables
      dataSource={lessonTableData}
      columns={columns}
      exportData
      showEntries
      tableTitle={singleLessonOverviewData?.title}
      backButton
      setSearchVal={setSearchVal}
      search
      searchPlaceholder="Search By Name"
    />
  );
};
LessonOverview.propTypes = {
  actions: PropTypes.shape({
    setPaginationTotal: PropTypes.func.isRequired,
  }).isRequired,
  overviewTableData: PropTypes.objectOf().isRequired,
};
export default LessonOverview;
