import { Button } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import EmailSuccessIcon from '../../../images/email-success.svg';
import '../reset-password.scss';

const SuccessScreen = () => {
  const history = useHistory();
  const pushToLogin = () => {
    history.push('/login');
  };
  return (
    <div className="reset-password-background">
      <div className="reset-password-wrapper">
        <p className="reset-password-title">
          Reset Email Has Been Sent
        </p>
        <p className="reset-password-subtitle">
          Please follow the link in the email sent to you to reset your password
        </p>
        <div className="reset-password-row-wrapper">
          <img src={EmailSuccessIcon} alt="email-success-icon" />
        </div>
        <div className="reset-password-row-wrapper">
          <Button type="button" onClick={pushToLogin}>Done</Button>
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(SuccessScreen);
