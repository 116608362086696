import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { Table } from 'antd';
import itemRender from './components/itemRender';
import TableOptions from './components/TableOptions';
import './tables.scss';

// This component gives us a table and table options
// props required for the table are 'dataSource' (table data) and 'columns' (column titles)
// For table options we have options to pass
//   -handleSearchChange (function for search option),
//   -search (include search),
//   -exportData (include export),
//   -showEntries (include show entries option or number of rows for that table. 5, 10, 50, 100),
//   -statusFilter (include status filter. Active or Deleted data),
//   -searchPlaceholder ( provide placeholder for search for example 'Search by email'),
//   -setStatusFilter (function for status filter)
// depending on your needs you include or exclude some of options.

const Tables = ({
  dataSource,
  columns,
  search,
  exportData,
  showEntries,
  statusFilter,
  searchPlaceholder,
  setStatusFilter,
  isLoading,
  tableTitle,
  rowOptions,
  actions,
  pagination,
  pathname,
  addNewComponent,
  addNew,
  showModal,
  visible,
  closeModal,
  itemForEdit,
  modalTitle,
  courseFilter,
  courseOptions,
  setSearchVal,
  weekFilter,
  setWeekFilter,
  weekOptions,
  hideNumberOfEntries,
  paymentPlanOption,
  setPaymentPlan,
  paymentStatusFilter,
  dropDownMinWidth,
}) => {
  const history = useHistory();
  const handleNumOfRows = (item) => {
    if (item === 'All') {
      actions.setPaginationNoOfRows(pagination.get('total'));
    } else {
      actions.setPaginationNoOfRows(item);
    }
    actions.setShowEntries(item);
  };
  const handleTableChange = (newPagination) => {
    actions.setPaginationCurrent(newPagination.current);
    actions.setOffset((newPagination.current * newPagination.pageSize)
      - newPagination.pageSize);
  };
  useEffect(() => {
    actions.resetPagination();
  }, []);

  const handleRowClick = (record) => () => {
    if (pathname) {
      actions.setOverviewTableData(record, pathname);
      history.push(pathname);
    }
  };

  const onRow = (record) => ({
    onClick: handleRowClick(record),
  });

  const resetPaginationData = () => {
    actions.setPaginationCurrent(1);
    actions.setOffset(0);
  };

  const courseFilterHandler = (item) => {
    actions.setSelectedCourseForFilter(item);
    resetPaginationData();
  };

  const [handleSearchChange] = useDebouncedCallback(
    (e) => {
      setSearchVal(e.target.value);
      resetPaginationData();
    },
    500,
  );

  return (
    <div className="table-wrapper">
      <p className="table-title">{tableTitle}</p>
      <TableOptions
        handleSearchChange={handleSearchChange}
        handleNumOfRows={handleNumOfRows}
        dataSource={dataSource}
        columns={columns}
        search={search}
        exportData={exportData}
        showEntries={showEntries}
        statusFilter={statusFilter}
        searchPlaceholder={searchPlaceholder}
        setStatusFilter={setStatusFilter}
        rowOptions={rowOptions}
        addNew={addNew}
        addNewComponent={addNewComponent}
        showModal={showModal}
        visible={visible}
        closeModal={closeModal}
        itemForEdit={itemForEdit}
        modalTitle={modalTitle}
        courseFilter={courseFilter}
        setCourseFilter={courseFilterHandler}
        courseOptions={courseOptions}
        weekFilter={weekFilter}
        setWeekFilter={setWeekFilter}
        weekOptions={weekOptions}
        hideNumberOfEntries={hideNumberOfEntries}
        setPaymentPlan={setPaymentPlan}
        paymentPlanOption={paymentPlanOption}
        paymentStatusFilter={paymentStatusFilter}
        resetPaginationData={resetPaginationData}
        dropDownMinWidth={dropDownMinWidth}
      />
      <Table
        dataSource={dataSource}
        columns={columns}
        pagination={{
          total: pagination.get('total'),
          pageSize: pagination.get('noOfRows'),
          itemRender,
          current: pagination.get('current'),
        }}
        loading={isLoading}
        onRow={onRow}
        onChange={handleTableChange}
        locale={{ emptyText: 'No results for search' }}
      />
    </div>
  );
};
Tables.propTypes = {
  dataSource: ImmutablePropTypes.list.isRequired,
  columns: ImmutablePropTypes.list.isRequired,
  rowOptions: ImmutablePropTypes.list.isRequired,
  setSearchVal: PropTypes.func.isRequired,
  setStatusFilter: PropTypes.func.isRequired,
  search: PropTypes.bool.isRequired,
  exportData: PropTypes.bool.isRequired,
  showEntries: PropTypes.bool.isRequired,
  statusFilter: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  hideNumberOfEntries: PropTypes.bool.isRequired,
  searchPlaceholder: PropTypes.string.isRequired,
  tableTitle: PropTypes.string.isRequired,
  pagination: ImmutablePropTypes.list.isRequired,
  pathname: PropTypes.string.isRequired,
  actions: PropTypes.shape({
    setPaginationCurrent: PropTypes.func,
    setPaginationNoOfRows: PropTypes.func,
    setOffset: PropTypes.func,
    setShowEntries: PropTypes.func,
    setSelectedCourseForFilter: PropTypes.func,
    setOverviewTableData: PropTypes.func,
    resetPagination: PropTypes.func,
  }).isRequired,
  addNew: PropTypes.string.isRequired,
  addNewComponent: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  itemForEdit: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,
  courseFilter: PropTypes.bool.isRequired,
  courseOptions: ImmutablePropTypes.list.isRequired,
  weekFilter: PropTypes.bool.isRequired,
  setWeekFilter: PropTypes.func.isRequired,
  weekOptions: ImmutablePropTypes.list.isRequired,
  paymentPlanOption: PropTypes.arrayOf.isRequired,
  setPaymentPlan: PropTypes.func.isRequired,
  paymentStatusFilter: PropTypes.bool.isRequired,
  dropDownMinWidth: PropTypes.bool.isRequired,
};
export default Tables;
