import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Modal, Spin } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { LoadingOutlined } from '@ant-design/icons';
import { formatDate, nameLength } from 'utils/helpers';
import '../styles.scss';
import moment from 'moment';

const PaymentPerUserTable = ({
  data, loadMoreData, hasMoreData, getUserSubscriptionOverview,
  userSubscriptionsOverview, exportData, plan, resetData, timeRangeFilter,
}) => {
  const infiniteScrollRef = useRef(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [userName, setUserName] = useState('');

  const openOverviewModal = (item) => () => {
    getUserSubscriptionOverview({ id: item.id, plan });
    setUserName(`${item.firstName} ${item.lastName}`);
    setIsModalOpen(true);
  };

  const handleExport = () => {
    exportData({ data: userSubscriptionsOverview, label: userName });
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (infiniteScrollRef.current) {
      infiniteScrollRef.current.props.refreshFunction();
    }
  }, [timeRangeFilter]);

  const spinIcon = (
    <LoadingOutlined
      spin
    />
  );

  const rowRenderer = (
    data.map((item) => (
      <div onClick={openOverviewModal(item)} className="payment-per-user-table-row-item">
        <span className="table-row-separator">{nameLength(`${item.firstName} ${item.lastName}`, 22)}</span>
        <span className="table-row-separator">{item.platform}</span>
        <span className="table-row-separator">
          $
          {(item.totalAmount / 100) || 0}
        </span>
        <span className="table-row-separator">{moment(item?.createdAt).format('DD/MM/YYYY')}</span>
        <span className="table-row-separator">{nameLength(item.country, 22)}</span>
        <span className="table-row-separator">{item?.lastActivities && moment(item?.lastActivities).format('DD/MM/YYYY')}</span>
      </div>
    ))
  );

  const userSubscriptionsOverviewMapped = userSubscriptionsOverview.map((item) => (

    <div className="payment-per-user-table-row-item">
      <div className="payment-per-user-table-row-wrapper">

        <span className="payment-per-user-table-row-date">{formatDate(item.createdAt)}</span>
      </div>

      <div className="payment-per-user-table-row-wrapper">
        <span className="payment-per-user-table-row-amount">
          $
          {item.amount / 100}
        </span>
      </div>
      <div className="payment-per-user-table-row-wrapper">
        <span className="payment-per-user-table-row-plan">{item?.plan}</span>
      </div>
    </div>
  ));
  if (!data) return null;
  return (
    <>
      <Modal
        destroyOnClose
        maskClosable={false}
        title={userName}
        visible={isModalOpen}
        onOk={handleExport}
        onCancel={handleCancel}
        className="user-subscriptions-overview"
        okText="Export"
      >
        <div className="payment-per-user-table-header">
          <span>PAYMENT</span>
          <span className="payment-per-user-table-row-amount">AMOUNT</span>
          <span className="payment-per-user-table-row-amount">TYPE</span>
        </div>
        <div className="payment-per-user-table">
          {userSubscriptionsOverviewMapped}
        </div>
      </Modal>
      <div className="payment-per-user-table-header">
        <span className="table-row-separator">NAME</span>
        <span className="table-row-separator">DEVICE</span>
        <span className="table-row-separator">AMOUNT</span>
        <span className="table-row-separator">REGISTRATION DATE</span>
        <span className="table-row-separator">COUNTRY</span>
        <span className="table-row-separator">LAST ACTIVITY</span>

      </div>
      <InfiniteScroll
        dataLength={data.length}
        next={loadMoreData}
        refreshFunction={resetData}
        hasMore={hasMoreData}
        loader={data.length > 10 && <Spin indicator={spinIcon} />}
        height={350}
        width={350}
        ref={infiniteScrollRef}
        className={`scroll-${plan}`}
      >
        <div className="payment-per-user-table">
          {rowRenderer}
        </div>
      </InfiniteScroll>
    </>
  );
};

PaymentPerUserTable.propTypes = {
  data: PropTypes.arrayOf.isRequired,
  loadMoreData: PropTypes.func.isRequired,
  hasMoreData: PropTypes.func.isRequired,
  getUserSubscriptionOverview: PropTypes.func.isRequired,
  userSubscriptionsOverview: PropTypes.arrayOf.isRequired,
  exportData: PropTypes.func.isRequired,
  plan: PropTypes.string.isRequired,
  timeRangeFilter: PropTypes.objectOf.isRequired,
  resetData: PropTypes.func.isRequired,
};

export default PaymentPerUserTable;
