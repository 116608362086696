/* eslint-disable import/prefer-default-export */
import { createLog } from '../services/loggerService';

export const logger = async (error) => {
  const postData = {
    title: error?.message,
    app: 'Kutuby Admin',
    time: new Date(),
    description: error?.stack,
  };

  await createLog(postData);
};
