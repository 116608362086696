import { all, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import analyticsService from 'services/analyticsService';
import { exportFileExtension, exportFileType } from 'utils/constants';
import { arrayOfWeeks } from 'utils/helpers';
import { setMaxCourseWeek } from 'components/ManageCourses/modules/actions';
import {
  types,
  setSubscriptionPaymentPerUser,
  setAveragePaymentOfUsers,
  setAveragePaymentOfUsersByDevice,
  setMonthlyRevenue,
  setTrialMemberships,
  setSubscriptionMemberships,
  setPlatformInstallations,
  setActiveUsers,
  setUserSubscriptionOverview,
  setAveragePaymentCalculationData,
  setCourseMetrics,
  setLessonMetrics,
  setQuizMetrics,
  setTrialsPaymentPerUser,
  setCurrentMembershipSubscriptions,
  setCurrentMembershipTrials,
  setTypeOfUsers,
  setWauAnalytics,
} from './actions';

export function * getMonthlyRevenueSaga(action) {
  const { params } = action.payload;
  const revenue = yield analyticsService.getRevenue(params);
  const lastMonthRevenue = yield analyticsService.getLastMonthRevenue();

  if (revenue.error || revenue.lastMonthRevenue) {
    notification.error({
      message: i18n.t('notification error'),
      description: revenue.error || revenue.lastMonthRevenue,
    });
  } else {
    yield put(setMonthlyRevenue({
      revenue: revenue.data,
      lastMonthRevenue: lastMonthRevenue.data,
    }));
  }
}

export function * getCourseMetricsSaga() {
  const { data, error } = yield analyticsService.getCourseMetrics();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourseMetrics(data));
  }
}
export function * getWauAnalytics() {
  const { data, error } = yield analyticsService.getWauAnalytics();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setWauAnalytics(data));
  }
}
export function * getLessonMetricsSaga(action) {
  const { data, error } = yield analyticsService.getLessonMetrics(action.payload.data);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setLessonMetrics(data.data));
    const weeks = ['All', ...arrayOfWeeks(data.maxWeek)];
    yield put(setMaxCourseWeek(weeks));
  }
}
export function * getQuizMetricsSaga(action) {
  const { data, error } = yield analyticsService.getQuizMetrics(action.payload.data);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setQuizMetrics(data.data));
    const weeks = ['All', ...arrayOfWeeks(data.maxWeek)];
    yield put(setMaxCourseWeek(weeks));
  }
}

export function * getSubscriptionPaymentPerUserSaga(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getSubscriptionPaymentPerUserService(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setSubscriptionPaymentPerUser(data));
  }
}

export function * getTrialsPaymentPerUserSaga(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getSubscriptionPaymentPerUserService(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setTrialsPaymentPerUser(data));
  }
}

export function * getAveragePaymentOfUsersSaga(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getAveragePaymentOfUsers(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setAveragePaymentOfUsers(data.averagePaymentData));
    yield put(setAveragePaymentOfUsersByDevice(data.averagePaymentByDevices));
    yield put(setAveragePaymentCalculationData(data));
  }
}

export function * getTrialMemberships(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getMembershipsService(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setTrialMemberships(data));
  }
}

export function * getUserSubscriptionsOverviewSaga(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getUserSubscriptionsOverview(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setUserSubscriptionOverview(data));
  }
}

export function * getCountOfStatuses(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getCountOfStatuses(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setSubscriptionMemberships(data));
  }
}

export function * getPlatformInstallations(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getPlatformInstallationsService(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setPlatformInstallations(data));
  }
}
export function * getTypeOfUsers(action) {
  const { params } = action.payload;
  const { data, error } = yield analyticsService.getTypeOfUsersService(params);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setTypeOfUsers(data));
  }
}

const mergeResponses = (responses) => {
  const dateCounts = {};

  Object.keys(responses).forEach((key) => {
    responses[key].forEach((entry) => {
      const { date } = entry;
      const count = parseInt(entry.count, 10);

      if (!dateCounts[date]) {
        dateCounts[date] = {
          date,
          registered: 0,
          cancel: 0,
          subscription: 0,
          trials: 0,
        };
      }

      if (key === 'cancels') {
        dateCounts[date].cancel += count;
      } else if (key === 'subscriptions') {
        dateCounts[date].subscription += count;
      } else if (key === 'trials') {
        dateCounts[date].trials += count;
      } else if (key === 'registered') {
        dateCounts[date].registered += count;
      }
    });
  });

  const mergedResponse = Object.values(dateCounts);
  return mergedResponse;
};

export function * getActiveUsers(action) {
  const { params } = action.payload;
  const data = yield analyticsService.getActiveUsersService(params);
  if (data) {
    const mergedResponse = mergeResponses(data);
    yield put(setActiveUsers(mergedResponse));
  }
}
export function * getCurrentMembership() {
  const currentSubscriptions = yield analyticsService.getMembershipService({ plan: 'PLUS' });
  const currentTrials = yield analyticsService.getMembershipService({ plan: 'TRIAL' });

  if (currentSubscriptions.error || currentTrials.error) {
    notification.error({
      message: i18n.t('notification error'),
      description: currentSubscriptions.error || currentTrials.error,
    });
  } else {
    yield put(setCurrentMembershipSubscriptions(currentSubscriptions.data));
    yield put(setCurrentMembershipTrials(currentTrials.data));
  }
}
export function exportDataSaga(action) {
  try {
    const dataToExport = action.payload.data.data;
    const fileName = action.payload.data.label;

    const jsonToSheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = { Sheets: { data: jsonToSheet }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: exportFileType });
    FileSaver.saveAs(data, `${fileName}${exportFileExtension}`);
  } catch (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: 'Unable to export data',
    });
  }
}

export default function * analyticsSaga() {
  yield all([
    takeLatest(types.GET_MONTHLY_REVENUE, getMonthlyRevenueSaga),
    takeLatest(types.GET_USER_SUBSCRIPTIONS_OVERVIEW, getUserSubscriptionsOverviewSaga),
    takeLatest(types.GET_SUBSCRIPTIONS_PAYMENT_PER_USER, getSubscriptionPaymentPerUserSaga),
    takeLatest(types.GET_TRIALS_PAYMENT_PER_USER, getTrialsPaymentPerUserSaga),
    takeLatest(types.GET_AVERAGE_PAYMENT_PER_USER, getAveragePaymentOfUsersSaga),
    takeLatest(types.GET_TRIAL_MEMBERSHIPS, getTrialMemberships),
    takeLatest(types.GET_SUBSCRIPTION_MEMBERSHIPS, getCountOfStatuses),
    takeLatest(types.GET_PLATFORM_INSTALLATIONS, getPlatformInstallations),
    takeLatest(types.GET_ACTIVE_USERS, getActiveUsers),
    takeLatest(types.EXPORT_DATA, exportDataSaga),
    takeLatest(types.GET_COURSE_METRICS, getCourseMetricsSaga),
    takeLatest(types.GET_LESSON_METRICS, getLessonMetricsSaga),
    takeLatest(types.GET_QUIZ_METRICS, getQuizMetricsSaga),
    takeLatest(types.GET_CURRENT_MEMBERSHIP, getCurrentMembership),
    takeLatest(types.GET_TYPE_OF_USERS, getTypeOfUsers),
    takeLatest(types.GET_WAU_ANALYTICS, getWauAnalytics),
  ]);
}
