export const types = {
  CREATE_QUIZ: 'CREATE_QUIZ',
  SET_CURRENT_STEP: 'SET_CURRENT_STEP',
  SET_CREATED_QUIZ: 'SET_CREATED_QUIZ',
  GET_COURSES: 'GET_COURSES',
  SET_COURSES: 'SET_COURSES',
  GET_QUIZ: 'GET_QUIZ',
  SET_QUIZ: 'SET_QUIZ',
  UPDATE_QUIZ: 'UPDATE_QUIZ',
  SET_IS_LOADING: 'SET_IS_LOADING',
  RESTART_QUIZ_DATA: 'RESTART_QUIZ_DATA',
};

export const createQuiz = (quiz) => ({
  type: types.CREATE_QUIZ,
  payload: { quiz },
});

export const setCurrentStep = (step) => ({
  type: types.SET_CURRENT_STEP,
  payload: { step },
});

export const setCreatedQuiz = (quiz) => ({
  type: types.SET_CREATED_QUIZ,
  payload: { quiz },
});

export const getCourses = () => ({
  type: types.GET_COURSES,
});

export const setCourses = (courses) => ({
  type: types.SET_COURSES,
  payload: { courses },
});

export const getQuizById = (id) => ({
  type: types.GET_QUIZ,
  payload: { id },
});

export const setQuizById = (quiz) => ({
  type: types.SET_QUIZ,
  payload: { quiz },
});

export const updateQuiz = (id, quiz) => ({
  type: types.UPDATE_QUIZ,
  payload: { id, quiz },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const restartQuizData = (isLoading) => ({
  type: types.RESTART_QUIZ_DATA,
  payload: { isLoading },
});
