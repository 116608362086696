import React from 'react';
import classNames from 'classnames';

const renderPointsItem = (value) => (
  Number.isInteger(value.points) ? (<span>{value.points * 10}</span>) : (<span>-</span>)
);
const renderStatusItem = (item) => (
  <div className={classNames({
    'status-wrapper': true,
    'status-wrapper-active': item.status === 'Completed',
    'status-wrapper-inprogress': item.status === 'Pending',
  })}
  >
    <p>{item?.status}</p>
  </div>
);

// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    title: 'Name',
    dataIndex: 'childName',
    key: 'childName',
  },
  {
    title: 'Enrollment date',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
  },
  {
    title: 'Status',
    key: 'status',
    render: renderStatusItem,
  },
  {
    title: 'Points',
    key: 'points',
    render: renderPointsItem,
  },
];
