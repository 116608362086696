import { all, put, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import i18n from 'i18next';

import coursesService from 'services/coursesService';
import { getCourses } from 'components/CreateLesson/modules/actions';
import { setIsLoading } from 'components/Tables/modules/actions';
import {
  types,
  setCourseBatches,
  courseDeleted,
  setCourseFilter,
  setCourseOverview,
} from './actions';

export function * getCourseBatches(action) {
  const { courseId } = action.payload;
  const { data, error } = yield coursesService.getCourseBatches(courseId);

  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourseBatches(data));
  }
}

export function * getCourseForFilter() {
  const { data, error } = yield coursesService.getCoursesFilter();
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourseFilter(data));
  }
}

export function * getCourseOverview(action) {
  yield put(setIsLoading(true));
  const { id } = action.payload;
  const { data } = yield coursesService.getCourseOverview(id);
  if (data) {
    yield put(setCourseOverview(data));
  }
  yield put(setIsLoading(false));
}

function * deleteCourse(action) {
  const { id, getCourseData } = action.payload;
  const { error } = yield coursesService.deleteCourse(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    notification.success({
      message: i18n.t('notification success'),
      description: i18n.t('manageCourses deleted message'),
    });
    yield put(courseDeleted(id));
    yield put(getCourses(getCourseData));
  }
}

export default function * manageCoursesSaga() {
  yield all([
    takeLatest(types.GET_COURSE_BATCHES, getCourseBatches),
    takeLatest(types.GET_COURSE_FILTER, getCourseForFilter),
    takeLatest(types.GET_COURSE_OVERVIEW, getCourseOverview),
    takeLatest(types.DELETE_COURSE, deleteCourse),
  ]);
}
