import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { withTranslation } from 'react-i18next';

import './reset-password.scss';
import { useHistory } from 'react-router-dom';
import { resetPasswordEmailRules, resetPasswordPasswordRules, resetPasswordRepeatPasswordRules } from 'utils/inputRules';

const ResetPassword = ({
  actions, location, isLoading, t,
}) => {
  const queryParams = queryString.parse(location.search);
  const history = useHistory();

  const requestPasswordReset = (values) => {
    const { email } = values;
    actions.requestPasswordReset(email);
  };

  const resetPassword = (values) => {
    const { password } = values;
    actions.resetPassword(password, queryParams.email, queryParams.token);
    history.push('/login');
  };

  return (
    <div className="reset-password-background">
      <div className="reset-password-wrapper">
        <p className="reset-password-title">
          {queryParams.email ? t('resetPassword resetTitle') : t('resetPassword requestTitle')}
        </p>
        <p className="reset-password-subtitle">
          {queryParams.email
            ? t('resetPassword resetDescription')
            : t('resetPassword requestDescription')}
        </p>

        {!queryParams.email ? (
          <Form onFinish={requestPasswordReset}>
            <p className="reset-password-input-label">Enter Email</p>

            <Form.Item name="email" rules={resetPasswordEmailRules}>
              <Input placeholder={t('resetPassword email')} size="large" />
            </Form.Item>
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                {t('button sendEmail text')}
              </Button>
            </Form.Item>
          </Form>
        ) : (
          <Form onFinish={resetPassword}>
            <Form.Item name="password" rules={resetPasswordPasswordRules}>
              <Input.Password
                autoComplete="off"
                placeholder={t('newPassword placeholder')}
                size="large"
                prefix={<LockOutlined style={{ color: '#BFBFBF' }} />}
              />
            </Form.Item>
            <Form.Item name="confirm" rules={resetPasswordRepeatPasswordRules}>
              <Input.Password
                autoComplete="off"
                placeholder={t('repeatPassword placeholder')}
                size="large"
                prefix={<LockOutlined style={{ color: '#BFBFBF' }} />}
              />
            </Form.Item>
            <Form.Item>
              <Button loading={isLoading} type="primary" htmlType="submit">
                Change Password
              </Button>
            </Form.Item>
          </Form>
        )}
      </div>
    </div>
  );
};

ResetPassword.propTypes = {
  actions: PropTypes.shape({
    requestPasswordReset: PropTypes.func,
    resetPassword: PropTypes.func,
    setSuccessScreen: PropTypes.func,
  }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default withTranslation()(ResetPassword);
