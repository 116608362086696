import { fromJS, List as IList } from 'immutable';
import { types } from './actions';

const defaultState = fromJS({
  activeTab: '1',
  tableData: [],
  pagination: {
    current: 1,
    showSizeChanger: false,
  },
  titleFilter: '',
  sortOrder: 'descend',
  isLoading: false,
  studentsData: IList(),
  studentsTable: false,
  monthFilter: null,
  numOfRows: 5,
  offset: 0,
});

export default (state = defaultState, action) => {
  switch (action.type) {
    case types.SET_ACTIVE_TAB:
      return state.set('activeTab', action.payload.activeTab);
    case types.SET_TABLE_DATA:
      return state.set('tableData', fromJS(action.payload.data));
    case types.SET_CURRENT_PAGE:
      return state.setIn(['pagination', 'current'], action.payload.currentPage);
    case types.SET_TITLE_FILTER:
      return state.set('titleFilter', action.payload.titleFilter);
    case types.SET_SORT_ORDER:
      return state.set('sortOrder', action.payload.sortOrder);
    case types.SET_IS_LOADING:
      return state.set('isLoading', action.payload.isLoading);
    case types.SET_TOTAL_COUNT:
      return state.setIn(['pagination', 'total'], action.payload.total);
    case types.SET_STUDENTS_DATA:
      return state.set('studentsData', action.payload.studentsData);
    case types.SET_STUDENTS_TABLE_VISIBLE:
      return state.set('studentsTable', action.payload.studentsTable);
    case types.SET_MONTH_FILTER:
      return state.set('monthFilter', action.payload.monthFilter);
    case types.SET_NUM_OF_ROWS:
      return state.set('numOfRows', action.payload.numOfRows);
    case types.SET_OFFSET:
      return state.set('offset', action.payload.offset);
    default:
      return state;
  }
};
