import React from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import { withTranslation } from 'react-i18next';

import { Button, Table } from 'antd';
import RollbackOutlined from '@ant-design/icons/RollbackOutlined';

import Export from './Export';

const StudentsTable = (props) => {
  const {
    data, columns, loading,
    pagination, filterTable, closeStudentsTable, activeTab,
  } = props;

  return (
    <div>
      <div>
        <Button
          className="students-table-back-button"
          onClick={() => {
            closeStudentsTable(false);
          }}
        >

          <RollbackOutlined />
          Back
        </Button>
        <Export studentData={data} activeTab={activeTab} />
      </div>

      {filterTable}

      <Table
        loading={loading}
        columns={columns}
        pagination={pagination}
        dataSource={data}
      />
    </div>
  );
};

StudentsTable.propTypes = {
  data: ImmutablePropTypes.list.isRequired,
  columns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  pagination: PropTypes.shape({
    current: PropTypes.number,
    total: PropTypes.number,
    pageSize: PropTypes.number,
  }).isRequired,
  filterTable: PropTypes.shape().isRequired,
  loading: PropTypes.bool.isRequired,
  closeStudentsTable: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,

};

export default withTranslation()(StudentsTable);
