import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  changePassword,
  setIsLoading,
  setIsSuccess,
} from './modules/actions';
import View from './View';
import { selectIsLoading, selectIsSuccess } from './modules/selectors';

const mapStateToProps = (state) => ({
  isLoading: selectIsLoading(state),
  isSuccess: selectIsSuccess(state),
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      changePassword,
      setIsLoading,
      setIsSuccess,
    },
    dispatch,
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(View);
