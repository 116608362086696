import { authorizedApiService } from './apiService';

const BASE_PATH = '/courses';

export default {

  createCourse: async (course, courseImageData, courseImageDataLandscape) => {
    try {
      const response = await authorizedApiService.post(`${BASE_PATH}`, course);
      const courseImgData = {
        url: courseImageData.url,
        name: courseImageData.name,
        publicId: courseImageData.publicId,
        courseId: response.data.id,
      };
      const courseImgLandscapeData = {
        url: courseImageDataLandscape.url,
        name: courseImageDataLandscape.name,
        publicId: courseImageDataLandscape.publicId,
        courseId: response.data.id,
      };
      await authorizedApiService.post(`${BASE_PATH}/images`, courseImgData);
      await authorizedApiService.post(`${BASE_PATH}/images-landscape`, courseImgLandscapeData);

      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getCourses: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/all`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getCourseOverview: async (id) => {
    try {
      const response = await authorizedApiService.get(`/course-overview-admin-panel/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getCoursesFilter: async () => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/filter`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getCourseAttendeesDataAndCountByCourseId: async (courseId) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${courseId}/attendees`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getAttendeesForAdminDashboard: async (type, courseId, lessonOrQuizId, firstDay, lastDay) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${courseId}/attendees/${type}/${lessonOrQuizId}?firstDay=${firstDay}&lastDay=${lastDay}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },

  getCourseBatches: async (courseId) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${courseId}/batches`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getLessonsForDashboard: async (data) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/lessons/info?limit=${data.numOfRows}&courseTitle=${data.title}&offset=${data.offset}&month=${data.month}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getQuizzesForDashboard: async (data) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/quizzes/info?limit=${data.numOfRows}&courseTitle=${data.title}&offset=${data.offset}&month=${data.month}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  deleteCourse: async (id) => {
    try {
      const response = await authorizedApiService.delete(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getCourseById: async (id) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateCourse: async (courseId, course, courseImageData, courseImageDataLandscape) => {
    try {
      const response = await authorizedApiService.put(`${BASE_PATH}/${courseId}`, course);
      const courseImgData = {
        url: courseImageData.url,
        name: courseImageData.name,
        publicId: courseImageData.publicId,
        courseId: response.data.id,
      };
      if (courseImageData.name) {
        await authorizedApiService.post(`${BASE_PATH}/images`, courseImgData);
      }
      const courseImgLandscapeData = {
        url: courseImageDataLandscape.url,
        name: courseImageDataLandscape.name,
        publicId: courseImageDataLandscape.publicId,
        courseId: response.data.id,
      };
      if (courseImageDataLandscape.name) {
        await authorizedApiService.post(`${BASE_PATH}/images-landscape`, courseImgLandscapeData);
      }

      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
