import { all, put, takeLatest } from 'redux-saga/effects';
import {
  types,
  setIsLoading,
  setPaginationTotal,
  setPaginationNoOfRows,
  setPaginationCurrent,
  setOffset,
  setShowEntries,
} from './actions';

export function * resetPaginationSaga() {
  yield put(setIsLoading(false));
  yield put(setPaginationTotal(0));
  yield put(setPaginationNoOfRows(20));
  yield put(setPaginationCurrent(1));
  yield put(setOffset(0));
  yield put(setShowEntries(20));
}

export default function * tableSaga() {
  yield all([
    takeLatest(types.RESET_PAGINATION, resetPaginationSaga),
  ]);
}
