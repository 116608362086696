import React from 'react';
import PropTypes from 'prop-types';
import { Button, Modal } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AddNew = ({
  Component, showModal, visible, closeModal, itemForEdit, modalTitle,
}) => (
  <div className="add-new-wrapper">
    <Button className="add-new-button" onClick={showModal}>
      <PlusOutlined className="add-new-icon" />
      Add New
    </Button>

    <Modal destroyOnClose footer className="add-new-modal" maskClosable={false} title={modalTitle} visible={visible} onCancel={closeModal}>
      <Component itemForEdit={itemForEdit} editMode={!!itemForEdit} />
    </Modal>
  </div>
);

AddNew.propTypes = {
  Component: PropTypes.string.isRequired,
  showModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  itemForEdit: PropTypes.string.isRequired,
  modalTitle: PropTypes.string.isRequired,

};

export default AddNew;
