import { all, put, takeLatest } from 'redux-saga/effects';
import { Map as IMap } from 'immutable';
import { notification } from 'antd';
import i18n from 'i18next';
import coursesService from 'services/coursesService';
import { getCourses } from 'components/CreateLesson/modules/actions';
import { createCourseThumbnail } from '../../../services/courseImageService';
import {
  setCreatedCourse, setCourseImage, types, setCurrentStep, setCourse,
  setIsLoading, setCourseImageLandscape,
} from './actions';

export function * createCourse(action) {
  const { course, courseImageData, courseImageLandscapeData } = action.payload;
  const { data, error } = yield coursesService
    .createCourse(course, courseImageData, courseImageLandscapeData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([put(setCreatedCourse(data)), put(setCurrentStep(1))]);
  }
  yield put(setIsLoading(false));
  yield put(getCourses({ current: 1, noOfRows: 20, searchVal:'' }));
  yield put(setCourseImage(IMap()));
  yield put(setCourseImageLandscape(IMap()));
}

export function * createCourseImage(action) {
  const { courseImage } = action.payload;
  const { data, error } = yield createCourseThumbnail(courseImage);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourseImage(data));
  }
}
export function * createCourseImageLandscape(action) {
  const { courseImage } = action.payload;
  const { data, error } = yield createCourseThumbnail(courseImage);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourseImageLandscape(data));
  }
}

export function * getCourse(action) {
  const { id } = action.payload;
  const { data, error } = yield coursesService.getCourseById(id);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield put(setCourse(data));
  }
}

export function * updateCourse(action) {
  const {
    id, course, courseImageData, courseImageLandscapeData,
  } = action.payload;
  const { data, error } = yield coursesService
    .updateCourse(id, course, courseImageData, courseImageLandscapeData);
  if (error) {
    notification.error({
      message: i18n.t('notification error'),
      description: error,
    });
  } else {
    yield all([put(setCreatedCourse(data)), put(setCurrentStep(1))]);
    yield put(getCourses({ current: 1, noOfRows: 20, searchVal: '' }));
  }
  yield put(setIsLoading(false));
}

export default function * createCourseSaga() {
  yield all([
    takeLatest(types.CREATE_COURSE, createCourse),
    takeLatest(types.POST_COURSE_IMAGE, createCourseImage),
    takeLatest(types.POST_COURSE_IMAGE_LANDSCAPE, createCourseImageLandscape),
    takeLatest(types.GET_COURSE, getCourse),
    takeLatest(types.UPDATE_COURSE, updateCourse),
  ]);
}
