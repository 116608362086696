/* eslint-disable prefer-regex-literals */
import i18n from 'i18next';

export const courseTitleRules = [
  { whitespace: true, required: true, message: i18n.t('createCourse addTitle') },
];

export const courseDescriptionRules = [
  { whitespace: true, required: true, message: i18n.t('createCourse addDescription') },
  { max: 1000, message: 'Description length must be between 0-1000 characters.' },
];
export const courseTotalWeekRules = [
  { required: true, message: i18n.t('createCourse addTotalWeeks') },
];

export const quizTitleRules = [
  {
    required: true,
    message: i18n.t('createQuiz titleField error'),
    whitespace: true,
  },
];

export const quizCourseRules = [
  {
    required: true,
    message: i18n.t('createLesson basicInfo course requiredMessage'),
    whitespace: true,
  },
];

export const quizTypeRules = [
  {
    required: true,
    message: i18n.t('createQuiz quizType requiredMessage'),
    whitespace: true,
  },
];

export const quizQuestionTitleRules = [
  {
    required: true,
    message: i18n.t('createQuiz questionField error'),
    whitespace: true,
  },
  {
    max: 300,
    message: 'Maximum 300 characters allowed for question title',
  },
];

export const lessonTitleRules = [
  {
    required: true,
    message: i18n.t('createLesson contentAndChapters title requiredMessage'),
    whitespace: true,
  },
];
export const ageGroupFromRules = [
  ({ getFieldValue }) => ({
    validator() {
      const fieldValue = getFieldValue('fromAge');
      if (fieldValue) {
        const numberValue = parseInt(fieldValue, 10);

        if (numberValue < 5 || numberValue > 12) {
          return Promise.reject(
            new Error('Number must be between 5 and 12'),
          );
        }
      }
      return Promise.resolve();
    },
  }),
];
export const ageGroupToRules = [
  ({ getFieldValue }) => ({
    validator() {
      const fieldValue = getFieldValue('toAge');
      const ageFromValue = getFieldValue('fromAge');

      if (fieldValue) {
        const numberValue = parseInt(fieldValue, 10);
        const ageFromNumberValue = parseInt(ageFromValue, 10);

        if (ageFromNumberValue > numberValue) {
          return Promise.reject(
            new Error('From age need to be lower than To age'),
          );
        }
        if (numberValue < 5 || numberValue > 12) {
          return Promise.reject(
            new Error('Number must be between 5 and 12'),
          );
        }
      }
      return Promise.resolve();
    },
  }),
];
export const lessonDescriptionRules = [
  {
    required: true,
    message: i18n.t('createLesson contentAndChapters description requiredMessage'),
    whitespace: true,
  },
];
export const lessonCourseRules = [
  {
    required: true,
    message: i18n.t('createLesson basicInfo course requiredMessage'),
  },
];
export const lessonWeekRules = (courseTotalWeeks) => [
  { required: true, message: i18n.t('createLesson contentAndChapters week requiredMessage') },
  ({ getFieldValue }) => ({
    validator() {
      const weekValue = getFieldValue('week');

      if (weekValue < 1 || weekValue > courseTotalWeeks) {
        return Promise.reject(
          new Error(`The lesson week can be between 1 and ${courseTotalWeeks}`),
        );
      }

      return Promise.resolve();
    },
  }),
];

export const quizWeekRules = (courseTotalWeeks) => [
  { required: true, message: i18n.t('createLesson contentAndChapters week requiredMessage') },
  ({ getFieldValue }) => ({
    validator() {
      const weekValue = getFieldValue('week');

      if (weekValue < 1 || weekValue > courseTotalWeeks) {
        return Promise.reject(
          new Error(`The quiz week can be between 1 and ${courseTotalWeeks}`),
        );
      }

      return Promise.resolve();
    },
  }),
];

export const resetPasswordEmailRules = [
  { required: true, message: i18n.t('email mandatory errorMessage') },
  { type: 'email', message: i18n.t('email invalid errorMessage') },
];
export const resetPasswordPasswordRules = [
  { min: 6, message: 'The password should be at least 6 characters' },
  { required: true, message: i18n.t('password mandatory errorMessage') },
];

export const resetPasswordRepeatPasswordRules = [
  { required: true, message: i18n.t('repeatPassword mandatory errorMessage') },
  ({ getFieldValue }) => ({
    validator(_, value) {
      if (!value || getFieldValue('password') === value) {
        return Promise.resolve();
      }
      return Promise.reject(new Error(i18n.t('repeatPassword invalid errorMessage')));
    },
  }),
];

export const getPopupContainer = (trigger) => trigger.parentNode;
