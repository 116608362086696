import React from 'react';
import classNames from 'classnames';

// eslint-disable-next-line import/prefer-default-export
export const columns = [
  {
    title: 'Course',
    dataIndex: 'courseTitle',
    key: 'courseTitle',
  },
  {
    title: 'Points Scored',
    dataIndex: 'pointsCollected',
    key: 'pointsCollected',
  },
  {
    title: 'Final Grade',
    dataIndex: 'finalGrade',
    key: 'finalGrade',
  },
  {
    title: 'Enrolled date',
    dataIndex: 'enrollDate',
    key: 'enrollDate',
  },
  {
    title: 'Status',
    key: 'status',
    render: (item) => (
      <div className={classNames({
        'status-wrapper': true,
        'status-wrapper-active': item.status === 'Completed',
        'status-wrapper-inprogress': item.status && item.status !== 'Completed',
      })}
      >
        <p>{item?.status}</p>
      </div>
    ),
  },
];
