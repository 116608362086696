export const types = {
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_SUCCESS: 'SET_IS_SUCCESS',
};

export const changePassword = (oldPassword, newPassword) => ({
  type: types.CHANGE_PASSWORD,
  payload: {
    oldPassword,
    newPassword,
  },
});

export const setIsLoading = (isLoading) => ({
  type: types.SET_IS_LOADING,
  payload: { isLoading },
});

export const setIsSuccess = (isSuccess) => ({
  type: types.SET_IS_SUCCESS,
  payload: { isSuccess },
});
