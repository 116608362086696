import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import classNames from 'classnames';

import {
  Select,
} from 'antd';

import '../../../tables.scss';
import { getPopupContainer } from 'utils/inputRules';

const Dropdown = ({
  dropdownOnChangeHandler, dropdownTitle, rowOptions, resetPaginationData, dropDownMinWidth,
}) => {
  const { Option } = Select;

  const rowOptionsMapping = useMemo(() => rowOptions.map((item) => (
    <Option key={item} value={item} className="dropdown-option">
      {item}
    </Option>
  )), [rowOptions]);

  const onSelectChange = (item) => {
    resetPaginationData();
    dropdownOnChangeHandler(item);
  };

  return (
    <div className="dropdown-wrapper">
      <p>{dropdownTitle}</p>
      <Select
        className={classNames({
          'dropdown-select': true,
          'dropdown-min-width': dropDownMinWidth,
        })}
        onChange={onSelectChange}
        defaultValue={rowOptions[0]}
        getPopupContainer={getPopupContainer}
      >
        {rowOptionsMapping}
      </Select>
    </div>

  );
};

Dropdown.propTypes = {
  dropdownOnChangeHandler: PropTypes.func.isRequired,
  dropdownTitle: PropTypes.string.isRequired,
  rowOptions:  ImmutablePropTypes.list.isRequired,
  dropDownMinWidth: PropTypes.bool,
  resetPaginationData: PropTypes.func.isRequired,
};

Dropdown.defaultProps = {
  dropDownMinWidth: false,
};

export default Dropdown;
