import React from 'react';
import classNames from 'classnames';
import { Button } from 'antd';
import '../parents.scss';

// eslint-disable-next-line import/prefer-default-export
export const getColumns = (generateCoupon) => {
  const renderStatus = (item) => (
    <div className={classNames({
      'status-wrapper': true,
      'status-wrapper-active': item.status === 'Active',
      'status-wrapper-inactive': item.status === 'Deleted',
    })}
    >
      <p>{item?.status}</p>
    </div>
  );

  const renderGenerateCoupon = (item) => {
    const generateCouponFunc = (e) => {
      e.stopPropagation();
      generateCoupon(item.id);
    };
    return (
      <Button onClick={generateCouponFunc} className="generate-coupon">Generate</Button>
    );
  };

  return ([
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Children',
      dataIndex: 'noOfChildren',
      key: 'noOfChildren',
    },
    {
      title: 'Enrolled',
      dataIndex: 'enrolled',
      key: 'enrolled',
    },
    {
      title: 'Subscription status',
      key: 'premium',
      dataIndex: 'premium',
    },
    {
      title: 'Register date',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
    {
      title: 'Status',
      key: 'status',
      render: renderStatus,
    },
    {
      title: 'Coupon',
      render: renderGenerateCoupon,
    },
  ]);
};
