import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import '../../../tables.scss';

const Search = ({ searchPlaceholder, handleSearchChange }) => (
  <div>
    <Input prefix={<SearchOutlined />} className="table_search" placeholder={searchPlaceholder} onChange={handleSearchChange} type="text" />
  </div>
);

Search.propTypes = {
  searchPlaceholder: PropTypes.string.isRequired,
  handleSearchChange: PropTypes.func.isRequired,
};

export default Search;
