import { authorizedApiService } from './apiService';

const BASE_PATH = '/lessons';

export default {
  getLessons: async (params) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/manage-lessons`, { params });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  createLesson: async (lessonData) => {
    try {
      const { lesson, dataFromCloudinary } = lessonData;
      const response = await authorizedApiService.post(`${BASE_PATH}`, lesson);
      const lessonImgData = {
        url: dataFromCloudinary.url,
        name: dataFromCloudinary.name,
        publicId: dataFromCloudinary.publicId,
        lessonId: response.data.id,
      };
      await authorizedApiService.post(`${BASE_PATH}/images`, lessonImgData);

      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getUserLessonInfo: async (data) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/watched?lessonId=${data.lessonId}&userId=${data.userId}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  deleteLesson: async (id) => {
    try {
      const response = await authorizedApiService.delete(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  getLessonById: async (id) => {
    try {
      const response = await authorizedApiService.get(`${BASE_PATH}/${id}`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateChapter: async (lessonId, chapterId, status) => {
    try {
      const response = await authorizedApiService.patch(`${BASE_PATH}/${lessonId}/chapters/${chapterId}`, {
        status,
      });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateLesson: async (lessonId, status) => {
    try {
      const response = await authorizedApiService.patch(`${BASE_PATH}/${lessonId}`, {
        status,
      });
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  updateCompleteLesson: async (lessonId, lessonData) => {
    try {
      const { lesson, dataFromCloudinary } = lessonData;
      const response = await authorizedApiService.put(`${BASE_PATH}/${lessonId}`, lesson);
      const lessonImgData = {
        url: dataFromCloudinary.url,
        name: dataFromCloudinary.name,
        publicId: dataFromCloudinary.publicId,
        lessonId: response.data[1][0].id,
      };
      await authorizedApiService.post(`${BASE_PATH}/images`, lessonImgData);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
  startChapterQuiz: async (lessonId, chapterId, quizId) => {
    try {
      const response = await authorizedApiService.put(`${BASE_PATH}/${lessonId}/chapters/${chapterId}/quizzes/${quizId}/start`);
      return response;
    } catch (e) {
      return {
        data: null,
        error: e,
      };
    }
  },
};
